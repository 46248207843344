import Select from 'react-select'
import React, { useState, useEffect } from 'react'
import http from '../../../http';
import Swal from "sweetalert2";
import { Autocomplete, TextField } from "@mui/material";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "../../../CSS/Permissions.css"
const Permissions = () => {

    const [userMaster, setuserMaster] = useState([])
    const [users, setusers] = useState([]);
    const [userRole, setUserRole] = useState([]);
    const [random, setRandom] = useState();
    const [slectedUser, setslectedUser] = useState(null);
    const [userType, setuserType] = useState("All");
    const navigate = useNavigate();

    console.log("users", users)
    useEffect(() => {
        http.get(`/users/`).then((res) => {
            setuserMaster(res.data.data);
            setusers(res.data.data);

        });
        return () => { };
    }, [random]);

    const fillterData = (userType = null) => {
        setslectedUser(null)
        setPermisionEmpty()
        setuserId();
        setroleId();

        if (userType === null) {
            setusers(userMaster)
        } else {
            const data = userMaster?.filter((v) => v?.user_type == userType)
            console.log("data", data, userType)
            setusers(data)

        }

    }


    useEffect(() => {
        http.get(`/roles`).then((res) => {
            if (res.data.status === 200) {
                setUserRole(res.data.data);
            }
        });
    }, [random]);

    const [roleId, setroleId] = useState();
    const [userId, setuserId] = useState();



    const permissionArray = [
        {
            main: "Admin Setup",
            slug: "admin-setup",
            sub: [
                { name: "Member Type", slug: "member-type" },
                { name: "Gender Type", slug: "gender-type" },
                { name: "Service Group", slug: "service-group" },
                { name: "Fees", slug: "Fees" },
                { name: "Unit", slug: "unit" },
                { name: "Service Type", slug: "service-type" },
                { name: "Notice", slug: "notice" },
                { name: "Help Line", slug: "help-line" },
                { name: "Billing-setup", slug: "billing-setup" },
                { name: "OwnerShip Type", slug: "owner-type" },
            ],
        },
        {
            main: "Manage Properties",
            slug: "manage-properties",
            sub: [
                { name: "Properties", slug: "properties" },
            ],
        },
        {
            main: "Manage Owner/Tenant",
            slug: "owners/tenant",
            sub: [{ name: "Owners/Tenant", slug: "owners/tenant" }],
        },
        {
            main: "Fee Setup",
            slug: "fee-setup",
            sub: [
                { name: "Fee Setup", slug: "fee-setup" },
            ],
        },
        {
            main: "Employee Management",
            slug: "employee-management",
            sub: [
                { name: "Employees", slug: "employees" },
            ],
        },
        {
            main: "Visitor Management",
            slug: "visitors",
            sub: [
                { name: "Visitors", slug: "visitors" },
            ],
        },
        {
            main: "Movement Management",
            slug: "movement-management",
            sub: [
                { name: "Movement", slug: "movement" },
            ],
        },
        {
            main: "Billing",
            slug: "billing",
            sub: [
                { name: "Billing", slug: "billing" },
                { name: "Billing Managment", slug: "billing-managment" },
                { name: "Saas Billing", slug: "saas-billing" },
            ],
        },
        {
            main: "Role & Permissions",
            slug: "role-permissions",
            sub: [
                { name: "Role", slug: "role" },
                { name: "Permissions", slug: "permissions" },
            ],
        },
    ];

    const [selectedPermissions, setSelectedPermissions] = useState({});
    const [isLoading, setisLoading] = useState(false);
    const [btnIsLoading, setbtnIsLoading] = useState(false);



    useEffect(() => {
        if (userId) {
            setisLoading(true);
            http
                .get(`user-role-permission/${userId}`)
                .then((res) => {
                    const newObj = res.data.permission?.reduce((acc, item) => {
                        acc[item.name] = item.value.split(",");
                        return acc;
                    }, {});

                    setroleId(res.data.permission[0]?.role_id);
                    setSelectedPermissions(newObj);
                    setisLoading(false);
                })
                .catch((err) => {
                    console.log("err", err);
                    setisLoading(false);
                });
        }
        // return () => { };
    }, [userId, random]);
    const handleCheckboxAll = (e) => {
        const { name, value, checked } = e.target;
        if (checked) {
            setSelectedPermissions({
                ...selectedPermissions,
                [name]: permissionArray[value]?.sub?.map((item) => item.slug),
            });
        } else {
            setSelectedPermissions({ ...selectedPermissions, [name]: [] });
        }
    };
    const handlePermissionChange = (
        event,
        mainPermissionSlug,
        subPermissionSlug
    ) => {
        setSelectedPermissions((prevSelectedPermissions) => {
            const updatedPermissions = { ...prevSelectedPermissions };

            if (event.target.checked) {
                if (!updatedPermissions[mainPermissionSlug]) {
                    updatedPermissions[mainPermissionSlug] = [];
                }
                updatedPermissions[mainPermissionSlug]?.push(subPermissionSlug);
            } else {
                updatedPermissions[mainPermissionSlug] = updatedPermissions[
                    mainPermissionSlug
                ]?.filter((slug) => slug !== subPermissionSlug);
            }

            return updatedPermissions;
        });
    };

    const submitPermission = () => {
        if (roleId && userId) {
            const data = [];
            selectedPermissions &&
                Object.entries(selectedPermissions).forEach(([key, value]) => {
                    data.push({
                        user_id: userId,
                        role_id: roleId,
                        name: key,
                        value: value.toString(),
                        validity_date: "null",
                    });
                });
            setbtnIsLoading(true);
            http
                .post("create-user-role-permission", { permission_array: data })
                .then((res) => {
                    setSelectedPermissions({});
                    setuserId();
                    setroleId();
                    setRandom(Math.random())
                    Swal.fire({
                        position: 'top-center',
                        icon: 'success',
                        title: 'Success !',
                        text: res.data.message,
                        showConfirmButton: false,
                        timer: 1500
                    })

                    setbtnIsLoading(false);
                })
                .catch((err) => {
                    console.log("err", err);
                    setbtnIsLoading(false);
                });
        } else {
            Swal.fire("Warning!", "You have to select User and Role", "warning");
        }
    };

    const setPermisionEmpty = () => {
        setSelectedPermissions({
            'admin-setup': [],
            'manage-properties': [],
            'fee-setup': [],
            'owners/tenant': [],
            'employee-management': [],
            'movement-management': [],
            'billing': [],
            'role-permissions': [],
        })
    }

    return (
        <>
            <div className="page-content adjustment-type-table">
                <div className="ms-2 mt-2">
                    <div className="custom-card flex-grow-1 ">
                        <h5 className="fw-normal  text-start py-2 px-1 mb-2 text-login">
                            User Roles, Permissions and Access
                        </h5>
                    </div>
                    <div className="custom-card overflow-hidden ">
                        <div className="row user-role-header m-2 p-2 apart-box-shadow">
                            <div className="col-8">
                                <div className="row">
                                    <div className="col-6">
                                        <div className="row">
                                            <label className="col-sm-4 col-form-label">
                                                Module - User
                                            </label>
                                            <div className="col-8">
                                                <Autocomplete
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    size="small"
                                                    value={slectedUser}
                                                    options={users}
                                                    loadingText="loading..."
                                                    getOptionLabel={(option) => option.name}
                                                    onChange={(e, value) => {
                                                        if (value != null) {
                                                            setuserId(value.id);
                                                            setslectedUser(value);
                                                        } else {
                                                            setuserId();
                                                        }
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            sx={{ width: "100%", height: 10 }}
                                                            {...params}
                                                            label={userType}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="row">
                                            <div className="col-2">
                                                <label className="col-form-label">Role </label>
                                            </div>
                                            <div className="col-8">
                                                <Autocomplete
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    size="small"
                                                    options={userRole}
                                                    value={userRole?.find((opt) => opt.id == roleId) || null}
                                                    loadingText="loading..."
                                                    getOptionLabel={(option) => option.name}
                                                    onChange={(e, value) => {
                                                        setroleId(value.id);

                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            sx={{ width: "100%", height: 10 }}
                                                            {...params}
                                                            label="Role"
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4 user-role-header-user d-flex justify-content-end align-items-center">
                                <div className="me-2">
                                    <img src='' alt="" className="me-1" />

                                    <span>{slectedUser && `${slectedUser.name} || ${slectedUser.email}`}</span>

                                </div>
                            </div>
                        </div>
                        <div className="row m-2 p-2">
                            <div className="col-2 new-rx-container user-role-sidebar apart-box-shadow">
                                <div
                                    className="nav flex-column nav-pills"
                                    id="v-pills-tab"
                                    role="tablist"
                                    aria-orientation="vertical"
                                >
                                    <button
                                        onClick={() => {
                                            setuserType("All");
                                            setSelectedPermissions({});
                                            fillterData()
                                        }}
                                        className="nav-link active mb-2 apart-box-shadow"
                                        id="v-pills-home-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#v-pills-home"
                                        type="button"
                                        role="tab"
                                        aria-controls="v-pills-home"
                                        aria-selected="true"
                                    >
                                        <img src="" alt="" className="me-2" /> All
                                    </button>
                                    <button
                                        onClick={() => {
                                            setuserType("Owners");
                                            setSelectedPermissions({});
                                            fillterData("Owner")
                                        }}
                                        className="nav-link mb-2 apart-box-shadow"
                                        id="v-pills-home-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#v-pills-home"
                                        type="button"
                                        role="tab"
                                        aria-controls="v-pills-home"
                                        aria-selected="true"
                                    >
                                        <img src="" alt="" className="me-2" /> Owners
                                    </button>
                                    <button
                                        onClick={() => {
                                            setuserType("Tenant");
                                            setSelectedPermissions({});
                                            fillterData("Tenant")
                                        }}
                                        className="nav-link mb-2 apart-box-shadow"
                                        id="v-pills-home-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#v-pills-home"
                                        type="button"
                                        role="tab"
                                        aria-controls="v-pills-home"
                                        aria-selected="true"
                                    >
                                        <img src="" alt="" className="me-2" /> Tenant
                                    </button>
                                    <button
                                        onClick={() => {
                                            setuserType("Employee");
                                            setSelectedPermissions({});
                                            fillterData("Employee")
                                        }}
                                        className="nav-link mb-2 apart-box-shadow"
                                        id="v-pills-profile-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#v-pills-home"
                                        type="button"
                                        role="tab"
                                        aria-controls="v-pills-profile"
                                        aria-selected="false"
                                    >
                                        <img src="" alt="" className="me-2" /> Employee
                                    </button>
                                </div>
                            </div>
                            <div className="col-10 user-role-container">
                                <div className="tab-content" id="v-pills-tabContent">
                                    <div
                                        className="tab-pane fade show active"
                                        id="v-pills-home"
                                        role="tabpanel"
                                        aria-labelledby="v-pills-home-tab"
                                    >
                                        <div className="user-role-content-head custom-card d-flex justify-content-between align-items-center apart-box-shadow">
                                            <div>
                                                <h6>{userType} permission</h6>
                                                <span className="text-muted">
                                                    Select the role for each feature
                                                </span>
                                            </div>
                                            <div className="rx-one-button-group d-flex align-items-end">
                                                {btnIsLoading ? (
                                                    <button className="btn float-end me-2">
                                                        <i className="fas fa-spinner fa-spin"></i>
                                                    </button>
                                                ) : (
                                                    <button
                                                        onClick={submitPermission}
                                                        className="btn btn-primary float-end me-2"
                                                    >
                                                        Save
                                                    </button>
                                                )}

                                                <button
                                                    onClick={() => navigate("/")}
                                                    className="btn btn-primary float-end me-2"
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                        <div className="user-role-content-table custom-card mt-2 p-2 apart-box-shadow">
                                            <div className="row">
                                                {isLoading ? (
                                                    <div className="col-6">
                                                        <i
                                                            style={{ fontSize: "17px" }}
                                                            className="fas fa-spinner fa-spin"
                                                        ></i>
                                                    </div>
                                                ) : (
                                                    permissionArray?.map((item, i) => (
                                                        <div key={i} className="col-3 ">
                                                            <div
                                                                style={{
                                                                    height: "220px",
                                                                    overflow: "hidden",
                                                                    overflowY: "auto",
                                                                }}
                                                                className="table-scroll custom-card m-2 py-3 px-2"
                                                            >
                                                                <div
                                                                    class="form-check pb-2"
                                                                    style={{
                                                                        borderBottom: "1px solid rgba(0, 0, 0, 0.08)",
                                                                    }}
                                                                >
                                                                    <input
                                                                        class="form-check-input mt-1"
                                                                        type="checkbox"
                                                                        value={i}
                                                                        checked={
                                                                            selectedPermissions[item.slug] &&
                                                                            selectedPermissions[item.slug].length ===
                                                                            item.sub.length
                                                                        }
                                                                        name={item.slug}
                                                                        onChange={handleCheckboxAll}
                                                                        id="flexCheckDefault"
                                                                    />
                                                                    <label
                                                                        class="form-check-label"
                                                                        for="flexCheckDefault"
                                                                    >
                                                                        {item.main}
                                                                    </label>
                                                                </div>

                                                                <div className="ms-3">
                                                                    {item?.sub?.map((sub, i) => (
                                                                        <div key={i} class="form-check">
                                                                            <input
                                                                                class="form-check-input"
                                                                                type="checkbox"
                                                                                value={sub.slug}
                                                                                onChange={(event) =>
                                                                                    handlePermissionChange(
                                                                                        event,
                                                                                        item.slug,
                                                                                        sub.slug
                                                                                    )
                                                                                }
                                                                                checked={
                                                                                    selectedPermissions[item.slug] &&
                                                                                    selectedPermissions[item.slug].includes(
                                                                                        sub.slug
                                                                                    )
                                                                                }
                                                                                id="flexCheckDefault"
                                                                            />
                                                                            <label
                                                                                class="form-check-label"
                                                                                for="flexCheckDefault"
                                                                            >
                                                                                {sub.name}
                                                                            </label>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Permissions