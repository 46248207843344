import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "../CSS/Sidebar.css";
import AuthUser from "../components/AuthUser";
import { useEffect } from "react";
import http from "../http";
import { FiAnchor, FiChevronDown, FiUnlock } from "react-icons/fi";
import logo from "../front_assets/Logo_Image/Logo.png";

import { LuLayoutDashboard } from "react-icons/lu";
import { HiOutlineBuildingLibrary, HiUserCircle } from "react-icons/hi2";
import { IoSettingsOutline } from "react-icons/io5";
import { FaUsersRectangle } from "react-icons/fa6";
import { AiOutlineUser } from "react-icons/ai";

import { RiAccountCircleLine } from "react-icons/ri";



export default function SideBar(props) {
  const location = useLocation();
  const { getToken } = AuthUser();
  console.log("permissionData4321", props.permissionData);
  console.log("isSuperAdmin321", props.isSuperAdmin);
  const user = getToken();
  return (
    <div className="sidebar-style">
      <nav className="sidebar">
        <div className="sidebar-header">
          <a style={{ width: "50%" }} href="/" className="sidebar-brand">
            <img className="img-fluid w-75" src={logo} alt="" srcset="" />
          </a>
          {/* <p>Salse</p> */}
          <div className="sidebar-toggler not-active">
            <span />
            <span />
            <span />
          </div>
        </div>

        <div className="sidebar-body">
          <ul className="nav">

          <li
                  class={`nav-item ${
                    location.pathname === "/" ? "active" : ""
                  }`}
                >
                  <Link
                  to="/"
                    class="nav-link"
                  
                  >
                    <LuLayoutDashboard className="link-icon" />
                    <span class="link-title">Dashboard</span>

                  </Link>
                 
                </li>
            {/* Category Start  */}
            {(props.isSuperAdmin ||
              (Array.isArray(props.permissionData) &&
                props.permissionData?.some(
                  (item) => item.name === "manage-properties"
                ))) && (
              <>
                <li
                  className={`nav-item ${
                    location.pathname === "/banner-bottom-slide" ||
                    location.pathname === "/properties" ||
                    location.pathname === "/banner" ||
                    location.pathname === "/titles" ||
                    location.pathname === "/recent-activity" ||
                    location.pathname === "/activity-slider" ||
                    location.pathname === "/designation" ||
                    location.pathname === "/sp" ||
                    location.pathname === "/logo" ||
                    location.pathname === "/background" ||
                    location.pathname === "/requisition-frequency"
                      ? "active"
                      : ""
                  }`}
                >
                  <a
                    className="nav-link"
                    data-bs-toggle="collapse"
                    href="#emails"
                    role="button"
                    aria-expanded={`${
                      location.pathname === "/banner" ||
                      location.pathname === "/properties" ||
                      location.pathname === "/banner" ||
                      location.pathname === "/titles" ||
                      location.pathname === "/recent-activity" ||
                      location.pathname === "/activity-slider" ||
                      location.pathname === "/designation" ||
                      location.pathname === "/sp" ||
                      location.pathname === "/logo" ||
                      location.pathname === "/requisition-category" ||
                      location.pathname === "/background" ||
                      location.pathname === "/requisition-frequency"
                        ? "true"
                        : ""
                    }`}
                    aria-controls="emails"
                  >
                    <HiOutlineBuildingLibrary  className="link-icon" />
                    <span className="link-title">Manage Properties</span>
                    {/* <i className="link-arrow" data-feather="chevron-down" strokeWidth="2" fill="#6E6" /> */}
                    <FiChevronDown className="link-arrow" />
                  </a>
                  <div
                    className={`collapse ${
                      location.pathname === "/add-properties" ||
                      location.pathname === "/properties" ||
                      location.pathname === "/banner" ||
                      location.pathname === "/banner-bottom-slide" ||
                      location.pathname === "/recent-activity" ||
                      location.pathname === "/activity-slider" ||
                      location.pathname === "/designation" ||
                      location.pathname === "/sp" ||
                      location.pathname === "/logo" ||
                      location.pathname === "/requisition-category" ||
                      location.pathname === "/background" ||
                      location.pathname === "/requisition-frequency"
                        ? "show"
                        : ""
                    }`}
                    id="emails"
                  >
                    <ul className="nav sub-menu">
                      <li className="nav-item">
                        <Link
                          to="/properties"
                          className={`nav-link ${
                            location.pathname === "/properties" ? "active" : ""
                          }`}
                        >
                          Properties
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
              </>
            )}

            {/* admin setup */}
            {(props.isSuperAdmin ||
              (Array.isArray(props.permissionData) &&
                props.permissionData?.some(
                  (item) => item.name === "admin-setup"
                ))) && (
              <>
                <li
                  class={`nav-item ${
                    location.pathname === "/member-setup" ? "active" : ""
                  }`}
                >
                  <a
                    class="nav-link"
                    data-bs-toggle="collapse"
                    href="#member-type"
                    role="button"
                    aria-expanded={`${
                      location.pathname === "/member-setup" ? "true" : ""
                    }`}
                    aria-controls="authPages"
                  >
                    <IoSettingsOutline  className="link-icon" />
                    <span class="link-title">Admin Setup</span>
                    <FiChevronDown className="link-arrow" />
                  </a>
                  <div
                    class={`collapse ${
                      location.pathname === "/rent-setup" ||
                      location.pathname === "/member-setup" ||
                      location.pathname === "/gender-setup" ||
                      location.pathname === "/group-setup" ||
                      location.pathname === "/fees-setup" ||
                      location.pathname === "/unit-setup" ||
                      location.pathname === "/service-setup" ||
                      location.pathname === "/notice" ||
                      location.pathname === "/help-line" ||
                      location.pathname === "/building-setup" ||
                      location.pathname === "/owenr-ship-type" ||
                      location.pathname === "/property-type" ||
                      location.pathname === "/designations" ||
                      location.pathname === "/nationality" ||
                      location.pathname === "/blood-group" ||
                      location.pathname === "/division" ||
                      location.pathname === "/district" ||
                      location.pathname === "/area" ||
                      location.pathname === "/police-stations" ||
                      location.pathname === "/uinit-measurement"
                        ? "show"
                        : ""
                    }`}
                    id="member-type"
                  >
                    <ul class="nav sub-menu">
                      <li className="nav-item">
                        <Link
                          to="/rent-setup"
                          className={`nav-link ${
                            location.pathname === "/rent-setup"
                              ? "active"
                              : ""
                          }`}
                        >
                          Rent Setup
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/member-setup"
                          className={`nav-link ${
                            location.pathname === "/member-setup"
                              ? "active"
                              : ""
                          }`}
                        >
                          Family Member Type
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/gender-setup"
                          className={`nav-link ${
                            location.pathname === "/gender-setup"
                              ? "active"
                              : ""
                          }`}
                        >
                          Gender Type
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/group-setup"
                          className={`nav-link ${
                            location.pathname === "/group-setup" ? "active" : ""
                          }`}
                        >
                          Service Group
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/fees-setup"
                          className={`nav-link ${
                            location.pathname === "/fees-setup" ? "active" : ""
                          }`}
                        >
                          Fees
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/unit-setup"
                          className={`nav-link ${
                            location.pathname === "/unit-setup" ? "active" : ""
                          }`}
                        >
                          Service Unit
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/service-setup"
                          className={`nav-link ${
                            location.pathname === "/service-setup"
                              ? "active"
                              : ""
                          }`}
                        >
                          Service Type
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/notice"
                          className={`nav-link ${
                            location.pathname === "/notice" ? "active" : ""
                          }`}
                        >
                          Notice
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link
                          to="/help-line"
                          className={`nav-link ${
                            location.pathname === "/help-line" ? "active" : ""
                          }`}
                        >
                          Help Line
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/building-setup"
                          className={`nav-link ${
                            location.pathname === "/building-setup"
                              ? "active"
                              : ""
                          }`}
                        >
                          Building Setup
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link
                          to="/owenr-ship-type"
                          className={`nav-link ${
                            location.pathname === "/owenr-ship-type"
                              ? "active"
                              : ""
                          }`}
                        >
                          OwnerShip Type
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/property-type"
                          className={`nav-link ${
                            location.pathname === "/property-type"
                              ? "active"
                              : ""
                          }`}
                        >
                          Property Type
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/designations"
                          className={`nav-link ${
                            location.pathname === "/designations"
                              ? "active"
                              : ""
                          }`}
                        >
                          Designation
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link
                          to="/nationality"
                          className={`nav-link ${
                            location.pathname === "/nationality" ? "active" : ""
                          }`}
                        >
                          Nationality
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link
                          to="/blood-group"
                          className={`nav-link ${
                            location.pathname === "/blood-group" ? "active" : ""
                          }`}
                        >
                          Blood Group
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/division"
                          className={`nav-link ${
                            location.pathname === "/division" ? "active" : ""
                          }`}
                        >
                          Division
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/district"
                          className={`nav-link ${
                            location.pathname === "/district" ? "active" : ""
                          }`}
                        >
                          District
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/area"
                          className={`nav-link ${
                            location.pathname === "/area" ? "active" : ""
                          }`}
                        >
                          Area
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/police-stations"
                          className={`nav-link ${
                            location.pathname === "/police-stations"
                              ? "active"
                              : ""
                          }`}
                        >
                          Police Stations
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/uinit-measurement"
                          className={`nav-link ${
                            location.pathname === "/uinit-measurement"
                              ? "active"
                              : ""
                          }`}
                        >
                          Unit Measurement
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
              </>
            )}

            {(props.isSuperAdmin ||
              (Array.isArray(props.permissionData) &&
                props.permissionData?.some(
                  (item) => item.name === "owners/tenant"
                ))) && (
              <>
                <li
                  class={`nav-item ${
                    location.pathname === "/owners" ||
                    location.pathname === "/subcategory" ||
                    location.pathname === "/image-gallery" ||
                    location.pathname === "/video-gallery"
                      ? "active"
                      : ""
                  }`}
                >
                  <a
                    class="nav-link"
                    data-bs-toggle="collapse"
                    href="#authPages"
                    role="button"
                    aria-expanded={`${
                      location.pathname === "/owners" ||
                      location.pathname === "/subcategory" ||
                      location.pathname === "/image-gallery" ||
                      location.pathname === "/video-gallery"
                        ? "true"
                        : ""
                    }`}
                    aria-controls="authPages"
                  >
                    <FiUnlock className="link-icon" />
                    <span class="link-title">Manage Owner/Tenant</span>
                    <FiChevronDown className="link-arrow" />
                  </a>
                  <div
                    class={`collapse ${
                      location.pathname === "/owners" ||
                      location.pathname === "/subcategory" ||
                      location.pathname === "/image-gallery" ||
                      location.pathname === "/video-gallery"
                        ? "show"
                        : ""
                    }`}
                    id="authPages"
                  >
                    <ul class="nav sub-menu">
                      <li className="nav-item">
                        <Link
                          to="/owners"
                          className={`nav-link ${
                            location.pathname === "/owners" ? "active" : ""
                          }`}
                        >
                          Owners/Tenants
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
              </>
            )}

            {(props.isSuperAdmin ||
              (Array.isArray(props.permissionData) &&
                props.permissionData?.some(
                  (item) => item.name === "fee-setup"
                ))) && (
              <>
                <li
                  class={`nav-item ${
                    location.pathname === "/utlilty" ||
                    location.pathname === "/service"
                      ? "active"
                      : ""
                  }`}
                >
                  <Link
                    className={`nav-link ${
                      location.pathname === "/service-fee-setup" ? "active" : ""
                    }`}
                    to="/service-fee-setup"
                  >
                    <IoSettingsOutline className="link-icon" />
                    <span class="link-title">Fee Setup</span>
                  </Link>
                </li>
              </>
            )}

            {(props.isSuperAdmin ||
              (Array.isArray(props.permissionData) &&
                props.permissionData?.some(
                  (item) => item.name === "employee-management"
                ))) && (
              <>
                <li
                  class={`nav-item ${
                    location.pathname === "/footer-section" ||
                    location.pathname === "/footer-content"
                      ? "active"
                      : ""
                  }`}
                >
                  <a
                    class="nav-link"
                    data-bs-toggle="collapse"
                    href="#footer"
                    role="button"
                    aria-expanded={`${
                      location.pathname === "/employees" ||
                      location.pathname === "/footer-content"
                        ? "true"
                        : ""
                    }`}
                    aria-controls="footer"
                  >
                    <FiUnlock className="link-icon" />
                    <span class="link-title">Employee Management</span>
                    <FiChevronDown className="link-arrow" />
                  </a>
                  <div
                    class={`collapse ${
                      location.pathname === "/employees" ||
                      location.pathname === "/footer-content" ||
                      location.pathname === "/footer-button"
                        ? "show"
                        : ""
                    }`}
                    id="footer"
                  >
                    <ul class="nav sub-menu">
                      <li className="nav-item">
                        <Link
                          to="/employees"
                          className={`nav-link ${
                            location.pathname === "/employees" ? "active" : ""
                          }`}
                        >
                          Employees
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
              </>
            )}
            {(props.isSuperAdmin ||
              (Array.isArray(props.permissionData) &&
                props.permissionData?.some(
                  (item) => item.name === "visitors"
                ))) && (
              <>
                <li
                  class={`nav-item ${
                    location.pathname === "/visitors" ||
                    location.pathname === "/add-visitor"
                      ? "active"
                      : ""
                  }`}
                >
                  <a
                    class="nav-link"
                    data-bs-toggle="collapse"
                    href="#right"
                    role="button"
                    aria-expanded={`${
                      location.pathname === "/visitors" ||
                      location.pathname === "/add-visitor" ||
                      location.pathname === "/edit-visitor"
                        ? "true"
                        : ""
                    }`}
                    aria-controls="right"
                  >
                    <FiUnlock className="link-icon" />
                    <span class="link-title">Visitor Management</span>
                    <FiChevronDown className="link-arrow" />
                  </a>
                  <div
                    class={`collapse ${
                      location.pathname === "/visitors" ||
                      location.pathname === "/add-visitor" ||
                      location.pathname === "/edit-visitor"
                        ? "show"
                        : ""
                    }`}
                    id="right"
                  >
                    <ul class="nav sub-menu">
                      <li className="nav-item">
                        <Link
                          to="/visitors"
                          className={`nav-link ${
                            location.pathname === "/visitors" ? "active" : ""
                          }`}
                        >
                          Visitors
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
              </>
            )}

            {(props.isSuperAdmin ||
              (Array.isArray(props.permissionData) &&
                props.permissionData?.some(
                  (item) => item.name === "movement-management"
                ))) && (
              <>
                <li
                  class={`nav-item ${
                    location.pathname === "/movements" ||
                    location.pathname === "/add-visitor"
                      ? "active"
                      : ""
                  }`}
                >
                  <a
                    class="nav-link"
                    data-bs-toggle="collapse"
                    href="#move"
                    role="button"
                    aria-expanded={`${
                      location.pathname === "/movements" ||
                      location.pathname === "/add-visitor" ||
                      location.pathname === "/edit-visitor"
                        ? "true"
                        : ""
                    }`}
                    aria-controls="move"
                  >
                    <FiUnlock className="link-icon" />
                    <span class="link-title">Movement Management</span>
                    <FiChevronDown className="link-arrow" />
                  </a>
                  <div
                    class={`collapse ${
                      location.pathname === "/movements" ||
                      location.pathname === "/add-visitor" ||
                      location.pathname === "/edit-visitor"
                        ? "show"
                        : ""
                    }`}
                    id="move"
                  >
                    <ul class="nav sub-menu">
                      <li className="nav-item">
                        <Link
                          to="/movements"
                          className={`nav-link ${
                            location.pathname === "/movements" ? "active" : ""
                          }`}
                        >
                          Movements
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
              </>
            )}

            {(props.isSuperAdmin ||
              (Array.isArray(props.permissionData) &&
                props.permissionData?.some(
                  (item) => item.name === "billing"
                ))) && (
              <>
                <li
                  class={`nav-item ${
                    location.pathname === "/billing-setup" ? "active" : ""
                  }`}
                >
                  <a
                    class="nav-link"
                    data-bs-toggle="collapse"
                    href="#billing"
                    role="button"
                    aria-expanded={`${
                      location.pathname === "/billing" ? "true" : ""
                    }`}
                    aria-controls="move"
                  >
                    <FiUnlock className="link-icon" />
                    <span class="link-title">Billing</span>
                    <FiChevronDown className="link-arrow" />
                  </a>
                  <div
                    class={`collapse ${
                      location.pathname === "/billing-managment" ||
                      location.pathname === "/billing-statment-history" ||
                      location.pathname === "/billing" ||
                      location.pathname === "/saas-billing"
                        ? "show"
                        : ""
                    }`}
                    id="billing"
                  >
                    <ul class="nav sub-menu">
                      <li className="nav-item">
                        <Link
                          to="/billing-managment"
                          className={`nav-link ${
                            location.pathname === "/billing-managment"
                              ? "active"
                              : ""
                          }`}
                        >
                          Billing Managment
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/billing-statment-history"
                          className={`nav-link ${
                            location.pathname === "/billing-statment-history"
                              ? "active"
                              : ""
                          }`}
                        >
                          Billing Statment
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/billing"
                          className={`nav-link ${
                            location.pathname === "/billing" ? "active" : ""
                          }`}
                        >
                          Billing
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link
                          to="/saas-billing"
                          className={`nav-link ${
                            location.pathname === "/saas-billing"
                              ? "active"
                              : ""
                          }`}
                        >
                          Sass Billing
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
              </>
            )}
            {(props.isSuperAdmin ||
              (Array.isArray(props.permissionData) &&
                props.permissionData?.some(
                  (item) => item.name === "finance"
                ))) && (
              <>
                <li
                  class={`nav-item ${
                    location.pathname === "/finance-setup" ? "active" : ""
                  }`}
                >
                  <a
                    class="nav-link"
                    data-bs-toggle="collapse"
                    href="#finance"
                    role="button"
                    aria-expanded={`${
                      location.pathname === "/finance" ? "true" : ""
                    }`}
                    aria-controls="move"
                  >
                    <FiUnlock className="link-icon" />
                    <span class="link-title">Finance</span>
                    <FiChevronDown className="link-arrow" />
                  </a>
                  <div
                    class={`collapse ${
                      location.pathname === "/finance-managment"||
                      location.pathname === "/balance-statment"
                      
                      ? "show" : ""
                    }`}
                    id="finance"
                  >
                    <ul class="nav sub-menu">
                      <li className="nav-item">
                        <Link
                          to="/finance-managment"
                          className={`nav-link ${
                            location.pathname === "/finance-managment" 
                              ? "active"
                              : ""
                          }`}
                        >
                          Finance Managment
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/balance-statment"
                          className={`nav-link ${
                            location.pathname === "/balance-statment"
                              ? "active"
                              : ""
                          }`}
                        >
                          Balance Statment
                        </Link>
                      </li>
                    </ul>
                  
                  </div>
                </li>
              </>
            )}

            {(props.isSuperAdmin ||
              (Array.isArray(props.permissionData) &&
                props.permissionData?.some(
                  (item) => item.name === "role-permissions"
                ))) && (
              <>
                {/* Role and permissions */}
                <li
                  class={`nav-item ${
                    location.pathname === "/billing-setup" ? "active" : ""
                  }`}
                >
                  <a
                    class="nav-link"
                    data-bs-toggle="collapse"
                    href="#role"
                    role="button"
                    aria-expanded={`${
                      location.pathname === "/role" ? "true" : ""
                    }`}
                    aria-controls="move"
                  >
                    <FiUnlock className="link-icon" />
                    <span class="link-title">Role & Permissions</span>
                    <FiChevronDown className="link-arrow" />
                  </a>
                  <div
                    class={`collapse ${
                      location.pathname === "/role" ||
                      location.pathname === "/user-role-permissions-access"
                        ? "show"
                        : ""
                    }`}
                    id="role"
                  >
                    <ul class="nav sub-menu">
                      <li className="nav-item">
                        <Link
                          to="/role"
                          className={`nav-link ${
                            location.pathname === "/role" ? "active" : ""
                          }`}
                        >
                          Role
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/user-role-permissions-access"
                          className={`nav-link ${
                            location.pathname === "/permissions" ? "active" : ""
                          }`}
                        >
                          Permissions
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
              </>
            )}

            {/* Sidebar Footer  */}
          </ul>
        </div>
      </nav>
    </div>
  );
}
