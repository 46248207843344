import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import Modal from "react-modal";
import Swal from "sweetalert2";
import http from "../../../http";
import moment from "moment";
import Select from "react-select";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { Col, Row } from "react-bootstrap";
import cashIcon from "../../../front_assets/icons/cash.png";
import creditDebitCard from "../../../front_assets/icons/credit-debit-card.png";
import digitalPayment from "../../../front_assets/icons/digital-payment.png";
import ePayment from "../../../front_assets/icons/e-payment.png";
import eWallet from "../../../front_assets/icons/e-wallet.png";
import CircularProgress from "@mui/material/CircularProgress";
import { CiEdit } from "react-icons/ci";
import { FaHistory } from "react-icons/fa";
import { useReactToPrint } from "react-to-print";
import noImage from "../../../front_assets/images/no-image.png";
import allCustomStyles from "../../../CSS/ReactModalStyle";
import Setting from "../../Setting/Setting";
import DepositSlip from "./DepositSlip";

export default function DepositModal({
  depositModalIsOpen,
  setdepositModalIsOpen,
  settableDataUpdate
}) {
  const { BillingManagementModal } = allCustomStyles;
  const { currencyFormat, dateFormat } = Setting;
  const [ownerMasterData, setownerMasterData] = useState([]);
  const [owner, setowner] = useState([]);
  const [ownerDetails, setownerDetails] = useState();
  const [depositAmount, setdepositAmount] = useState("");
  const [depostiHistory, setdepostiHistory] = useState();

  const [isLoading, setisLoading] = useState(false);

  useEffect(() => {
    let sub = true;

    if (sub) {
      http
        .get("owners")
        .then((res) => {
          setownerMasterData(res.data);
          setowner(res.data);
        })
        .catch((err) => console.log(err));
    }

    return () => {
      sub = false;
    };
  }, []);

  const submitDeposit = (e) => {
    e.preventDefault();
    if (depositAmount < 1 || !depositAmount) {
      return Swal.fire({
        position: "center",
        icon: "warning",
        title: "Please add amount for deposit",
        timer: 4500,
      });
    }
    if (!ownerDetails?.wallet_number) {
      return Swal.fire({
        position: "center",
        icon: "warning",
        title: "Please verify your wallet number",
        timer: 4500,
      });
    }

    setisLoading(true);
    const data = {
      owner_id: ownerDetails?.id,
      recived_by: "Manager",
      manager_id: 1,
      amount: depositAmount,
    };
    http
      .post("e-wallet", data)
      .then((res) => {
        console.log("res de", res.data);
        setdepositAmount("");
        setdepostiHistory(res.data.data.history);
        setownerDetails({
          ...ownerDetails,
          e_wallet: {
            ...ownerDetails.e_wallet,
            balance: res.data.data.ew.balance,
          },
        });

        Swal.fire({
          position: "center",
          icon: "success",
          title: "Deposit amount added successfully",
          timer: 4500,
        }).then((v) => {
          if (v) {
            handlePrint();
          }
        });
        setisLoading(false);
      })
      .catch((err) => {
        setisLoading(false);
        Swal.fire({
          position: "center",
          icon: "error",
          title: err.response.data.message,
          timer: 4500,
        });
      });
  };

  const [selectedPayment, setSelectedPayment] = useState("Cash");
  const [paymentOptionSelected, setPaymentOptionSelected] = useState("");
  const [activePayment, setactivePayment] = useState(false);
  const [expireDate, setExpireDate] = useState("");
  const [digitalPaymentNumber, setDigitalPaymentNumber] = useState("");
  const changeSelectedHandler = (e) => {
    setSelectedPayment(e.target.value);
  };
  const changePaymentOptionSeleted = (e) => {
    setPaymentOptionSelected(e.target.value);
  };

  const componentPaymetSlipRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentPaymetSlipRef.current,
  });

  const closeWindow = () => {
    setdepositModalIsOpen(false)
    settableDataUpdate(Math.random())
  }

  return (
    <Modal
      isOpen={depositModalIsOpen}
      onRequestClose={depositModalIsOpen}
      style={BillingManagementModal}
      contentLabel="Example Modal"
    >
      <div className="product_modal">
        <div className="page-content">
          <div className=" patients-head ">
            <h5 className="fw-normal  text-start card-name">
              Deposit
              <span
                style={{ cursor: "pointer", fontSize: "16px" }}
                onClick={closeWindow}
                className="float-end"
              >
                <i className="fal fa-times"></i>
              </span>
              <hr></hr>
            </h5>
          </div>

          <div className="row">
            <div className="col-3 left_panel table-scroll">
              <div className="row my-2 ">
                <div className="col-4 mt-1">
                  <p>Users</p>
                </div>
                <div className="col-8">
                  <input
                    onChange={(e) => {
                      const { value } = e.target;
                      if (value.length > 0) {
                        var data = ownerMasterData.filter(
                          (item) =>
                            item.name
                              .toLowerCase()
                              .startsWith(value.toLowerCase()) ||
                            item.mobile_1
                              .toLowerCase()
                              .startsWith(value.toLowerCase())
                        );
                      } else {
                        var data = ownerMasterData;
                      }
                      setowner(data);
                    }}
                    type="text"
                    placeholder="Search user"
                    className="form-control form-control-sm"
                  />
                </div>
              </div>

              {owner?.length > 0 ? (
                owner?.map((item, i) => {
                  return (
                    <div
                      key={i}
                      onClick={() => {
                        setownerDetails(item);
                        setdepositAmount("");
                      }}
                      className={`d-flex flex-column flat ${Number(ownerDetails?.id) === Number(item?.id) &&
                        "active"
                        }`}
                    >
                      <label>{item?.name}</label>
                      <label>{item?.mobile_1}</label>
                    </div>
                  );
                })
              ) : (
                <div className="flat" style={{ color: "#E74C3C" }}>
                  <label>Records are not available</label>
                </div>
              )}
            </div>
            {ownerDetails && (
              <div className="col-9">
                <div className="row">
                  <div className="col-9">
                    <div className="owner_info">
                      <div className="row ms-2">
                        <div className="col-sm-1">
                          {!ownerDetails?.picture ? (
                            <img
                              style={{ width: "50px", height: "50px" }}
                              src={noImage}
                              alt="User_Image"
                            />
                          ) : (
                            <img
                              style={{ width: "50px", height: "50px" }}
                              src={`${global.img_Url}/images/${ownerDetails?.picture}`}
                              alt="User_Image"
                            />
                          )}
                        </div>
                        <div className="col-sm-5 ms-2">
                          <p>Name : {ownerDetails?.name}</p>
                          <p>Mobile : {ownerDetails?.mobile_1}</p>
                          <p>Wallet No. : {ownerDetails?.wallet_number}</p>
                        </div>
                        <div className="col-sm-5">
                          <p>Email : {ownerDetails?.email}</p>
                          <p>Address : {ownerDetails?.address_line_1}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div
                      className="owner_info"
                      style={{
                        color: "green",
                        fontWeight: "600",
                        height: "60px",
                      }}
                      align="center"
                    >
                      <p>Available Balance </p>
                      <p>
                        {currencyFormat(
                          ownerDetails?.e_wallet?.balance
                            ? ownerDetails?.e_wallet?.balance
                            : 0
                        )}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="row billing_right_content_height table-scroll">
                  <Row>
                    {/* payment area */}
                    <Col lg={6}>
                      <div className="custom-card p-3 mt-2 pt-4">
                        <div className="d-flex justify-content-between">
                          <h6>Deposit Request</h6>
                          <FaHistory
                            onClick={() => { }}
                            style={{ cursor: "pointer" }}
                          />
                        </div>

                        <p>A request has been made for you to submit deposit</p>
                        <div className="mt-2">
                          <p>Required deposit Amount</p>
                          <form onSubmit={submitDeposit}>
                            <div className="row mt-2">
                              <div className="col-6">
                                <div className="row">
                                  <div className="col-3">
                                    <span>BDT</span>
                                  </div>
                                  <div className="col-9">
                                    <input
                                      autoFocus
                                      onChange={(e) =>
                                        setdepositAmount(e.target.value)
                                      }
                                      value={depositAmount}
                                      type="number"
                                      placeholder="deposit amount"
                                      className="form-control form-control-sm"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-6">
                                {isLoading ? (
                                  <button
                                    type="button"
                                    className="vaital-setup-btn"
                                  >
                                    <CircularProgress
                                      style={{
                                        height: "20px",
                                        width: "20px",
                                        color: "white",
                                      }}
                                    />
                                  </button>
                                ) : (
                                  <button
                                    type="submit"
                                    className="vaital-setup-btn"
                                  >
                                    Deposit Now
                                  </button>
                                )}
                              </div>
                            </div>
                          </form>
                        </div>
                        <div className="mt-2">
                          <h6>Remark</h6>
                          <div className="row mt-2">
                            <div className="col-5">
                              <span>Deposit for</span>{" "}
                              <span className="fw-bold ms-2">E-Wallet</span>
                            </div>
                          </div>
                        </div>
                        <p>
                          If you have any question or need help, please contact
                          us.
                        </p>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="custom-card p-3 mt-2 pt-4">
                        <h6 className="mb-2">Payment</h6>
                        {/* cash */}
                        <div className="d-flex justify-content-between align-items-center payment-container rounded mb-1">
                          <div className="d-flex">
                            <input
                              type="radio"
                              name="method"
                              value="Cash"
                              id="cash"
                              checked={selectedPayment === "Cash"}
                              onChange={changeSelectedHandler}
                            />
                            <label className="ms-2" htmlFor="Cash">
                              Cash
                            </label>
                          </div>
                          <div className="d-flex justify-content-end align-items-center gap-1">
                            <img
                              className="cash-icon"
                              src={cashIcon}
                              alt="cash-icon"
                            />
                          </div>
                        </div>
                        {/* credit/debit */}
                        <div className="payment-container mb-1 pt-1 rounded">
                          <div className="d-flex justify-content-between align-items-center   mb-1">
                            <div className="d-flex">
                              <input
                                disabled
                                type="radio"
                                name="method"
                                value="credit-debit"
                                id="credit-debit"
                                checked={selectedPayment === "credit-debit"}
                                onChange={changeSelectedHandler}
                              />
                              <label className="ms-2" htmlFor="credit-debit">
                                Credit / Debit Card
                              </label>
                            </div>
                            <div className="d-flex justify-content-end align-items-center gap-1">
                              <img
                                className="debit-credit-icon"
                                src={creditDebitCard}
                                alt="credit-debit-icon"
                              />
                            </div>
                          </div>
                          {/* payment option */}
                          {selectedPayment === "credit-debit" && (
                            <>
                              <div className="p-2  mb-2 d-flex gap-2">
                                <div className="radio-container">
                                  <div className="d-flex">
                                    <input
                                      type="radio"
                                      name="visa"
                                      value="Visa Card"
                                      id="visa"
                                      checked={
                                        paymentOptionSelected === "Visa Card"
                                      }
                                      onChange={changePaymentOptionSeleted}
                                    />
                                    <label className="ms-1" htmlFor="Visa Card">
                                      Visa
                                    </label>
                                  </div>
                                </div>
                                {/* master card */}
                                <div className="radio-container">
                                  <div className="d-flex">
                                    <input
                                      type="radio"
                                      name="MasterCard"
                                      value="Master Card"
                                      id="MasterCard"
                                      checked={
                                        paymentOptionSelected === "Master Card"
                                      }
                                      onChange={changePaymentOptionSeleted}
                                    />
                                    <label
                                      className="ms-1"
                                      htmlFor="Master Card"
                                    >
                                      Master Card
                                    </label>
                                  </div>
                                </div>
                                <div className="radio-container">
                                  <div className="d-flex">
                                    <input
                                      type="radio"
                                      name="AmericanExpress"
                                      value="American Express"
                                      id="AmericanExpress"
                                      checked={
                                        paymentOptionSelected ===
                                        "American Express"
                                      }
                                      onChange={changePaymentOptionSeleted}
                                    />
                                    <label
                                      className="ms-1"
                                      htmlFor="American Express"
                                    >
                                      American Express
                                    </label>
                                  </div>
                                </div>
                              </div>
                              {/* card form */}
                              <form className="d-flex justify-content-start gap-3">
                                <div className="form-group">
                                  <label for="card-number">
                                    {paymentOptionSelected} Number
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    id="card-number"
                                    placeholder="Enter Card Number"
                                    onChange={(e) =>
                                      setDigitalPaymentNumber(e.target.value)
                                    }
                                  />
                                </div>
                                <div className="form-group">
                                  <label for="card-expire-date">
                                    Expiry Date
                                  </label>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <DatePicker
                                      label={" "}
                                      format={dateFormat}
                                      onChange={(v) => setExpireDate(v?.$d)}
                                      slotProps={{
                                        textField: { size: "small" },
                                      }}
                                    />
                                  </LocalizationProvider>
                                </div>
                              </form>
                            </>
                          )}
                        </div>
                        {/* digital payment */}
                        <div className="payment-container mb-1 pt-1 rounded">
                          <div className="d-flex justify-content-between align-items-center  mb-1">
                            <div className="d-flex">
                              <input
                                disabled
                                type="radio"
                                name="digital-payment"
                                value="digital-payment"
                                id="digital-payment"
                                checked={selectedPayment === "digital-payment"}
                                onChange={changeSelectedHandler}
                              />
                              <label className="ms-2" htmlFor="digital-payment">
                                Digital Payment
                              </label>
                            </div>
                            <div className="d-flex justify-content-end align-items-center gap-1">
                              <img
                                className="payment-icon"
                                src={digitalPayment}
                                alt="digital-payment"
                              />
                            </div>
                          </div>
                          {/* payment option */}
                          {selectedPayment === "digital-payment" && (
                            <>
                              <div className="p-2 d-flex gap-2">
                                <div className="radio-container">
                                  <div className="d-flex">
                                    <input
                                      type="radio"
                                      name="method"
                                      value="Rocket"
                                      id="Rocket"
                                      checked={
                                        paymentOptionSelected === "Rocket"
                                      }
                                      onChange={changePaymentOptionSeleted}
                                    />
                                    <label className="ms-1" htmlFor="Rocket">
                                      Rocket
                                    </label>
                                  </div>
                                </div>
                                {/* master card */}
                                <div className="radio-container">
                                  <div className="d-flex">
                                    <input
                                      type="radio"
                                      name="method"
                                      value="Nagad"
                                      id="Nagad"
                                      checked={
                                        paymentOptionSelected === "Nagad"
                                      }
                                      onChange={changePaymentOptionSeleted}
                                    />
                                    <label className="ms-1" htmlFor="Nagad">
                                      Nagad
                                    </label>
                                  </div>
                                </div>
                                <div className="radio-container">
                                  <div className="d-flex">
                                    <input
                                      type="radio"
                                      name="method "
                                      value="BKash"
                                      id="BKash"
                                      checked={
                                        paymentOptionSelected === "BKash"
                                      }
                                      onChange={changePaymentOptionSeleted}
                                    />
                                    <label className="ms-1" htmlFor="BKash">
                                      BKash
                                    </label>
                                  </div>
                                </div>
                              </div>
                              {/*  form */}
                              <form className="d-flex justify-content-start gap-3">
                                <div className="form-group">
                                  <label for="card-number">
                                    {paymentOptionSelected} {""}
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    id="card-number"
                                    placeholder="Payment Number"
                                    onChange={(e) =>
                                      setDigitalPaymentNumber(e.target.value)
                                    }
                                  />
                                </div>
                              </form>
                            </>
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            )}

            <div className="payment_slip" ref={componentPaymetSlipRef}>
              <DepositSlip
                ownerDetails={ownerDetails}
                depostiHistory={depostiHistory}
                amount={depositAmount}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
