import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MaterialTable from "material-table";
import Modal from "react-modal";
import "../../../CSS/MemberSetup.css";
import http from '../../../http';
import Swal from 'sweetalert2';
import allCustomStyles from '../../../CSS/ReactModalStyle';

const Unit = () => {
  const { serviceModal } = allCustomStyles;
  const [random, setRandom] = useState();
  const [spinner, setSpinner] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [type, setType] = useState();
  const [updateButton, setUpdateButton] = useState(false);
  const [error, setError] = useState({
    name: ''
  });
  const [id, setId] = useState();
  function openModal() {
    if (!type) {
      setError({ name: '' })
    }
    setType('')
    setUpdateButton(false)
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }
  const [data, setData] = useState([]);

  useEffect(() => {
    http
      .get(`unit-type`)
      .then((res) => {
        setData(res.data);
        setSpinner(false);
      })
      .catch((err) => {
        console.log(err);
        setSpinner(false);
      });
  }, [random]);

  const [isLoading, setisLoading] = useState(false)

  const handleSubmit = (event) => {
    event.preventDefault();
    setisLoading(true)
    if (!type) {
      setError({ name: 'Required Service Unit' })
    }
    if (id) {
      http
        .put(`/unit-type/${id}`, {
          unitType: type
        }).then((res) => {
          setisLoading(false)
          try {
            if (res.data.status === 200) {
              Swal.fire({
                position: 'center',
                icon: 'success',
                title: res?.data?.message,
                showConfirmButton: false,
                timer: 1500
              })
              setUpdateButton(false)
              setRandom(Math.random())
              setIsOpen(false);
              setId('')
            }
          } catch (error) {
            console.log(error)
          }
        }).catch((err) => {
          console.log(err);
          setSpinner(false);
          setisLoading(false)
        });
    } else {
      http
        .post("/unit-type", {
          unitType: type
        }).then((res) => {
          setisLoading(false)
          try {
            if (res?.data?.status === 200) {
              console.log('res', res)
              Swal.fire({
                position: 'center',
                icon: 'success',
                title: res?.data?.message,
                showConfirmButton: false,
                timer: 1500
              })
              setUpdateButton(false)
              setRandom(Math.random())
              setIsOpen(false);
            }
          } catch (error) {
            console.log(error)
          }
        }).catch((err) => {
          console.log(err);
          setSpinner(false);
          setisLoading(false)
        });
    }
  }


  const updateMemberType = (id) => {
    if (!type) {
      setError({ name: '' })
    }
    const unit_type = data?.find((item) => item.id == id)
    setType(unit_type.unit_type)
    setId(unit_type.id)
    setUpdateButton(true)
    setIsOpen(true);

  }
  const deleteMemberType = (id) => {
    const unit = data.find((item) => item.id == id);
    http
      .delete(`/unit-type/${unit.id}`).then((res) => {
        try {
          if (res.data.status === 200) {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: res?.data?.message,
              showConfirmButton: false,
              timer: 1500
            })
            setRandom(Math.random())
            setIsOpen(false);
          }
        } catch (error) {
          console.log(error)
        }
      }).catch((err) => {
        console.log(err);
        setSpinner(false);
      });
  }

  const columns = [
    {
      title: "SL",
      field: "",
      render: (row) => <div>{row.tableData.id + 1}</div>,
      width: "20 !important",
      cellStyle: {
        textAlign: "center",
      },
    },

    {
      title: "Name",
      field: `unit_type`,
      cellStyle: {
        textAlign: "center",
      },
    },

    {
      title: "Action",
      field: "patient",
      render: (row) => (
        <div>
          <button className="btn btn-sm action-btn" onClick={() => updateMemberType(row?.id)}>
            <i className="far fa-edit"></i>
          </button>
          <button className="btn btn-sm action-btn" onClick={() => deleteMemberType(row?.id)}>
            <i className="far fa-trash"></i>
          </button>

        </div >
      ),
      cellStyle: {
        textAlign: "center",
      },
    },
  ];
  //  

  return (
    <div className="page-content adjustment-type-table">
      <div className="custom-card p-2 d-flex justify-content-between mb-2 align-items-center">
        <h6>Service Uint</h6>
        <div>
          <button
            style={{ marginTop: "1px" }}
            className="btn btn-sm btn-primary float-end"
            onClick={openModal}
          >
            Add
          </button>
        </div>
      </div>
      <MaterialTable
        columns={columns}
        data={data}
        isLoading={spinner}
        options={{
          search: true,
          showTitle: false,
          searchFieldAlignment: "left",
          pageSize: 10,
          emptyRowsWhenPaging: false,
          pageSizeOptions: [5, 10, 20, 50, 100],
        }}
      />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={serviceModal}
        contentLabel="Example Modal"
      >
        <form onSubmit={handleSubmit}>
          <div className="product_modal">
            <div className="page-content">
              <div className=" patients-head ">
                <h5 className="fw-normal custom_py-3 px-2  text-start mb-2 card-name">
                  {updateButton ? "Edit" : "Add"}
                  <span style={{ cursor: "pointer", fontSize: "16px" }} onClick={closeModal} className='float-end'><i className="fal fa-times"></i></span>
                </h5>
              </div>
              <div className=" p-3">

                <div className="form-group">
                  <label htmlFor="type" className="mb-1">
                    Name
                  </label>
                  <input
                    required
                    className="form-control"
                    id="type"
                    name="type"
                    value={type}
                    onChange={(event) => setType(event.target.value)}
                  />
                  {error?.name && <div className="text-danger">{error?.name}</div>}
                </div>

              </div>
            </div>

            {
              isLoading ?
                <button className="btn mt-2 btn-sm btn-info float-end text-uppercase" type="button"> saving ...</button>
                :
                updateButton === true ? (
                  <button className="btn mt-2 btn-sm btn-success float-end text-uppercase" type="submit"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-save mb-1"><path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path><polyline points="17 21 17 13 7 13 7 21"></polyline><polyline points="7 3 7 8 15 8"></polyline></svg> Update</button>
                ) : (
                  <button className="btn mt-2 btn-sm btn-primary float-end text-uppercase" type="submit"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-save mb-1"><path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path><polyline points="17 21 17 13 7 13 7 21"></polyline><polyline points="7 3 7 8 15 8"></polyline></svg> Save</button>
                )}

          </div>
        </form>
      </Modal >
    </div >
  );
};

export default Unit;
