import React from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaPlus } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
const Service = () => {
    const location = useLocation();
    return (
        <>
            <div className="row mt-3">
                <div className='col-lg-2' style={{ borderRadius: "10px" }}>
                    <div className="page-content bg-white">
                        <div className="sidebar-body">
                            <ul className="nav">
                                <li className='nav-item'>
                                    <Link
                                        to="/utlilty"
                                        className={`nav-link ${location.pathname === "/utlilty" ? "active" : ""
                                            }`}
                                    >
                                        <span className='pe-2'><FaPlus /></span>
                                        Utlilty
                                    </Link>
                                </li>
                                <li className='nav-item'>
                                    <Link
                                        to="/service"
                                        className={`nav-link ${location.pathname === "/service" ? "active" : ""
                                            }`}
                                    >
                                        <span className='pe-2'><FaPlus /></span>
                                        Service
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className='col-lg-10'>
                    <div className="page-content bg-white">
                        <h3 className="text-center my-3 mb-4">Service</h3>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='mb-4'>
                                    <div className="row mt-3 justify-content-between">

                                        <div className="col-3">
                                            <div className="row mb-2">
                                                <div className="col-3">
                                                    <label> Group</label>
                                                </div>
                                                <div className="col-9">
                                                    <select name='unit_measurement' className="form-select form-select-sm">
                                                        <option value="1">Select</option>
                                                        <option value="SQF">Utilty</option>
                                                        <option value="SQM">Service</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-1">
                                            <button className="btn btn-sm btn-primary me-3" style={{ margin: "0 0 4px 0" }}>Active</button>
                                        </div>
                                    </div>
                                    <div className="unit-manager-table invoice-item-table mb-3">
                                        <table className=''>
                                            <tr>
                                                <td>SL</td>
                                                <td>Description</td>
                                                <td>Service</td>
                                                <td>Amount</td>
                                                <td>Efficative Date</td>
                                                <td>VT</td>
                                            </tr>
                                            <tr>
                                                <td>#</td>
                                                <td width="30%"> Lorem Inspum eropo ekuot qulo </td>
                                                <td>
                                                    Lorem Inspum eropo ekuot qulo
                                                </td>
                                                <td >
                                                    60000
                                                </td>


                                                <td width='10%'>
                                                    <DatePicker
                                                        required
                                                        selected={new Date()}
                                                        dateFormat="dd/MM/yyyy"
                                                        className="form-control form-control-sm"
                                                        onChange="" />
                                                </td>
                                                <td>

                                                </td>
                                            </tr>
                                        </table>

                                        <div className="d-flex justify-content-end">
                                            <button type='submit' className="btn btn-sm btn-primary me-3 mt-3">Save</button>
                                            <button type='submit' className="btn btn-sm btn-primary me-3 mt-3">Activated</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Service