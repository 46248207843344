import React, { useContext, useEffect, useState } from "react";
import "./Dashboard.css";
import Chart from "react-apexcharts";
import img1 from "../../front_assets/Building/building4.jpg";
import img2 from "../../front_assets/Building/building5.jpg";
import img3 from "../../front_assets/Building/building6.jpg";
import noImage from "../../front_assets/images/no-image.png";
import { FaMobileAlt, FaRegUserCircle } from "react-icons/fa";
import { IoHomeOutline } from "react-icons/io5";
import { BsTelephone } from "react-icons/bs";
import http from "../../http";
import Setting from "../Setting/Setting";
import CircularProgress from "@mui/material/CircularProgress";
import { Grid, LinearProgress } from "@mui/material";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import MaterialTable from "material-table";
import moment from "moment";
import Select from "react-select";
import allCustomStyles from "../../CSS/ReactModalStyle";
import propertieContext from "../../navbar/propertieContext";
import { MdOutlineMail } from "react-icons/md";

export default function Dashboard() {
  const { colourStyles } = allCustomStyles;
  const { organizationInfo } = useContext(propertieContext);


  const getOrdinal = (number) => {
    const suffixes = ["th", "st", "nd", "rd"];
    const lastDigit = number % 10;
    const suffix =
      lastDigit <= 3 && number !== 11 && number !== 12 && number !== 13
        ? suffixes[lastDigit]
        : suffixes[0];
    return number + suffix;
  };
  const years = [
    { value: "2023", label: "2023" },
    { value: "2024", label: "2024" },
    { value: "2025", label: "2025" },
    { value: "2026", label: "2026" },
    { value: "2027", label: "2027" },
  ];

  const { currencyFormat, dateFormat } = Setting;
  const [cardData, setcardData] = useState();
  const [isLoading, setisLoading] = useState(false);
  const [chartData, setchartData] = useState({
    // series: [
    //   {
    //     name: "Rent Due",
    //     data: [5.0, 10, 12, 6, 22, 43],
    //     color: "#FF7878",
    //   },
    //   {
    //     name: "Rent Collection",
    //     data: [15, 23, 20, 8, 13, 27],
    //     color: "#829DFD",
    //   },
    //   {
    //     name: "Total",
    //     data: [40, 17, 15, 15, 21, 14],
    //     color: "#DFDFDF",
    //   },
    // ],
    options: {
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: false,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
          dataLabels: {
            total: {
              enabled: true,
              style: {
                fontSize: "13px",
                fontWeight: 900,
              },
            },
          },
        },
        colors: ["#FF7878", "#829DFD", "#DFDFDF"],
      },
      xaxis: {
        type: "datetime",
        categories: [
          "01/01/2023",
          "02/01/2023",
          "03/01/2023",
          "04/01/2023",
          "05/01/2023",
          "06/01/2023",
          "07/01/2023",
          "08/01/2023",
          "08/01/2023",
          "09/01/2023",
          "10/01/2023",
          "11/01/2023",
          "12/01/2023",
        ],
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            // return parseInt(value) + " k";
            return currencyFormat(value.toFixed(2)) + " k";
            // return currencyFormat((value / 1000).toFixed(1)) + "k";
          },
        },
      },
      legend: {
        position: "top",
        // offsetY: 40
      },
      fill: {
        opacity: 1,
      },
    },
  });

  const [collection, setcollection] = useState();
  const [due, setdue] = useState();
  const [year, setyear] = useState(moment().format("YYYY"));

  useEffect(() => {
    let sub = false;
    if (!sub) {
      setisLoading(true);
      apiCall();
    }

    return () => {
      sub = true;
    };
  }, []);

  const [chartLoading, setchartLoading] = useState(false);
  const apiCall = (y = year) => {
    setchartLoading(true);
    http
      .get(`dashboard/${y}`)
      .then((res) => {
        setcardData(res.data.data.card);
        setchartData({
          ...chartData,
          series: res.data.data.chart.series,
          options: {
            ...chartData.options,
            xaxis: {
              type: "datetime",
              categories: res.data.data.chart.months,
            },
          },
        });

        const collectionData = res.data.data.rent
          .filter((v) => {
            return Number(v?.received_amount) != 0;
          })
          .map((v1) => {
            return {
              ...v1,
              name:
                v1.floor.tenant === null
                  ? v1.floor.owner.name
                  : v1.floor.tenant.name,
              date: moment(v1?.updated_at).format(dateFormat),
              floor: getOrdinal(v1?.floor?.floor),
              unit: v1?.floor?.unit_name,
              remarks: Number(v1?.due) <= 0 ? "Paid" : "Partial Payment",
            };
          });

        setcollection(collectionData);

        const dueData = res.data.data.rent
          .filter((v) => {
            return v.received_amount < v.grand_total;
          })
          .map((v1) => {
            return {
              ...v1,
              name:
                v1.floor.tenant === null
                  ? v1.floor.owner.name
                  : v1.floor.tenant.name,
              date: moment(v1?.updated_at).format(dateFormat),
              floor: getOrdinal(v1?.floor?.floor),
              unit: v1?.floor?.unit_name,
              remarks: v1?.due === v1.grand_total ? "Due" : "Partial Due",
            };
          });

        setdue(dueData);

        setchartLoading(false);

        setisLoading(false);
      })
      .catch((err) => {
        setchartLoading(false);
        setisLoading(false);
      });
  };

  const [showMoreTanat, setshowMoreTanat] = useState(false);
  const [showMoreOwner, setshowMoreOwner] = useState(false);

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip placement="top-start" {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: "auto",
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }));

  const rentCollectionColumn = [
    {
      title: "Invoice",
      field: "invoice",
      width: 200,
    },

    { title: "Name", field: "name" },
    { title: "Building", field: "building.title" },
    { title: "Floor", field: "floor" },
    { title: "Unit", field: "unit" },
    {
      title: "Total",
      field: "grand_total",
      cellStyle: {
        color: "#666666",
      },
    },
    {
      title: "Collected",
      field: "received_amount",
      cellStyle: {
        color: "#30ad2b",
      },
    },
    {
      title: "Date",
      field: "date",
      cellStyle: {
        color: "#30ad2b",
      },
    },
    {
      title: "Remarks",
      field: "remarks",
      cellStyle: {
        color: "#30ad2b",
      },
    },
  ];
  const dueColumn = [
    { title: "Invoice", field: "invoice", width: 200 },

    { title: "Name", field: "name" },
    { title: "Building", field: "building.title" },
    { title: "Floor", field: "floor" },
    { title: "Unit", field: "unit" },
    {
      title: "Total",
      field: "grand_total",
      cellStyle: {
        color: "#666666",
      },
    },
    {
      title: "Due",
      field: "due",
      cellStyle: {
        color: "#a12323",
      },
    },
    {
      title: "Date",
      field: "date",
      cellStyle: {
        color: "#a12323",
      },
    },
    {
      title: "Remarks",
      field: "remarks",
      cellStyle: {
        color: "#a12323",
      },
    },
  ];

  return (
    <div className="page-content" style={{ background: "rgb(249 249 249)" }}>
      <div className="row">
        <div className="col-9">
          <div className="row" style={{ marginLeft: "1px" }}>
            <div className="col-2 dashboard-card tag-content">
              <p>Total Owners</p>
              <p>
                {isLoading ? (
                  <CircularProgress
                    style={{ width: "25px", height: "25px", marginTop: "5px" }}
                  />
                ) : (
                  cardData?.owner?.length
                )}
              </p>
            </div>
            <div className="col-2 dashboard-card tag-content">
              <p>Total Tenants</p>
              <p>
                {isLoading ? (
                  <CircularProgress
                    style={{ width: "25px", height: "25px", marginTop: "5px" }}
                  />
                ) : (
                  cardData?.tenant?.length
                )}
              </p>
            </div>
            <div
              className="col-3 dashboard-card tag-content"
              style={{ color: "blue" }}
            >
              <p>Total Bill</p>
              <p>
                {isLoading ? (
                  <CircularProgress
                    style={{ width: "25px", height: "25px", marginTop: "5px" }}
                  />
                ) : (
                  currencyFormat(cardData?.rent)
                )}
              </p>
            </div>

            <div
              className="col-2 dashboard-card tag-content"
              style={{ color: "green" }}
            >
              <p>Total Collection</p>
              <p>
                {isLoading ? (
                  <CircularProgress
                    style={{ width: "25px", height: "25px", marginTop: "5px" }}
                  />
                ) : (
                  currencyFormat(cardData?.recived)
                )}
              </p>
            </div>
            <div
              className="col-2 dashboard-card tag-content"
              style={{ color: "red" }}
            >
              <p>Total Due</p>
              <p>
                {isLoading ? (
                  <CircularProgress
                    style={{ width: "25px", height: "25px", marginTop: "5px" }}
                  />
                ) : (
                  currencyFormat(cardData?.due)
                )}
              </p>
            </div>
          </div>

          <div className="dashboard-card rent-chart mt-3">
            {chartLoading ? (
              <>
                <LinearProgress />
                <div className="d-flex justify-content-between mb-2">
                  <h6>Billing Chart</h6>
                  <div style={{ width: "200px" }}>
                    <Select
                      styles={colourStyles}
                      value={years.filter((v) => v.value === year)}
                      onChange={(v) => {
                        setyear(v.value);
                      }}
                      options={years}
                      placeholder="Year"
                    />
                  </div>
                </div>

                <Chart
                  options={chartData?.options}
                  series={chartData?.series}
                  type="bar"
                  height={350}
                />
              </>
            ) : (
              <div className="">
                <div className="d-flex justify-content-between mb-2">
                  <h6>Billing Chart</h6>
                  <div style={{ width: "200px" }}>
                    <Select
                      styles={colourStyles}
                      value={years.filter((v) => v.value === year)}
                      onChange={(v) => {
                        setyear(v.value);
                        apiCall(v.value);
                      }}
                      options={years}
                      placeholder="Year"
                    />
                  </div>
                </div>

                <Chart
                  options={chartData?.options}
                  series={chartData?.series}
                  type="bar"
                  height={350}
                />
              </div>
            )}
          </div>
          <div className="dashboard mt-3">
            <MaterialTable
              isLoading={isLoading}
              title="Bill Collected"
              columns={rentCollectionColumn}
              data={collection}
              options={{
                search: true,
                showTitle: true,
                searchFieldAlignment: "right",
                pageSize: 5,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [5, 10, 20, 50, 100],
                rowStyle: {
                  fontSize: 14,
                  fontWeight: 400,
                },
              }}
            />
          </div>
          <div className="dashboard mt-3">
            <MaterialTable
              isLoading={isLoading}
              title="Current Due"
              columns={dueColumn}
              data={due}
              options={{
                search: true,
                showTitle: true,
                searchFieldAlignment: "right",
                pageSize: 5,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [5, 10, 20, 50, 100],
                rowStyle: {
                  fontSize: 14,
                  fontWeight: 400,
                },
              }}
            />
          </div>
        </div>
        <div
          className="col-3"
          style={{ background: "#E7EAF6", paddingBottom: "20px" }}
        >
          <img src={img1} className="building-img" height={125} />
          <div className="d-flex mt-2 gap-2">
            <div style={{ width: "50%" }}>
              {" "}
              <img src={img2} height={100} className="building-img" />
            </div>
            <div style={{ width: "50%" }}>
              {" "}
              <img src={img3} height={100} className="building-img" />
            </div>
          </div>

          <div className="dashboard-card mt-2 p-2">
            <div
              style={{ borderBottom: "1px solid grey", paddingBottom: "5px" }}
            >
              <h6>Organization Details</h6>
            </div>
            <div className="body mt-2">
              <div className="row">
                <div className="col-2">
                  <IoHomeOutline />
                </div>
                <div className="col-10">{organizationInfo?.user?.organization_name}</div>
              </div>
              <div className="row">
                <div className="col-2">
                  <MdOutlineMail />
                </div>
                <div className="col-10">{organizationInfo?.user?.organization_email}</div>
              </div>
              <div className="row">
                <div className="col-2">
                  <FaMobileAlt />
                </div>
                <div className="col-10">{organizationInfo?.user?.organization_mobile}</div>
              </div>
              {/* <div className="row">
                <div className="col-2">
                  <BsTelephone />
                </div>
                <div className="col-10">Mr Test user</div>
              </div> */}
            </div>
          </div>

          <div className="dashboard-card mt-2 p-2">
            <div className="d-flex justify-content-between">
              <h6>Tenants</h6>
              <button
                onClick={() => setshowMoreTanat(!showMoreTanat)}
                className="see-all-btn"
              >
                See All
              </button>
            </div>
            {isLoading ? (
              <CircularProgress
                style={{ width: "25px", height: "25px", marginTop: "5px" }}
              />
            ) : (
              <div
                className={`d-flex ${!showMoreTanat ? `overflow-hidden` : `flex-wrap`
                  } gap-2 mt-2`}
              >
                {cardData?.tenant?.map((v, i) => (
                  <HtmlTooltip
                    key={i}
                    title={
                      <React.Fragment style={{ width: "500px" }}>
                        <Grid container spacing={1}>
                          <Grid item xs={2}>
                            <p>Name</p>
                          </Grid>
                          <Grid item xs={10}>
                            <p>: {v.name}</p>
                          </Grid>
                          <Grid item xs={2}>
                            <p>Phone</p>
                          </Grid>
                          <Grid item xs={10}>
                            <p>: {v?.mobile_1}</p>
                          </Grid>
                          <Grid item xs={2}>
                            <p>Email</p>
                          </Grid>
                          <Grid item xs={10}>
                            <p>: {v.email}</p>
                          </Grid>
                        </Grid>
                      </React.Fragment>
                    }
                  >
                    {(!v?.picture || v?.picture == "null") ? (
                      <img src={noImage} className="avatar" />
                    ) : (
                      <img
                        src={`${global.img_Url}/images/${v?.picture}`}
                        className="avatar"
                      />
                    )}
                  </HtmlTooltip>
                ))}
              </div>
            )}
          </div>
          <div className="dashboard-card mt-2 p-2">
            <div className="d-flex justify-content-between">
              <h6>Owners</h6>
              <button
                onClick={() => setshowMoreOwner(!showMoreOwner)}
                className="see-all-btn"
              >
                See All
              </button>
            </div>
            {isLoading ? (
              <CircularProgress
                style={{ width: "25px", height: "25px", marginTop: "5px" }}
              />
            ) : (
              <div
                className={`d-flex ${!showMoreOwner ? `overflow-hidden` : `flex-wrap`
                  } gap-2 mt-2`}
              >
                {cardData?.owner?.map((v, i) => (
                  <HtmlTooltip
                    key={i}
                    title={
                      <React.Fragment style={{ width: "500px" }}>
                        <Grid container spacing={1}>
                          <Grid item xs={2}>
                            <p>Name</p>
                          </Grid>
                          <Grid item xs={10}>
                            <p>: {v.name}</p>
                          </Grid>
                          <Grid item xs={2}>
                            <p>Phone</p>
                          </Grid>
                          <Grid item xs={10}>
                            <p>: {v?.mobile_1}</p>
                          </Grid>
                          <Grid item xs={2}>
                            <p>Email</p>
                          </Grid>
                          <Grid item xs={10}>
                            <p>: {v.email}</p>
                          </Grid>
                        </Grid>
                      </React.Fragment>
                    }
                  >
                    {(!v?.picture || v?.picture == "null") ? (
                      <img src={noImage} className="avatar" />
                    ) : (
                      <img
                        src={`${global.img_Url}/images/${v?.picture}`}
                        className="avatar"
                      />
                    )}
                  </HtmlTooltip>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
