
import MaterialTable from 'material-table'
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import Swal from 'sweetalert2';
import http from '../../../http';
import { Link } from 'react-router-dom';
import avatar from '../../../front_assets/images/avatar.png'
import moment from 'moment';
export default function Movements() {
    const deleteRowData = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        })
            .then((result) => {
                if (result.isConfirmed) {
                    http.delete(`employees/${id}`)
                        .then((res) => {
                            setUpdate(!update);
                            setIsOpen(false);
                            Swal.fire({
                                position: 'top-center',
                                icon: 'success',
                                title: 'Success !',
                                text: 'Banner Deleted Successfully',
                                showConfirmButton: false,
                                timer: 1500
                            })
                        })
                }
            })
    }
   
    const columns = [
        {
            title: "SL",
            field: "",
            render: (row) => <div>{row.tableData.id + 1}</div>,
            width: "20 !important",
            cellStyle: {
                textAlign: "center",
            },
        },

        {
            title: "Visitor",
            field: `name`,
            render: (row) => <div>{row?.visitor?.name}</div>,
            cellStyle: {
                textAlign: "center",
            },
        },
        {
            title: "Owner",
            field: `mobile_1`,
            render: (row) => <div>{row?.owner?.name}</div>,
            cellStyle: {
                textAlign: "center",
            },
        },
        {
            title: "Mobile",
            field: `mobile_1`,
            render: (row) => <div>{row?.visitor?.mobile}</div>,
            cellStyle: {
                textAlign: "center",
            },
        },
       
        {
            title: "Email",
            render: (row) => <div>{row?.visitor?.email}</div>,
            cellStyle: {
                textAlign: "center",
            },
        },
        {
            title: "Date and Time",
            field: `designation_id`,
            render: (row) => <div>{moment(row?.created_at).format('DD-MM-YYYY hh:mm A')}</div>,
            cellStyle: {
                textAlign: "center",
            },
        },
        {
            title: "Status",
            field: `designation_id`,
            render: (row) => <div>{row?.status}</div>,
            cellStyle: {
                textAlign: "center",
            },
        },
       
        {
            title: "Image",
            field: 'content',
            render: (row) => <div>
                {
                    row.picture ?
                        <img src={`${global.img_Url}/images/${row?.picture}`} width="50" height="50" alt='Profile' />
                        :
                        <img src={`${global.img_Url}/images/${row?.visitor?.picture}`} width="50" height="50" alt='Profile' />
                }

            </div>,
            cellStyle: {
                textAlign: "center",
            },
        },

        // {
        //     title: "Action",
        //     field: "patient",
        //     render: (row) => (
        //         <div>
        //             <Link to={`/edit-movement/${row.id}`}>
        //                 <button
        //                     className="btn btn-sm action-btn"
        //                 >
        //                     <i className="far fa-edit"></i>
        //                 </button>
        //             </Link>
        //             <button
        //                 onClick={() => deleteRowData(row.id)}
        //                 className="btn btn-sm action-btn"
        //             >
        //                 <i className="far fa-trash"></i>
        //             </button>
        //         </div>
        //     ),
        //     cellStyle: {
        //         textAlign: "center",
        //     },
        // },
    ];
    const [data, setData] = useState([]);
    const [update, setUpdate] = useState(false);
    const [spinner, setSpinner] = useState(false);
    useEffect(() => {
        const controller = new AbortController();
        setSpinner(true);
        http
            .get(`movements`)
            .then((res) => {
                setData(res.data)
                setSpinner(false);
            })
            .catch((err) => {
                console.log(err);
                setSpinner(false);
            });


        return () => {
            controller.abort();
        };
    }, [update]);
    // add modal 
    const customStyles = {
        content: {
            top: '55%',
            left: '58%',
            // right: 'auto',
            // bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            maxHeight: "90%",
            width: "76%",
            height: "480px",
            padding: "10px",
        },
    };
    const [modalIsOpen, setIsOpen] = useState(false);
    const [categoryData, setCategoryData] = useState({
        workSectionId: "",
        index: "",
        title: "",
        content: ""
    })

  
    return (
        <div className='page-content adjustment-type-table'>
            <div className="custom-card p-2 d-flex justify-content-between mb-2 align-items-center">
                <h6>Movements</h6>
                <div>
                    <Link to='/add-movement'>

                        <button style={{ marginTop: "1px" }} className='btn btn-sm btn-primary float-end'>Add </button>
                    </Link>
                </div>
            </div>

            <MaterialTable
                columns={columns}
                data={data}
                isLoading={spinner}
                options={{
                    search: true,
                    showTitle: false,
                    searchFieldAlignment: "left",
                    pageSize: 10,
                    emptyRowsWhenPaging: false,
                    pageSizeOptions: [5, 10, 20, 50, 100],
                }}
            />
        </div>
    )
}