import React, { Fragment, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import http from "../../../http";
import Swal from "sweetalert2";
import { Navigate, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Setting from "../../Setting/Setting";
const AddServiceFeeSetup = () => {
  const [utlitiyState, setUtlitiyState] = useState(true);
  const [units, setUnits] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [fees, setFees] = useState([]);
  const [serviceType, setServiceType] = useState([]);
  const [unitType, setUnitType] = useState([]);
  const [allData, setAllData] = useState([]);
  const [activeData, setActiveData] = useState([]);
  const { dateFormat } = Setting;
  const [random, setRandom] = useState();
  const [enableEndDate, setEnableEndDate] = useState(false);
  const navigate = useNavigate();
  const [data, setData] = useState({
    service_group_id: "",
    service_type_id: "",
    effactive_date: new Date(),
    end_date: "",
    isActive: "",
    id: "",
    FsCode: "1000",
    code: "",
  });



  const handleChangeUint = (event) => {
    const { name, value } = event.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFeesChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    const feesData = units.find((item) => item.id == value);
    const feesGetData = feesData;
    setFees(feesGetData);
    setUtlitiyState(value);
  };

  const handleChangeInputUintMesureMind = (event, i) => {
    event.preventDefault();
    const { name, value } = event.target;
    setFees((prevData) => {
      const updatedFees = prevData.fees.map((fee, index) =>
        index === i ? { ...fee, unit_measurement: value } : fee
      );
      return {
        ...prevData,
        fees: updatedFees,
      };
    });
  };

  const handleChangeInputUint = (event, i) => {
    event.preventDefault();
    const { name, value } = event.target;
    setFees((prevData) => {
      const updatedFees = prevData.fees.map((fee, index) =>
        index === i ? { ...fee, unit: value } : fee
      );

      return {
        ...prevData,
        fees: updatedFees,
      };
    });
  };
  const handleChangeInputRemarks = (event, i) => {
    event.preventDefault();
    const { name, value } = event.target;
    setFees((prevData) => {
      const updatedFees = prevData.fees.map((fee, index) =>
        index === i ? { ...fee, remarks: value } : fee
      );
      return {
        ...prevData,
        fees: updatedFees,
      };
    });
  };

  useEffect(() => {
    setSpinner(true);
    http
      .get(`service-group`)
      .then((res) => {
        setUnits(res?.data);
        setSpinner(false);
      })
      .catch((err) => {
        setSpinner(false);
        console.log(err);
      });
    http
      .get(`fee-setup`)
      .then((res) => {
        setData({ ...data, FsCode: res?.data?.maxFsCode });
        setAllData(res?.data?.data);
        setSpinner(false);
      })
      .catch((err) => {
        console.log(err);
        setSpinner(false);
      });
    http
      .get(`service-type`)
      .then((res) => {
        setServiceType(res?.data);
        setSpinner(false);
      })
      .catch((err) => {
        console.log(err);
        setSpinner(false);
      });
    http
      .get(`unit-type`)
      .then((res) => {
        setUnitType(res?.data);
        setSpinner(false);
      })
      .catch((err) => {
        console.log(err);
        setSpinner(false);
      });

    // active fee service setup
    http
      .get(`active-fee-setup`)
      .then((res) => {
        setActiveData(res?.data?.data);
        setSpinner(false);
      })
      .catch((err) => {
        console.log(err);
        setSpinner(false);
      });
  }, [random]);
  const [errorMessage, setErrorMessage] = useState({
    service_group_id: "",
    effactive_date: "",
    service_type_id: "",
    unit_measurement: "",
    end_date: "",
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!data.service_group_id) {
      setErrorMessage({ service_group_id: "Service group is required" });
      return false;
    } else if (!data.service_type_id) {
      setErrorMessage({ service_type_id: "Service type field is required" });
      return false;
    }
    else if (!data.effactive_date) {
      setErrorMessage({ effactive_date: "Effactive date field is required" });
      return false;
    }
    if (data?.end_date && data.end_date.length > 0) {
      if (data?.effactive_date > data?.end_date) {
        setErrorMessage({
          end_date: "End date must be greater than effactive date",
        });
        return false;
      }
    }

    const formdata = JSON.stringify({ data });
    const feesData = JSON.stringify({ fees });
    const findActiveServiceGroup = activeData?.find(
      (item) =>
        item?.service_group_id == data?.service_group_id &&
        item?.service_type_id == data?.service_type_id
    );
    if (findActiveServiceGroup) {
      Swal.fire({
        title: "Are you sure?",
        text: "You have already activate this service!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, save it!",
      }).then((result) => {
        if (result.isConfirmed) {
          http.post("fee-setup", { formdata, feesData }).then((res) => {
            console.log("res", res);
            Swal.fire({
              position: "top-center",
              icon: "success",
              title: "Success !",
              text: "Data Added Successfully",
              showConfirmButton: false,
              timer: 1500,
            });
            setFees("");
            navigate("/service-fee-setup");
          });
        }
      });
    } else {
      http
        .post("fee-setup", { formdata, feesData })
        .then((res) => {
          console.log(res, "res");
          Swal.fire({
            position: "top-center",
            icon: "success",
            title: "Success !",
            text: "Data Added Successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          setFees("");
          navigate("/service-fee-setup");
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
            position: "top-center",
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            showConfirmButton: false,
            timer: 1500,
          });
        });
    }
  };
  const sethandleEndDate = (event) => {
    const value = event.target.checked;
    if (value == false) {
      setEnableEndDate(false);
    } else {
      setEnableEndDate(true);
    }
  };

  console.log("data", data);
  console.log("alldata", allData);

  return (
    <>
      <div className="page-content bg-white">
        <h3 className="text-center my-3 mb-1">Fee Setup</h3>
        <div className="row">
          <div className="col-12">
            <div className="mb-4">
              <div className="row mt-3 justify-content-center align-items-center">
                <div className="col-lg-12 mb-1">
                  <div className="col-lg-10">
                    <div className="row mb-2">
                      <div className="col-2">
                        <label> Fee Setup code : </label>
                      </div>
                      <div className="col-3">
                        <input
                          type="hidden"
                          name="FsCode"
                          value={`${data?.FsCode ? "FS-" + data?.FsCode + 1 : ""
                            }`}
                        />
                        {`${data?.FsCode ? "FS-" + data?.FsCode : ""}`}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`${enableEndDate ? "col-2" : "col-3 "}`}>
                  <div className="row mb-2 justify-content-center align-items-center">
                    <div className="col-2">
                      <label> Group</label>
                    </div>
                    <div className="col-10">
                      <select
                        name="service_group_id"
                        className="form-select form-select-sm"
                        onChange={(event) => handleFeesChange(event)}
                        required
                      >
                        <option selected disabled value="">
                          Select service group
                        </option>
                        {units.map((unit, key) => {
                          return (
                            <Fragment key={unit.id}>
                              {" "}
                              <option value={unit?.id}>
                                {unit?.service_group}
                              </option>
                            </Fragment>
                          );
                        })}
                      </select>
                      {errorMessage?.service_group_id && (
                        <div className="text-danger">
                          {" "}
                          {errorMessage?.service_group_id}{" "}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className={`${enableEndDate ? "col-2" : "col-3 "}`}>
                  <div className="row mb-2 justify-content-center align-items-center">
                    <div className="col-4">
                      <label> Service Type</label>
                    </div>
                    <div className="col-8">
                      <select
                        name="service_type_id"
                        className="form-select form-select-sm"
                        onChange={(event) => handleChangeUint(event)}
                        required
                        value={
                          data?.service_type_id ? data?.service_type_id : ""
                        }
                      >
                        <option selected disabled value="">
                          Select service type
                        </option>
                        {serviceType.map((item, i) => (
                          <option value={item?.id} key={i}>
                            {item?.service_type}
                          </option>
                        ))}
                      </select>
                      {errorMessage?.service_type_id && (
                        <div className="text-danger">
                          {" "}
                          {errorMessage?.service_type_id}{" "}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-3">
                  <div className="row mb-2 justify-content-center align-items-center">
                    <div className="col-3">
                      <label> Effactive Date</label>
                    </div>
                    <div className="col-9">
                      <DatePicker
                        required
                        format={dateFormat}
                        selected={
                          data?.effactive_date
                            ? new Date(data?.effactive_date)
                            : new Date()
                        }
                        dateFormat="dd/MM/yyyy"
                        className="form-control form-control-sm"
                        onChange={(event) =>
                          setData({ ...data, effactive_date: event })
                        }
                      />
                      {errorMessage?.effactive_date && (
                        <div className="text-danger">
                          {" "}
                          {errorMessage?.effactive_date}{" "}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-1">
                  <div className="row mb-2 justify-content-center align-items-center">
                    <div className="col-1">
                      <input
                        type="checkbox"
                        name="valid_till"
                        id=""
                        onChange={(event) => sethandleEndDate(event)}
                      />
                    </div>
                    <div className="col-9">
                      <label> Valid till</label>
                    </div>
                  </div>
                </div>
                {enableEndDate == true ? (
                  <>
                    <div className="col-3">
                      <div className="row mb-2 justify-content-center align-items-center">
                        <div className="col-3">
                          <label> End Date</label>
                        </div>
                        <div className="col-9">
                          <DatePicker
                            required
                            selected={
                              data?.end_date
                                ? new Date(data?.end_date)
                                : new Date()
                            }
                            dateFormat="dd/MM/yyyy"
                            className="form-control form-control-sm"
                            onChange={(event) =>
                              setData({ ...data, end_date: event })
                            }
                          />
                          {errorMessage?.end_date && (
                            <div className="text-danger">
                              {" "}
                              {errorMessage?.end_date}{" "}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                <div className="col-1">
                  <div className="row ">
                    <Link
                      to="/service-fee-setup"
                      className="btn btn-primary btn-sm "
                    >
                      Back
                    </Link>
                  </div>
                </div>
              </div>

              <div className="unit-manager-table invoice-item-table mb-3">
                <form onSubmit={handleSubmit}>
                  <table className="">
                    <tr>
                      <td>SL</td>
                      <td>Name</td>
                      <td>Per Unit</td>
                      <td>Unit Meaurment</td>
                      <td>Remarks</td>
                    </tr>
                    {fees?.fees?.map((fee, i) => {
                      return (
                        <tr>
                          <td>{i + 1}</td>
                          <td width="30%"> {fee?.fee_name}</td>
                          <td>
                            <input
                              type="text"
                              name="unit"
                              className="form-control form-control-sm border-none"
                              onChange={(event) =>
                                handleChangeInputUint(event, i)
                              }
                              required
                            />
                          </td>
                          <td>
                            <div className="col-8">
                              <select
                                required
                                name="unit_measurement"
                                className="form-select form-select-sm"
                                onChange={(event) =>
                                  handleChangeInputUintMesureMind(event, i)
                                }
                              >
                                <option value="">Select uint Type</option>
                                {unitType.map((item) => {
                                  return (
                                    <>
                                      <option value={item?.id}>
                                        {item?.unit_type}
                                      </option>
                                    </>
                                  );
                                })}
                              </select>
                              {errorMessage?.unit_measurement && (
                                <div className="text-danger">
                                  {" "}
                                  {errorMessage?.unit_measurement}{" "}
                                </div>
                              )}
                            </div>
                          </td>
                          <td width="10%">
                            <input
                              name="remarks"
                              className="form-control form-control-sm"
                              onChange={(event) =>
                                handleChangeInputRemarks(event, i)
                              }
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </table>
                  <div className="d-flex justify-content-end">
                    <button className="btn btn-sm btn-primary me-3 mt-3">
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddServiceFeeSetup;
