import React, { useState, useRef } from 'react'
import '../../../CSS/BuillingSetup.css'
import '../../../CSS/Table.css'
import '../../../CSS/Saas.css'
import Modal from 'react-modal'
import allCustomStyles from '../../../CSS/ReactModalStyle';
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { FaRegFilePdf } from "react-icons/fa";
import MaterialTable, { MTableToolbar } from 'material-table';

import http from '../../../http';
import { useReactToPrint } from 'react-to-print';
export default function SaasBilling() {
    const [bladeContent, setBladeContent] = useState();
    const { SetupModal } = allCustomStyles;
    const [modalIsOpen, setIsOpen] = useState(false);
    const closeModal = () => {
        setIsOpen(false);
    }
    const componentRef = useRef();
    const options = [
        { value: 'Dhaka', label: "Dhaka" },
        { value: 'Chattogram', label: "Chattogram" },
        { value: 'Cumilla', label: "Cumilla" }
    ]
    const buildings = [
        { value: 'Builidng A', label: "Building A" },
        { value: 'Building B', label: "Building B" },
        { value: 'Building C', label: "Building C" }
    ]
    const months = [
        { value: 'Jan', label: "Jan" },
        { value: 'Feb', label: "Feb" },
        { value: 'March', label: "March" }
    ]
    const years = [
        { value: '2023', label: "2023" },
        { value: '2024', label: "2024" },
        { value: '2025', label: "2025" }
    ]
    const [spinner, setSpinner] = useState(false);
    const [check, setCheck] = useState(false);
    const openModal = () => {
        setIsOpen(true)
    }
    const allChecked = () => {
        setCheck(true)
    }
    const [invoiceData, setInvoiceData] = useState({
        property_name: 'Appartment 1001',
        property_address: 'Lalbag, Dhaka',
        owner_name: 'Jakir Hossain',
        owner_address: 'Khulna',
        owner_phone: '6666-6666-6666',
        account_number: '654321',
        due_date: '07-01-2024',
        amount_paid: '$200',
        statement_date: '28-01-2024',
        service_group: [
            { id: '1', title: 'Utlitiy Service' },
            { id: '2', title: 'Managed Service' },
        ],
        service_fee: [
            { service_group_id: '1', title: 'Electricity previous', unit: '20', unit_fee: '12' },
            { service_group_id: '1', title: 'Gas', unit: '20', unit_fee: '10' },
            { service_group_id: '1', title: 'Electricity previous', unit: '20', unit_fee: '20' },
            { service_group_id: '2', title: 'Gas', unit: '20', unit_fee: '20' },
            { service_group_id: '2', title: 'Water', unit: '20', unit_fee: '10' },
        ],
    });
    const [data, setData] = useState({
        data: [
            {
                sl: 1,
                invoice: 'invoice-001',
                image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQBrDpzWSWvT8WQKdSxpdEaoev3e0uixuPvdw&usqp=CAU',
                name: 'Zabir Raihan',
                email: 'zabir@gmail.com',
                billing_date: '03-02-2024',
                amount: '7000',
                users: '10000',
            },
            {
                sl: 1,
                invoice: 'invoice-002',
                image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQBrDpzWSWvT8WQKdSxpdEaoev3e0uixuPvdw&usqp=CAU',
                name: 'Zabir Raihan',
                email: 'zabir@gmail.com',
                billing_date: '03-02-2024',
                amount: '7000',
                users: '10000',
            },
            {
                sl: 1,
                invoice: 'invoice-003',
                image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQBrDpzWSWvT8WQKdSxpdEaoev3e0uixuPvdw&usqp=CAU',
                name: 'Zabir Raihan',
                email: 'zabir@gmail.com',
                billing_date: '03-02-2024',
                amount: '7000',
                users: '10000',
            },
            {
                sl: 1,
                invoice: 'invoice-004',
                image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQBrDpzWSWvT8WQKdSxpdEaoev3e0uixuPvdw&usqp=CAU',
                name: 'Zabir Raihan',
                email: 'zabir@gmail.com',
                billing_date: '03-02-2024',
                amount: '7000',
                users: '10000',
            },
        ],
        columns: [
            {
                title: "Invoice",
                field: 'invoice',
                render: (row) => <div className=''><p className='d-flex align-items-center justify-content-around'><FaRegFilePdf className='fs-4 text-danger' /> {row?.invoice}</p></div>,
                width: "10%",
                cellStyle: {
                    textAlign: "center",
                },
            },
            {
                title: "Billing Admin",
                field: `billing_admin`,
                render: (row) =>
                    <div className='d-flex'>
                        <div className="col-lg-4">
                            <img src={row?.image} alt="" width="32" style={{ borderRadius: '50%' }} />
                        </div>
                        <div className="col-lg-8 ">
                            <div className="text-start">
                                <h5>{row?.name}</h5>
                                <p>{row?.email}</p>
                            </div>
                        </div>
                    </div>,
                width: "20%",
                cellStyle: {
                    textAlign: "center",
                },
            },
            {
                title: "Billing Date",
                field: `billing_date`,
                cellStyle: {
                    textAlign: "center",
                },
            },
            {
                title: "Amount",
                field: `amount`,
                cellStyle: {
                    textAlign: "center",
                },
            },
            {
                title: "Users",
                field: "users",
                cellStyle: {
                    textAlign: "center",
                },
            },
            {
                title: "",
                field: "download",
                render: (row) => <div><button className='btn btn-outline-dark btn-sm d-flex jusitfy-content-around align-items-center'><FaCloudDownloadAlt className='mt-1 me-1' /> Download</button></div>,
                cellStyle: {
                    textAlign: "center",
                },
            },
            {
                title: "",
                field: "icon",
                render: (row) => <div><BsThreeDotsVertical /></div>,
                cellStyle: {
                    textAlign: "center",
                },
            },
        ],
        totalsColumns: [
            { title: "Utility", field: "utility" },
            { title: "Rent", field: "flat" },
        ],

        totalsData: [{ flat: "", utility: '12000' }]
    });
    const handle = useReactToPrint({
        content: () => componentRef.current,
    });


    const handlePrint = () => {
        http.post('/invoice-pdf', invoiceData)
            .then((res) => {
                setBladeContent(res.data)
                console.log('success', res)
            })
        setTimeout(() => {
            handle();
        }, 1000)

    };


    return (
        <>
            <div id='sass-table-container'>
                <div className='page-content adjustment-type-table'>
                    <div className="custom-card p-2 d-flex justify-content-between mb-2 align-items-center">
                        <h6>Sass Billing</h6>
                    </div>
                    <div className='card'>
                        <div className='card-body'>
                            <div className="sass_billing_table">
                                <div className='col-lg-12'>
                                    <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">My Details</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Profile</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Password</button>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-lg-12 m">
                                    <div className="business-pro">
                                        <div className="saas-card">
                                            <div className="saas-card-header d-flex justify-content-between align-items-start">
                                                <div className='saas-card-header-left'>
                                                    <h1>Business Pro+ Trail</h1>
                                                    <p>Includes up to 20 users and 40gb indiviual data</p>
                                                </div>
                                                <div className="saas-card-header-right">
                                                    <h1>21 days remaining</h1>
                                                    <p></p>
                                                </div>
                                            </div>
                                            <div className="saas-card-body d-flex justify-content-between align-items-start">
                                                <div className="col-lg-5 d-flex mt-1">
                                                    <div className="col-lg-1">
                                                        <img src="https://static.vecteezy.com/system/resources/thumbnails/020/335/998/small/visa-logo-visa-icon-free-free-vector.jpg" alt="" width="40px" />
                                                    </div>
                                                    <div className="col-lg-11 ms-2">
                                                        <h6 style={{ fontSize: "13px" }}>Visa ending in 1234</h6>
                                                        <h6 style={{ fontSize: "13px", paddingBottom: '6px' }}>Expire 06/2024</h6>
                                                        <p style={{ fontSize: "10px" }}>billing@apratpro.com</p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 text-end">
                                                    <h1 style={{ fontSize: '14px ' }}>Edit</h1>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                    <MaterialTable
                                        columns={data?.columns}
                                        data={data?.data}
                                        isLoading={spinner}
                                        footerData={data?.totalsData}
                                        options={{
                                            search: false,
                                            selection: true,
                                            showTitle: false,
                                            searchFieldAlignment: "left",
                                            pageSize: 10,
                                            emptyRowsWhenPaging: false,
                                            pageSizeOptions: [5, 10, 20, 50, 100],
                                            rowStyle: {
                                                fontSize: 14,
                                                fontWeight: 400,
                                            }
                                        }}
                                        components={{
                                            Toolbar: (props) => (
                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <div className='mt-3'>
                                                        <h6>Billing History</h6>
                                                        <p>Download previous invoices</p>
                                                    </div>
                                                    <div className="me-1 mt-4 mb-0">
                                                        <button className='btn btn-outline-dark btn-sm d-flex jusitfy-content-around align-items-center'><FaCloudDownloadAlt className='mt-1 me-1' />All Download</button>
                                                    </div>
                                                </div>
                                            ),
                                        }}
                                    // actions={[
                                    //     {
                                    //         tooltip: 'Print Selected PDF',
                                    //         icon: PictureAsPdfSharpIcon,
                                    //         onClick: handlePrint,
                                    //     }
                                    // ]}

                                    />

                                </div>
                                <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                                    <h2>Hello</h2>
                                </div>
                                <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                    <h2>Bye</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div>
            <div ref={componentRef} className="content-to-print displayNone" dangerouslySetInnerHTML={{ __html: bladeContent }} />
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={SetupModal}
                contentLabel="Example Modal"
            >
                <div className="product_modal">
                    <div className="page-content">
                        <div className=" patients-head ">
                            <h5 className="fw-normal custom_py-3 px-2  text-start mb-2 card-name">
                                <span style={{ cursor: "pointer", fontSize: "16px" }} onClick={closeModal} className='float-end'><i className="fal fa-times"></i></span>
                            </h5>
                        </div>

                        <div className="p-3 pt-0">
                            <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Email</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Sms</button>
                                </li>
                            </ul>
                            <div class="tab-content" id="pills-tabContent">
                                <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabindex="0">
                                    <form onSubmit="">
                                        <textarea name="" id="" cols="30" rows="4" className='form-control'> </textarea>
                                        <button className="btn mt-2 btn-sm btn-primary float-end text-uppercase" type="submit"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-save mb-1"><path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path><polyline points="17 21 17 13 7 13 7 21"></polyline><polyline points="7 3 7 8 15 8"></polyline></svg> Send</button>
                                    </form>
                                </div>
                                <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabindex="0">
                                    <form onSubmit="">
                                        <textarea name="" id="" cols="30" rows="4" className='form-control'> </textarea>
                                        <button className="btn mt-2 btn-sm btn-primary float-end text-uppercase" type="submit"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-save mb-1"><path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path><polyline points="17 21 17 13 7 13 7 21"></polyline><polyline points="7 3 7 8 15 8"></polyline></svg> Send</button>
                                    </form>
                                </div>
                            </div>




                        </div>

                    </div>
                </div>
            </Modal >
            {/* <Invoice /> */}
        </>
    )
}


// import React from 'react'
// import MaterialTable from 'material-table'
// import { useEffect } from 'react';
// import { useState } from 'react';
// import './Billing.css';
// import BillEntry from './BillEntry';

// export default function BillingManagement() {
//     const [isModalOpen, setisModalOpen] = useState(false)

//     const openModal = () => {
//         setisModalOpen(true)
//     }
//     return (
//         <div className='page-content adjustment-type-table'>
//             <div className="custom-card p-2 d-flex justify-content-between mb-2 align-items-center">
//                 <h6>Billing Managment</h6>
//                 <div>
//                     <button style={{ marginTop: "1px" }} onClick={openModal} className='btn btn-sm btn-primary float-end'>Bill Entry</button>
//                 </div>
//             </div>

//             {/* <MaterialTable
//                 columns={{}}
//                 data={[]}
//                 isLoading={false}
//                 options={{
//                     search: true,
//                     showTitle: false,
//                     searchFieldAlignment: "left",
//                     pageSize: 10,
//                     emptyRowsWhenPaging: false,
//                     pageSizeOptions: [5, 10, 20, 50, 100],
//                 }}
//             /> */}

//             {isModalOpen && <BillEntry isModalOpen={isModalOpen} setisModalOpen={setisModalOpen} />}


//         </div>
