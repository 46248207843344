import React, { useEffect } from 'react'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useState } from 'react';
import '../../../CSS/Invoice.css'
import http from '../../../http';
import Swal from 'sweetalert2';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate, useParams } from 'react-router-dom';
export default function EditMovement() {
    const designation = [
        { title: 'Manager' },
        { title: 'Caretaker' },
        { title: 'Guard' },
    ]
    const profession = [
        { title: 'Business' },
        { title: 'Self Employed' },
        { title: 'Doctor' },
        { title: 'Engineer' },
    ]
    // project info 
    const [personalInfo, setPersonaInfo] = useState({
        name: '',
        email: '',
        phone_1: '',
        phone_2: '',
        mobile_1: '',
        mobile_2: '',
        designation_id: '',
        father_name: '',
        mother_name: '',
        date_of_birth: new Date(),
        address_line_1: '',
        address_line_2: '',
    })
    const handlePersonalInfoChange = (event) => {
        const { name, value } = event.target;
        setPersonaInfo({ ...personalInfo, [name]: value });
    }
    const { id } = useParams();
    const navigate = useNavigate();
    useEffect(() => {
        http.get(`employees/${id}`)
            .then((res) => {
                setPersonaInfo(res.data)
            })
    }, [id])
    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('name', personalInfo.name)
        formData.append('email', personalInfo.email)
        formData.append('phone_1', personalInfo.phone_1)
        formData.append('phone_2', personalInfo.phone_2)
        formData.append('mobile_1', personalInfo.mobile_1)
        formData.append('mobile_2', personalInfo.mobile_2)
        formData.append('designation_id', personalInfo.designation_id)
        formData.append('father_name', personalInfo.father_name)
        formData.append('mother_name', personalInfo.mother_name)
        formData.append('date_of_birth', personalInfo.date_of_birth)
        formData.append('address_line_1', personalInfo.address_line_1)
        formData.append('address_line_2', personalInfo.address_line_2)
        formData.append('picture', personalInfo.picture)

        http.post(`update-employee/${id}`, formData)
            .then((res) => {
                if (res.status === 200) {
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Employee Updated Successfully',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    document.getElementById('picture').value = ''
                    setPersonaInfo({
                        name: '',
                        email: '',
                        phone_1: '',
                        phone_2: '',
                        mobile_1: '',
                        mobile_2: '',
                        designation_id: '',
                        father_name: '',
                        mother_name: '',
                        date_of_birth: new Date(),
                        address_line_1: '',
                        address_line_2: '',
                    })
                    navigate('/employees')
                }
            })
    }
    console.log(personalInfo, 'personalInfo')
    return (
        <div className="page-content bg-white">
            <div className="property-register ms-1">
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <h3 className="text-center my-3">Employee Information</h3>
                        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Personal Info</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button disabled className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">My Wallet</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button disabled className="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Family Member</button>
                            </li>
                        </ul>
                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="row mb-2">
                                            <div className="col-3">
                                                <label>Full Name <span className="text-danger">*</span></label>
                                            </div>
                                            <div className="col-9">
                                                <input required type="text" onChange={handlePersonalInfoChange} value={personalInfo.name} name='name' className="form-control form-control-sm" />
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-3">
                                                <label>Designation <span className="text-danger">*</span></label>
                                            </div>
                                            <div className="col-9">
                                                <Autocomplete
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    options={designation}
                                                    getOptionLabel={(option) => option.title}
                                                    sx={{ width: '100%' }}
                                                    size='small'
                                                    onChange={(event, value) => {
                                                        setPersonaInfo({ ...personalInfo, designation_id: value.title })
                                                    }}
                                                    isOptionEqualToValue={(option, value) => option.title === value.title}
                                                    renderInput={(params) => <TextField {...params} label={personalInfo.designation_id} />}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-3">
                                                <label> Father Name <span className="text-danger">*</span></label>
                                            </div>
                                            <div className="col-9">
                                                <input required type="text" onChange={handlePersonalInfoChange} value={personalInfo?.father_name} name='father_name' className="form-control form-control-sm" />
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-3">
                                                <label> Phone 1</label>
                                            </div>
                                            <div className="col-9">
                                                <input type="text" onChange={handlePersonalInfoChange} value={personalInfo?.phone_1} name='phone_1' className="form-control form-control-sm" />
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-3">
                                                <label> Mobile 1 <span className="text-danger">*</span></label>
                                            </div>
                                            <div className="col-9">
                                                <input required type="text" onChange={handlePersonalInfoChange} value={personalInfo?.mobile_1} name='mobile_1' className="form-control form-control-sm" />
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-3">
                                                <label> Email</label>
                                            </div>
                                            <div className="col-9">
                                                <input type="email" onChange={handlePersonalInfoChange} value={personalInfo?.email} name='email' className="form-control form-control-sm" />
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-3">
                                                <label htmlFor="address1">Address 1 <span className="text-danger">*</span></label>
                                            </div>
                                            <div className="col-9">
                                                <textarea required name="address_line_1" onChange={handlePersonalInfoChange} value={personalInfo?.address_line_1} className="form-control form-control-sm" id="" cols="30" rows="3"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row mb-2">
                                            <div className="col-3">
                                                <label className=''>Date of Birth <span className="text-danger">*</span></label>
                                            </div>
                                            <div className="col-9">
                                                <DatePicker
                                                    selected={personalInfo?.date_of_birth ? new Date(personalInfo?.date_of_birth) : new Date()}
                                                    dateFormat="dd/MM/yyyy"
                                                    className="form-control form-control-sm"
                                                    onChange={(date) => setPersonaInfo({ ...personalInfo, date_of_birth: date })} />

                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-3">
                                                <label> Mother's Name</label>
                                            </div>
                                            <div className="col-9">
                                                <input type="text" onChange={handlePersonalInfoChange} value={personalInfo.mother_name} name='mother_name' className="form-control form-control-sm" />
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-3">
                                                <label> Phone 2</label>
                                            </div>
                                            <div className="col-9">
                                                <input type="text" onChange={handlePersonalInfoChange} value={personalInfo.phone_2} name='phone_2' className="form-control form-control-sm" />
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-3">
                                                <label> Mobile 2</label>
                                            </div>
                                            <div className="col-9">
                                                <input type="text" onChange={handlePersonalInfoChange} value={personalInfo.mobile_2} name='mobile_2' className="form-control form-control-sm" />
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-3">
                                                <label> Picture</label>
                                            </div>
                                            <div className="col-9">
                                                <input type="file" id='picture' onChange={(e) => setPersonaInfo({ ...personalInfo, picture: e.target.files[0] })} accept='image/*' name='picture' className="form-control form-control-sm" />
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-3">
                                                <label htmlFor="address1">Address 2</label>
                                            </div>
                                            <div className="col-9">
                                                <textarea name="address_line_2" onChange={handlePersonalInfoChange} value={personalInfo.address_line_2} className="form-control form-control-sm" id="" cols="30" rows="3"></textarea>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="d-flex justify-content-end">
                                                <button type='submit' className="btn btn-sm btn-primary me-3 mt-3">Update</button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">

                            </div>
                            <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">

                            </div>
                        </div>

                    </div>
                </form>
            </div>
        </div>
    )
}
