import React from "react";
import MaterialTable from "material-table";
import { useEffect } from "react";
import { useState } from "react";
import Modal from "react-modal";
import Swal from "sweetalert2";
import http from "../../../http";
import moment from "moment";
import allCustomStyles from "../../../CSS/ReactModalStyle";
import Select from "react-select";
import "./Billing.css";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import CircularProgress from "@mui/material/CircularProgress";
import Setting from "../../Setting/Setting";
import { FiRefreshCcw } from "react-icons/fi";

import noImage from "../../../front_assets/images/no-image.png";

export default function BillEntry({
  projectWiseFloor,
  isModalOpen,
  setisModalOpen,
  setupdateBillEntryData,
  billEntryDataForEdit,
  setbillEntryDataForEdit,
  permisionFroUpdate,
  setpermisionFroUpdate,
  monthAndYear,
}) {
  const { BillingManagementModal, colourStyles } = allCustomStyles;
  const { currencyFormat, dateFormat } = Setting;

  const [projectWiseFloorVlaue, setprojectWiseFloorVlaue] = useState();
  const [buildingDetails, setbuildingDetails] = useState([]);
  const [buildingValue, setbuildingValue] = useState(null);

  const [floorDetails, setfloorDetails] = useState([]);
  const [floorDetailsforSearch, setfloorDetailsforSearch] = useState([]);
  const [building_id, setbuilding_id] = useState();
  const [floor_id, setfloor_id] = useState();
  const [ownerDetails, setownerDetails] = useState();

  const [feeSetup, setfeeSetup] = useState([]);
  const [loadinFeeSetup, setloadinFeeSetup] = useState(false);

  const [buildingInfoIndex, setbuildingInfoIndex] = useState(null);
  const [priviousDate, setpriviousDate] = useState({
    billing_month: dayjs().format(
      `${`${monthAndYear?.year}-${monthAndYear?.month}-01`}`
    ),
    billing_date: dayjs(),
    due_date: dayjs().add(5, "day"),
  });

  const closeModal = () => {
    setisModalOpen(false);
    setupdateBillEntryData(Math.random());
    setbillEntryDataForEdit();
    makeSateEmpty();
  };

  const makeSateEmpty = () => {
    setfeeSetup([]);
    settotal(0);
  };

  const onChangeValue = (e, i, j, v) => {
    const { name, value } = e.target;
    const existingMain = [...feeSetup];
    existingMain[i].fee_setup_deatils[j][name] = Number(value);
    if (name !== "qty") {
      existingMain[i].fee_setup_deatils[j]["qty"] = Number(
        existingMain[i].fee_setup_deatils[j]["to"] -
        existingMain[i].fee_setup_deatils[j]["from"]
      );
    }
    existingMain[i].fee_setup_deatils[j]["subTotal"] = Number(
      existingMain[i].fee_setup_deatils[j]["qty"] * Number(v?.unit)
    );
    setfeeSetup(existingMain);
    setvalueUpdate(Math.random());
  };

  useEffect(() => {
    let sub = false;
    if (
      billEntryDataForEdit !== null &&
      billEntryDataForEdit !== undefined &&
      !sub
    ) {
      setprojectWiseFloorVlaue(billEntryDataForEdit?.project);

      setbuildingValue(billEntryDataForEdit?.building);

      const forBuilding = projectWiseFloor?.find(
        (v1) => Number(v1.id) === Number(billEntryDataForEdit?.project?.id)
      );
      setbuildingDetails(forBuilding?.building);

      const forUnit = forBuilding?.building?.find(
        (v) =>
          Number(v.building_id) ===
          Number(billEntryDataForEdit?.building?.building_id)
      );

      setfloorDetails(forUnit?.units);
      setfloorDetailsforSearch(forUnit?.units);

      // const index = d1?.building.findIndex(
      //   (item) =>
      //     Number(item?.building_id) ===
      //     Number(billEntryDataForEdit?.building?.building_id)
      // );
      // setbuildingInfoIndex(index);

      // setbuilding_id(billEntryDataForEdit?.building?.building_id);
      // setfloorDetails(d1?.building[index]?.units);
      // setfloorDetailsforSearch(d1?.building[index]?.units);
      if (billEntryDataForEdit?.id === null) {
        http
          .get(
            `seache-floor/${billEntryDataForEdit?.project?.id}/${billEntryDataForEdit?.building?.building_id}/${billEntryDataForEdit?.unit_name}`
          )
          .then((res) => forServiceandOwnerDetails(res.data?.data[0]));
      } else {
        forServiceandOwnerDetails({
          id: billEntryDataForEdit?.floor_details_id,
          owner: billEntryDataForEdit?.owner,
          tenant: billEntryDataForEdit?.tenant,
        });
      }
    }

    return () => {
      sub = true;
    };
  }, [billEntryDataForEdit]);

  const forServiceandOwnerDetails = (
    item,
    month = priviousDate?.billing_month,
    year = priviousDate?.billing_month
  ) => {
    makeSateEmpty();
    setfloor_id(item?.id);
    setloadinFeeSetup(true);

    setownerDetails(item);

    // const locked = data?.filter(v => Number(v?.floor_details_id) === Number(item?.id));
    // if (locked[0]?.status === "Locked") {
    //   setpermisionFroUpdate(false)

    // }
    // if (locked?.length === 0) {
    //   setpermisionFroUpdate(true)
    // }

    checked_permission_for_floor(
      item?.id,
      dayjs(month).format("MM"),
      dayjs(year).format("YYYY")
    );

    http
      .get(
        `appoitment-wise-bill-map/${item?.id}/${dayjs(month).format(
          "MM"
        )}/${dayjs(year).format("YYYY")}`
      )
      .then((res) => {
        setloadinFeeSetup(false);
        const v = res?.data?.data?.map((v) => {
          const changes = v.fee_setup_deatils?.map((i) => {
            const fromV = i.from > 0 ? i.from : 0;
            const toV = i.to > 0 ? i.to : 0;
            const qtyV = i.qty > 0 ? i.qty : 0;

            return {
              ...i,
              from: fromV,
              to: toV,
              qty: qtyV,
              subTotal: qtyV * i.unit,
            };
          });

          return { ...v, fee_setup_deatils: changes };
        });

        if (v[0]?.billing_month !== undefined) {
          setpriviousDate({
            billing_month: v[0]?.billing_month,
            billing_date: v[0]?.billing_date,
            due_date: v[0]?.due_date,
          });
        }

        setfeeSetup(v);
        setvalueUpdate(Math.random());
      })
      .catch((err) => {
        setloadinFeeSetup(false);
      });
  };

  const checked_permission_for_floor = (floorId, month, year) => {
    http
      .get(`checked-bill-entry-status/${floorId}/${month}/${year}`)
      .then((res) => {
        if (res.data?.data?.status === "Locked") {
          setpermisionFroUpdate(false);
        } else {
          setpermisionFroUpdate(true);
        }
      })
      .catch((err) => console.log(err));
  };

  const removeFlatAfterSubmit = () => {
    const data = floorDetails.filter((item) => item?.id !== floor_id);
    setfloorDetails(data);
    forServiceandOwnerDetails(data[0]);
  };

  const [total, settotal] = useState(0);
  const [valueUpdate, setvalueUpdate] = useState();

  useEffect(() => {
    let sub = false;
    if (!sub && feeSetup?.length > 0) {
      const data = feeSetup.map((item) => {
        return {
          ...item,
          total: item.fee_setup_deatils?.reduce(
            (acc, detail) => acc + detail.subTotal,
            0
          ),
        };
      });
      setfeeSetup(data);
      settotal(data?.reduce((acc, detail) => acc + detail.total, 0));
    }
    return () => {
      sub = true;
    };
  }, [valueUpdate]);

  const [submitBtnLoding, setsubmitBtnLoding] = useState(false);

  const dataSubmit = () => {
    if (total <= 0) {
      return Swal.fire({
        position: "top-center",
        icon: "warning",
        title: "Warning !",
        text: "Please add bill in this month",
      });
    } else {
      setsubmitBtnLoding(true);
      const data = {
        feeSetup,
        billing_date: dayjs(priviousDate?.billing_date).format("YYYY-MM-DD"),
        billing_month: dayjs(priviousDate?.billing_month).format("YYYY-MM-DD"),
        due_date: dayjs(priviousDate?.due_date).format("YYYY-MM-DD"),
        building_info_id: buildingValue?.id,
        floor_details_id: floor_id,
        grand_total: total,
      };

      http
        .post("bill-entry", data)
        .then((res) => {
          setsubmitBtnLoding(false);
          Swal.fire({
            position: "top-center",
            icon: "success",
            title: "Success !",
            text: res.data.message,
          });
          removeFlatAfterSubmit();
          // closeModal()
        })
        .catch((err) => {
          setsubmitBtnLoding(false);
          Swal.fire({
            position: "top-center",
            icon: "error",
            title: "Error !",
            text: err.response.data.message,
          });
        });
    }
  };

  const handleKeyDown = (event) => {
    console.log("event", event);
    if (event.key === "ArrowUp") {
      console.log("ArrowUp");
    } else if (event.key === "ArrowDown") {
      console.log("ArrowDown");
    }
  };
  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={isModalOpen}
      style={BillingManagementModal}
      contentLabel="Example Modal"
    >
      <div className="product_modal">
        <div className="page-content">
          <div className=" patients-head ">
            <h6
              className=""
              style={{
                borderBottom: "1px solid #bfc1c3",
                marginBottom: "8px",
                paddingBottom: "8px",
              }}
            >
              Bill Entry
              <span
                style={{ cursor: "pointer", fontSize: "13px" }}
                onClick={closeModal}
                className="float-end"
              >
                <i className="fal fa-times"></i>
              </span>
            </h6>
          </div>

          <div className="row">
            <div className="col-3 left_panel table-scroll">
              <div className="building">
                <label className="mb-2">Properties</label>
                <Select
                  styles={colourStyles}
                  className="basic-single mb-2"
                  value={projectWiseFloorVlaue}
                  options={projectWiseFloor}
                  getOptionLabel={(option) => option.property_name}
                  onChange={(v, i) => {
                    setprojectWiseFloorVlaue(v);
                    setbuildingDetails(v?.building);
                    setbuildingValue(null);
                    setfloorDetails([]);
                  }}
                />
              </div>
              <div className="building">
                <label className="mb-2">Building</label>
                <Select
                  styles={colourStyles}
                  className="basic-single mb-2"
                  value={buildingValue}
                  options={buildingDetails}
                  getOptionLabel={(option) => option.title}
                  onChange={(v, i) => {
                    setbuildingValue(v);
                    setfloorDetails(v?.units);
                    setfloorDetailsforSearch(v?.units);
                  }}

                />
              </div>
              <div className="row my-2 ">
                <div className="col-4 mt-1 ">
                  <p>
                    Flat List
                    <FiRefreshCcw
                      onClick={() => setfloorDetails(floorDetailsforSearch)}
                      style={{ cursor: "pointer" }}
                    />
                  </p>
                </div>
                <div className="col-8 d-flex">
                  <input
                    onChange={(e) => {
                      const { value } = e.target;
                      if (value.length > 0) {
                        var data = floorDetailsforSearch.filter((item) =>
                          item.unit_name
                            .toLowerCase()
                            .startsWith(value.toLowerCase())
                        );
                      } else {
                        var data = floorDetailsforSearch;
                      }
                      setfloorDetails(data);
                    }}
                    type="text"
                    placeholder="Search Flat"
                    className="form-control form-control-sm"
                  />
                </div>
              </div>

              {floorDetails?.length > 0 ? (
                floorDetails?.map((item, i) => {
                  return (
                    <div
                      key={i}
                      onClick={() => {
                        forServiceandOwnerDetails(item);
                      }}
                      onKeyDown={handleKeyDown}
                      className={`flat ${Number(ownerDetails?.id) === Number(item?.id) &&
                        "active"
                        }`}
                    >
                      <label>{item?.unit_name}</label>
                    </div>
                  );
                })
              ) : (
                <div className="flat" style={{ color: "#E74C3C" }}>
                  <label>Records are not available</label>
                </div>
              )}
            </div>
            <div className="col-9">
              <div className="row">
                <div className="col-8">
                  <div className="owner_info d-flex">
                    {ownerDetails?.id === undefined ? (
                      <img
                        style={{ width: "50px", height: "50px" }}
                        src={noImage}
                        alt="User_Image"
                      />
                    ) : (
                      <img
                        style={{ width: "50px", height: "50px" }}
                        src={
                          ownerDetails?.tenant === null
                            ? ownerDetails?.owner?.picture &&
                              ownerDetails?.owner?.picture != "null"
                              ? `${global.img_Url}/images/${ownerDetails?.owner?.picture}`
                              : noImage
                            : ownerDetails?.tenant?.picture &&
                              ownerDetails?.tenant?.picture != "null"
                              ? `${global.img_Url}/images/${ownerDetails?.tenant?.picture}`
                              : noImage
                        }
                        alt="User_Image"
                      />
                    )}

                    <div className="info d-flex mt-1">
                      <div className="ms-2 me-3">
                        <p>
                          Name :
                          {ownerDetails?.tenant === null
                            ? ownerDetails?.owner?.name
                            : ownerDetails?.tenant?.name}
                        </p>
                        <p>
                          Address :
                          {ownerDetails?.tenant === null
                            ? ownerDetails?.owner?.address_line_1
                            : ownerDetails?.tenant?.address_line_1}
                        </p>
                        {/* <p>Ownership : {ownerDetails?.owner?.ownership_type_id}</p> */}
                      </div>
                      <div className="">
                        <p>
                          Phone :
                          {ownerDetails?.tenant === null
                            ? ownerDetails?.owner?.mobile_1
                            : ownerDetails?.tenant?.mobile_1}
                        </p>
                        <p>
                          Email :
                          {ownerDetails?.tenant === null
                            ? ownerDetails?.owner?.email
                            : ownerDetails?.tenant?.email}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-4 owner_info" align="center">
                  <p
                    style={{
                      fontSize: "18px",
                      marginTop: "5%",
                      color: "green",
                    }}
                  >
                    Total Bill : {currencyFormat(total)}
                  </p>
                </div>
              </div>

              <div className="d-flex mt-3 mb-2">
                <div className="me-2">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label={"Billing Month/Y"}
                      views={["month", "year"]}
                      value={
                        priviousDate.billing_month === null
                          ? dayjs().format(
                            `${`${monthAndYear?.year}-${monthAndYear?.month}-01`}`
                          )
                          : dayjs(priviousDate.billing_month)
                      }
                      // value={date?.billing_month}
                      onChange={(v) => {
                        setloadinFeeSetup(true);
                        setpriviousDate({
                          ...priviousDate,
                          billing_month: moment(v?.$d).format("YYYY-MM-DD"),
                        });
                        const month = v?.$d;
                        http
                          .get(
                            `appoitment-wise-bill-map/${floor_id}/${dayjs(
                              month
                            ).format("MM")}/${dayjs(month).format("YYYY")}`
                          )
                          .then((res) => {
                            setloadinFeeSetup(false);
                            const v = res?.data?.data?.map((v) => {
                              const changes = v.fee_setup_deatils?.map((i) => {
                                const fromV = i.from > 0 ? i.from : 0;
                                const toV = i.to > 0 ? i.to : 0;
                                const qtyV = i.qty > 0 ? i.qty : 0;

                                return {
                                  ...i,
                                  from: fromV,
                                  to: toV,
                                  qty: qtyV,
                                  subTotal: qtyV * i.unit,
                                };
                              });

                              return { ...v, fee_setup_deatils: changes };
                            });

                            if (v[0]?.billing_month !== undefined) {
                              setpriviousDate({
                                billing_month: v[0]?.billing_month,
                                billing_date: v[0]?.billing_date,
                                due_date: v[0]?.due_date,
                              });
                            }

                            setfeeSetup(v);
                            setvalueUpdate(Math.random());
                          })
                          .catch((err) => {
                            setloadinFeeSetup(false);
                          });
                        checked_permission_for_floor(
                          floor_id,
                          dayjs(month).format("MM"),
                          dayjs(month).format("YYYY")
                        );
                        // forServiceandOwnerDetails({ id: floor_id, owner: ownerDetails });
                      }}
                      slotProps={{ textField: { size: "small" } }}
                    />
                  </LocalizationProvider>
                </div>
                <div className="me-2">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label={"Billing date"}
                      format={dateFormat}
                      value={
                        priviousDate.billing_date === null
                          ? dayjs()
                          : dayjs(priviousDate.billing_date)
                      }
                      onChange={(v) => {
                        setpriviousDate({
                          ...priviousDate,
                          billing_date: moment(v?.$d).format("YYYY-MM-DD"),
                        });
                      }}
                      slotProps={{ textField: { size: "small" } }}
                    />
                  </LocalizationProvider>
                </div>
                <div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label={"Due date"}
                      format={dateFormat}
                      value={
                        priviousDate.due_date === null
                          ? dayjs().add(5, "day")
                          : dayjs(priviousDate.due_date)
                      }
                      onChange={(v) => {
                        setpriviousDate({
                          ...priviousDate,
                          due_date: moment(v?.$d).format("YYYY-MM-DD"),
                        });
                      }}
                      slotProps={{ textField: { size: "small" } }}
                    />
                  </LocalizationProvider>
                </div>
              </div>

              <div className="row billing_right_content_height table-scroll">
                {loadinFeeSetup ? (
                  <span
                    style={{
                      marginTop: "15%",
                      marginLeft: "35%",
                      color: "green",
                    }}
                  >
                    <CircularProgress />
                  </span>
                ) : feeSetup?.length > 0 ? (
                  feeSetup?.map((item, i) => {
                    return (
                      <div key={i} className="col-12">
                        <div className="unit-manager-table bill-entry-table">
                          <span
                            className="mb-1"
                            style={{
                              border: "none",
                              padding: "1px 10px",
                              borderRadius: 3,
                              display: "inline-block",
                              background: "#035E7B",
                              color: "white",
                            }}
                          >
                            {item?.service?.service_group}
                          </span>
                          <div className="billing_table_height table-scroll">
                            <table>
                              <thead>
                                <tr key={i}>
                                  <td>Code</td>
                                  <td>Name</td>
                                  <td>Per Unit</td>
                                  <td>From</td>
                                  <td>To</td>
                                  <td>Qty</td>
                                  <td>Unit</td>
                                  <td>Sub total</td>
                                </tr>
                              </thead>
                              <tbody>
                                {item?.fee_setup_deatils?.length > 0 ? (
                                  item?.fee_setup_deatils?.map((v, j) => {
                                    return (
                                      <>
                                        <tr key={j}>
                                          <td width={"5%"}>{v?.code}</td>
                                          <td width={"25%"}>
                                            {v?.feesid?.fee_name}
                                          </td>
                                          <td width={"10%"}>{v?.unit}</td>
                                          <td width={"12%"}>
                                            <input
                                              type="number"
                                              name="from"
                                              onChange={(e) =>
                                                onChangeValue(e, i, j, v)
                                              }
                                              value={v?.from}
                                              className="bill_entry_input sm form-control"
                                              style={{ padding: "0" }}
                                            />
                                          </td>
                                          <td width={"12%"}>
                                            <input
                                              type="number"
                                              name="to"
                                              onChange={(e) =>
                                                onChangeValue(e, i, j, v)
                                              }
                                              value={v?.to}
                                              className="bill_entry_input sm form-control"
                                              style={{ padding: "0" }}
                                            />
                                          </td>
                                          <td width={"10%"}>
                                            <input
                                              type="number"
                                              name="qty"
                                              onChange={(e) =>
                                                onChangeValue(e, i, j, v)
                                              }
                                              value={v?.qty}
                                              className="bill_entry_input sm form-control"
                                              style={{ padding: "0" }}
                                            />
                                          </td>
                                          <td width={"10%"}>
                                            {v?.unitsid?.unit_type}
                                          </td>
                                          <td align="right" width={"16%"}>
                                            {v?.subTotal?.toFixed(2)}
                                          </td>
                                        </tr>
                                      </>
                                    );
                                  })
                                ) : (
                                  <tr>
                                    <td style={{ color: "red" }} colSpan={8}>
                                      Records are not available
                                    </td>
                                  </tr>
                                )}
                                <tr>
                                  <td colSpan={7}>
                                    <b>Total</b>
                                  </td>
                                  <td
                                    align="right"
                                    style={{ marginRight: "15px" }}
                                  >
                                    <b>
                                      {currencyFormat(item?.total).replace(
                                        /\.00$/,
                                        ""
                                      )}
                                    </b>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <span
                    style={{
                      marginTop: "15%",
                      marginLeft: "35%",
                      color: "red",
                    }}
                  >
                    Records are not available
                  </span>
                )}
              </div>

              {permisionFroUpdate && (
                <div className="d-flex justify-content-end">
                  {submitBtnLoding ? (
                    <button className="btn btn-sm btn-primary">
                      saving .....
                    </button>
                  ) : (
                    <>
                      {/* <button
                          onClick={() => setfloorDetails(floorDetailsforSearch)}
                          className="btn btn-sm btn-primary me-2"
                        >
                          Refresh Flat List
                        </button> */}
                      <button
                        onClick={dataSubmit}
                        className="btn btn-sm btn-primary"
                      >
                        Submit
                      </button>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>

          {/* <div className=" p-3">
                            <form onSubmit={() => { }}>
                                <input onChange={() => { }} value="" name="service_group" type="text" className="form-control form-control-sm my-2" required placeholder="Title" />
                                <button className="btn mt-2 btn-sm btn-primary float-end text-uppercase" type="submit"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-save mb-1"><path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path><polyline points="17 21 17 13 7 13 7 21"></polyline><polyline points="7 3 7 8 15 8"></polyline></svg> Save</button>
                            </form>
                        </div> */}
        </div>
      </div>
    </Modal>
  );
}
