import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import Modal from "react-modal";
import Swal from "sweetalert2";
import http from "../../../http";
import moment from "moment";
import allCustomStyles from "../../../CSS/ReactModalStyle";
import Select from "react-select";
import "./Billing.css";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { Col, Row } from "react-bootstrap";
import cashIcon from "../../../front_assets/icons/cash.png";
import creditDebitCard from "../../../front_assets/icons/credit-debit-card.png";
import digitalPayment from "../../../front_assets/icons/digital-payment.png";
import ePayment from "../../../front_assets/icons/e-payment.png";
import eWallet from "../../../front_assets/icons/e-wallet.png";
import CircularProgress from "@mui/material/CircularProgress";
import { CiEdit } from "react-icons/ci";
import { FaCheckCircle, FaHistory } from "react-icons/fa";
import PaymentHistoryModal from "./PaymentHistoryModal";
import Setting from "../../Setting/Setting";
import PaymentSlip from "./PaymentSlip";
import { useReactToPrint } from "react-to-print";
import noImage from "../../../front_assets/images/no-image.png";

export default function PaymentModal({
  projectWiseFloor,
  ispaymentModal,
  setispaymentModal,
  setupdateBillEntryData,
  billEntryDataForEdit,
  setbillEntryDataForEdit,
  paymentHistoryModal,
  setpaymentHistoryModal,
  monthAndYear,
  settableDataUpdate,
}) {
  const { BillingManagementModal, colourStyles } = allCustomStyles;
  const { currencyFormat, dateFormat } = Setting;

  const [projectWiseFloorVlaue, setprojectWiseFloorVlaue] = useState();
  const [buildingDetails, setbuildingDetails] = useState([]);
  const [buildingValue, setbuildingValue] = useState(null);

  const [floorDetails, setfloorDetails] = useState([]);
  const [floorDetailsforSearch, setfloorDetailsforSearch] = useState([]);
  const [building_id, setbuilding_id] = useState();
  const [floor_id, setfloor_id] = useState();
  const [ownerDetails, setownerDetails] = useState();

  const [buildingInfoIndex, setbuildingInfoIndex] = useState(null);
  const [priviousDate, setpriviousDate] = useState({
    billing_month: dayjs().format(
      `${`${monthAndYear?.year}-${monthAndYear?.month}-01`}`
    ),
    billing_date: dayjs(),
    due_date: dayjs().add(5, "day"),
  });

  const closeModal = () => {
    setispaymentModal(false);
    setupdateBillEntryData(Math.random());
    setbillEntryDataForEdit();
    settableDataUpdate(Math.random());
  };

  useEffect(() => {
    let sub = false;
    if (billEntryDataForEdit !== null && billEntryDataForEdit !== undefined && !sub) {

      setprojectWiseFloorVlaue(billEntryDataForEdit?.project);

      setbuildingValue(billEntryDataForEdit?.building);

      const forBuilding = projectWiseFloor?.find(
        (v1) => Number(v1.id) === Number(billEntryDataForEdit?.project?.id)
      );
      setbuildingDetails(forBuilding?.building);

      const forUnit = forBuilding?.building?.find(
        (v) =>
          Number(v.building_id) ===
          Number(billEntryDataForEdit?.building?.building_id)
      );

      setfloorDetails(forUnit?.units);
      setfloorDetailsforSearch(forUnit?.units);

      forServiceandOwnerDetails({
        id: billEntryDataForEdit?.floor_details_id,
        owner: billEntryDataForEdit?.owner,
        tenant: billEntryDataForEdit?.tenant,
      });
    }

    return () => {
      sub = true;
    };
  }, [billEntryDataForEdit]);

  const [amount, setamount] = useState({
    received_amount: 0,
    grand_total: 0,
    due: 0,
  });
  const [bill_entries_id, setbill_entries_id] = useState();
  const [isLoading, setisLoading] = useState(false);
  const [invoice, setinvoice] = useState();

  const [balance, setbalance] = useState(0);

  const forServiceandOwnerDetails = (item) => {
    setSelectedPayment("Cash");
    setisLoading(true);
    setfloor_id(item?.id);
    setownerDetails(item);

    const id = item?.tenant === null ? item?.owner?.id : item?.tenant?.id;
    http.get(`e-wallet/${id}`).then((res) => {
      res?.data.data !== null
        ? setbalance(res?.data.data.balance)
        : setbalance(0);
    });

    checkBillEntryStatus(item?.id, monthAndYear?.month, monthAndYear?.year);
  };

  const checkBillEntryStatus = (id, month, year) => {
    http
      .get(`checked-bill-entry-status/${id}/${month}/${year}`)
      .then((res) => {
        if (res.data?.data?.status === "Locked") {
          setbill_entries_id(res.data.data.id);
          setinvoice(res.data.data.invoice);
          setamount({
            received_amount: res.data.data.received_amount,
            grand_total: res.data.data.grand_total,
            due: res.data.data.due,
          });

          setpaymentAmount(res.data.data.due);
          setpriviousDate({
            billing_month: res.data.data.billing_month,
            billing_date: res.data.data.billing_date,
            due_date: res.data.data.due_date,
          });
        } else {
          setbill_entries_id();
          setamount({
            received_amount: 0,
            grand_total: 0,
            due: 0,
          });
          setpaymentAmount(0.0);
        }

        setisLoading(false);
      })
      .catch((err) => setisLoading(false));
  };

  const [paymentAmount, setpaymentAmount] = useState(0);

  const [selectedPayment, setSelectedPayment] = useState("Cash");
  const [paymentOptionSelected, setPaymentOptionSelected] = useState("");
  const [activePayment, setactivePayment] = useState(false);
  const [expireDate, setExpireDate] = useState("");
  const [digitalPaymentNumber, setDigitalPaymentNumber] = useState("");

  const changeSelectedHandler = (e) => {
    setSelectedPayment(e.target.value);
    makeStateEmpty();
    setotpIterfaceShowing(false);
    setisOtpVerifyed(false);
  };
  const changePaymentOptionSeleted = (e) => {
    setPaymentOptionSelected(e.target.value);
  };

  const makeStateEmpty = () => {
    setPaymentOptionSelected("");
    setDigitalPaymentNumber("");
    setExpireDate("");
    setactivePayment("");
  };

  const [payNowloading, setpayNowloading] = useState(false);
  const [singlePaymentHitory, setsinglePaymentHitory] = useState();
  const paymentRequest = () => {
    if (paymentAmount < 1) {
      return Swal.fire({
        position: "center",
        icon: "warning",
        title: "You have to add amount",
        timer: 4500,
      });
    }
    if (!selectedPayment) {
      return Swal.fire({
        position: "center",
        icon: "warning",
        title: "You have to select the payment payment method",
        timer: 4500,
      });
    }
    if (
      selectedPayment === "credit-debit" &&
      digitalPaymentNumber === "" &&
      expireDate === ""
    ) {
      return Swal.fire({
        position: "center",
        icon: "warning",
        title: "You have to fill up all the information",
        timer: 4500,
      });
    }
    if (selectedPayment === "digital-payment" && digitalPaymentNumber === "") {
      return Swal.fire({
        position: "center",
        icon: "warning",
        title: "You have to fill up all the information",
        timer: 4500,
      });
    }

    console.log("pay", typeof paymentAmount, Number(paymentAmount).toFixed(2));
    const data = {
      bill_entries_id: bill_entries_id,
      amount: Number(paymentAmount).toFixed(2),
      payment_method: selectedPayment,
      name: paymentOptionSelected,
      number: digitalPaymentNumber,
      expired_Date: expireDate,
      owner_id:
        ownerDetails?.tenant === null
          ? ownerDetails?.owner?.id
          : ownerDetails?.tenant?.id,
    };
    setpayNowloading(true);
    http
      .post("make-payment-request", data)
      .then((res) => {
        makeStateEmpty();
        setamount({
          received_amount: Number(res.data.data.bill.received_amount),
          grand_total: Number(res.data.data.bill.grand_total),
          due: Number(res.data.data.bill.due),
        });
        setpaymentAmount(Number(res.data.data.bill.due).toFixed(2));
        setsinglePaymentHitory(res.data.data.payment);
        setbalance(res.data.data.balance);
        setpayNowloading(false);
        setSelectedPayment("Cash");
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Payment added successfully",
          buttons: true,
          dangerMode: true,
        }).then((v) => {
          if (v) {
            handlePrint();
          }
        });
      })
      .catch((err) => {
        Swal.fire({
          position: "center",
          icon: "error",
          title: err.response.data.message,
          timer: 4500,
        });
        setpayNowloading(false);
        setSelectedPayment("Cash");
      });
  };

  const componentPaymetSlipRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentPaymetSlipRef.current,
  });

  const [requestAmount, setrequestAmount] = useState(0);
  const [requestLoading, setrequestLoading] = useState(false);
  const [otpIterfaceShowing, setotpIterfaceShowing] = useState(false);
  const [otpLoading, setotpLoading] = useState(false);

  const [otpInput, setotpInput] = useState();
  const send_request = () => {
    if (Number(requestAmount) > Number(balance)) {
      return Swal.fire({
        position: "center",
        icon: "error",
        title: "Insufficient balance",
      });
    }
    if (Number(requestAmount) < 1) {
      return Swal.fire({
        position: "center",
        icon: "error",
        title: "You request amount is invalid",
      });
    }
    const data = {
      mobile:
        ownerDetails?.tenant === null
          ? ownerDetails?.owner?.mobile_1
          : ownerDetails?.tenant?.mobile_1,
      amount: requestAmount,
    };
    setrequestLoading(true);
    http
      .post("send-payment-request", data)
      .then((res) => {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "You payment request send successfully",
          timer: 4500,
        });
        setrequestLoading(false);
        setotpIterfaceShowing(true);
      })
      .catch((err) => setrequestLoading(false));
  };

  const [isOtpVerifyed, setisOtpVerifyed] = useState(false);

  const check_otp = () => {
    setotpLoading(true);
    setisOtpVerifyed(false);
    const data = {
      mobile:
        ownerDetails?.tenant === null
          ? ownerDetails?.owner?.mobile_1
          : ownerDetails?.tenant?.mobile_1,
      otp: otpInput,
    };
    http
      .post("otp-verify", data)
      .then((res) => {
        setotpLoading(false);
        setisOtpVerifyed(true);
        setpaymentAmount(requestAmount);
        Swal.fire({
          position: "center",
          icon: "success",
          title: res.data.message,
          timer: 4500,
        });
      })
      .catch((err) => {
        setisOtpVerifyed(false);
        setotpLoading(false);
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Invalid OTP or OTP has expired",
          timer: 4500,
        });
      });
  };

  return (
    <Modal
      isOpen={ispaymentModal}
      onRequestClose={ispaymentModal}
      style={BillingManagementModal}
      contentLabel="Example Modal"
    >
      <div className="product_modal">
        <div className="page-content">
          <div className=" patients-head ">
            <h5 className="fw-normal  text-start card-name">
              Payment
              <span
                style={{ cursor: "pointer", fontSize: "16px" }}
                onClick={closeModal}
                className="float-end"
              >
                <i className="fal fa-times"></i>
              </span>
              <hr></hr>
            </h5>
          </div>

          <div className="row">
            <div className="col-2 left_panel table-scroll">
              <div className="building">
                <label className="mb-2">Property</label>
                <Select
                  styles={colourStyles}
                  className="basic-single mb-2"
                  value={projectWiseFloorVlaue}
                  options={projectWiseFloor}
                  getOptionLabel={(option) => option.property_name}
                  onChange={(v, i) => {
                    setprojectWiseFloorVlaue(v);
                    setbuildingDetails(v?.building);
                    setbuildingValue(null);
                    setfloorDetails([]);
                  }}
                />
              </div>
              <div className="building">
                <label className="mb-2">Building</label>
                <Select
                  styles={colourStyles}
                  className="basic-single mb-2"
                  value={buildingValue}
                  options={buildingDetails}
                  getOptionLabel={(option) => option.title}
                  onChange={(v, i) => {
                    setbuildingValue(v);
                    setfloorDetails(v?.units);
                    setfloorDetailsforSearch(v?.units);
                  }}

                />
              </div>
              <div className="row my-2 ">
                <div className="col-4 mt-1 ">
                  <p>
                    Flat List

                  </p>
                </div>
                <div className="col-8 d-flex">
                  <input
                    onChange={(e) => {
                      const { value } = e.target;
                      if (value.length > 0) {
                        var data = floorDetailsforSearch.filter((item) =>
                          item.unit_name
                            .toLowerCase()
                            .startsWith(value.toLowerCase())
                        );
                      } else {
                        var data = floorDetailsforSearch;
                      }
                      setfloorDetails(data);
                    }}
                    type="text"
                    placeholder="Search Flat"
                    className="form-control form-control-sm"
                  />
                </div>
              </div>

              {floorDetails?.length > 0 ? (
                floorDetails?.map((item, i) => {
                  return (
                    <div
                      key={i}
                      onClick={() => {
                        forServiceandOwnerDetails(item);
                      }}
                      className={`flat ${Number(ownerDetails?.id) === Number(item?.id) &&
                        "active"
                        }`}
                    >
                      <label>{item?.unit_name}</label>
                    </div>
                  );
                })
              ) : (
                <div className="flat" style={{ color: "#E74C3C" }}>
                  <label>Records are not available</label>
                </div>
              )}
            </div>
            {isLoading ? (
              <div className="col-9" align="center">
                <CircularProgress />
              </div>
            ) : ownerDetails?.id ? (
              <div className="col-10">
                <div className="row">
                  <div className="col-5">
                    <div className="owner_info d-flex">
                      {ownerDetails?.id === undefined ? (
                        <img
                          style={{ width: "50px", height: "50px" }}
                          src={noImage}
                          alt="User_Image"
                        />
                      ) : (
                        <img
                          style={{ width: "50px", height: "50px" }}
                          src={
                            ownerDetails?.tenant === null
                              ? ownerDetails?.owner?.picture &&
                                ownerDetails?.owner?.picture != "null"
                                ? `${global.img_Url}/images/${ownerDetails?.owner?.picture}`
                                : noImage
                              : ownerDetails?.tenant?.picture &&
                                ownerDetails?.tenant?.picture != "null"
                                ? `${global.img_Url}/images/${ownerDetails?.tenant?.picture}`
                                : noImage
                          }
                          alt="User_Image"
                        />
                      )}
                      <div className="info">
                        <div className="ms-2 ">
                          <p>
                            Name :
                            {ownerDetails?.tenant === null
                              ? ownerDetails?.owner?.name
                              : ownerDetails?.tenant?.name}
                          </p>
                          <p>
                            Mobile :
                            {ownerDetails?.tenant === null
                              ? ownerDetails?.owner?.mobile_1
                              : ownerDetails?.tenant?.mobile_1}
                          </p>
                          <p>
                            Address :
                            {ownerDetails?.tenant === null
                              ? ownerDetails?.owner?.address_line_1
                              : ownerDetails?.tenant?.address_line_1}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-7">
                    <div className="row" style={{ fontWeight: "600" }}>
                      <div
                        className="col-4 owner_info me-2"
                        style={{ color: "blue" }}
                        align="center"
                      >
                        <p>Total :</p>
                        <p>{currencyFormat(amount?.grand_total)}</p>
                      </div>
                      <div
                        className="col-4 owner_info me-2"
                        style={{ color: "green" }}
                        align="center"
                      >
                        <p>Recived :</p>
                        <p>{currencyFormat(amount?.received_amount)}</p>
                      </div>
                      <div
                        className="col-3 owner_info"
                        align="center"
                        style={{ color: "red" }}
                      >
                        <p> Due :</p>
                        <p>{currencyFormat(amount?.due)}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="d-flex mt-3 mb-2">
                  <div className="me-2">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label={"Billing Month/Y"}
                        views={["month", "year"]}
                        value={
                          priviousDate.billing_month === null
                            ? dayjs().subtract(1, "month")
                            : dayjs(priviousDate.billing_month)
                        }
                        onChange={(v) => {
                          checkBillEntryStatus(
                            ownerDetails?.id,
                            dayjs(v.$d).format("MM"),
                            dayjs(v.$d).format("YYYY")
                          );
                        }}
                        slotProps={{ textField: { size: "small" } }}
                      />
                    </LocalizationProvider>
                  </div>
                  <div className="me-2">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label={"Billing date"}
                        format={dateFormat}
                        value={
                          priviousDate.billing_date === null
                            ? dayjs()
                            : dayjs(priviousDate.billing_date)
                        }
                        readOnly
                        slotProps={{ textField: { size: "small" } }}
                      />
                    </LocalizationProvider>
                  </div>
                  <div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label={"Due date"}
                        format={dateFormat}
                        value={
                          priviousDate.due_date === null
                            ? dayjs().add(5, "day")
                            : dayjs(priviousDate.due_date)
                        }
                        readOnly
                        slotProps={{ textField: { size: "small" } }}
                      />
                    </LocalizationProvider>
                  </div>
                </div>

                {bill_entries_id ? (
                  <div className="row billing_right_content_height table-scroll">
                    <Row>
                      {/* payment area */}
                      <Col lg={6}>
                        <div className="custom-card p-3 mt-2 pt-4">
                          <div className="d-flex justify-content-between">
                            <h6>Payment Request</h6>
                            <FaHistory
                              onClick={() => {
                                setpaymentHistoryModal(true);
                              }}
                              style={{ cursor: "pointer" }}
                            />

                            {paymentHistoryModal && (
                              <PaymentHistoryModal
                                paymentHistoryModal={paymentHistoryModal}
                                setpaymentHistoryModal={setpaymentHistoryModal}
                                bill_entries_id={bill_entries_id}
                              />
                            )}
                          </div>

                          <p>
                            A request has been made for you to submit payment
                          </p>
                          <div className="mt-2">
                            <p>
                              Invoice : <b>{invoice}</b>{" "}
                            </p>
                            <p>Required Payment Amount</p>
                            <div className="row mt-2">
                              <div className="col-8">
                                {!activePayment ? (
                                  <div
                                    style={{
                                      cursor: "pointer",
                                      fontWeight: "700",
                                      fontSize: "17px",
                                    }}
                                  >
                                    <span>BDT</span>
                                    <span
                                      onClick={() =>
                                        selectedPayment !== "e-wallet" &&
                                        setactivePayment(true)
                                      }
                                      className="money-amount-box d-inline-block ms-4"
                                    >
                                      {Number(paymentAmount)?.toFixed(2)}
                                    </span>
                                    {selectedPayment !== "e-wallet" && (
                                      <CiEdit
                                        className="ms-2"
                                        onClick={() => setactivePayment(true)}
                                      />
                                    )}
                                  </div>
                                ) : (
                                  <div className="row">
                                    <div className="col-3">
                                      <span>BDT</span>
                                    </div>
                                    <div className="col-9">
                                      <input
                                        onKeyDown={(e) => {
                                          if (e.key === "Enter") {
                                            paymentRequest();
                                          }
                                        }}
                                        onChange={(e) =>
                                          setpaymentAmount(e.target.value)
                                        }
                                        onBlur={() => setactivePayment(false)}
                                        value={paymentAmount}
                                        autoFocus
                                        type="number"
                                        className="form-control form-control-sm"
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div className="col-4">
                                {payNowloading ? (
                                  <button className="vaital-setup-btn">
                                    <CircularProgress
                                      style={{
                                        height: "20px",
                                        width: "20px",
                                        color: "white",
                                      }}
                                    />
                                  </button>
                                ) : (
                                  selectedPayment !== "e-wallet" && (
                                    <button
                                      className="vaital-setup-btn"
                                      onClick={paymentRequest}
                                    >
                                      Pay Now
                                    </button>
                                  )
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="mt-2">
                            <h6>Remark</h6>
                            <div className="row mt-2">
                              <div className="col-5">
                                <span>Deposit for</span>{" "}
                                <span className="fw-bold ms-2">Due</span>
                              </div>
                            </div>
                          </div>
                          <p>
                            If you have any question or need help, please
                            contact us.
                          </p>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="custom-card p-3 mt-2 pt-4">
                          <h6>Payment</h6>
                          {/* cash */}

                          <div className="d-flex justify-content-between align-items-center payment-container rounded mb-1">
                            <div className="d-flex">
                              <input
                                type="radio"
                                name="method"
                                value="Cash"
                                id="cash"
                                checked={selectedPayment === "Cash"}
                                onChange={changeSelectedHandler}
                              />
                              <label className="ms-2" htmlFor="Cash">
                                Cash
                              </label>
                            </div>
                            <div className="d-flex justify-content-end align-items-center gap-1">
                              <img
                                className="cash-icon"
                                src={cashIcon}
                                alt="cash-icon"
                              />
                            </div>
                          </div>
                          {/* credit/debit */}
                          <div className="payment-container mb-1 pt-1 rounded">
                            <div className="d-flex justify-content-between align-items-center   mb-1">
                              <div className="d-flex">
                                <input
                                  type="radio"
                                  name="method"
                                  value="credit-debit"
                                  id="credit-debit"
                                  checked={selectedPayment === "credit-debit"}
                                  onChange={changeSelectedHandler}
                                />
                                <label className="ms-2" htmlFor="credit-debit">
                                  Credit / Debit Card
                                </label>
                              </div>
                              <div className="d-flex justify-content-end align-items-center gap-1">
                                <img
                                  className="debit-credit-icon"
                                  src={creditDebitCard}
                                  alt="credit-debit-icon"
                                />
                              </div>
                            </div>
                            {/* payment option */}
                            {selectedPayment === "credit-debit" && (
                              <>
                                <div className="p-2  mb-2 d-flex gap-2">
                                  <div className="radio-container">
                                    <div className="d-flex">
                                      <input
                                        type="radio"
                                        name="visa"
                                        value="Visa Card"
                                        id="visa"
                                        checked={
                                          paymentOptionSelected === "Visa Card"
                                        }
                                        onChange={changePaymentOptionSeleted}
                                      />
                                      <label
                                        className="ms-1"
                                        htmlFor="Visa Card"
                                      >
                                        Visa
                                      </label>
                                    </div>
                                  </div>
                                  {/* master card */}
                                  <div className="radio-container">
                                    <div className="d-flex">
                                      <input
                                        type="radio"
                                        name="MasterCard"
                                        value="Master Card"
                                        id="MasterCard"
                                        checked={
                                          paymentOptionSelected ===
                                          "Master Card"
                                        }
                                        onChange={changePaymentOptionSeleted}
                                      />
                                      <label
                                        className="ms-1"
                                        htmlFor="Master Card"
                                      >
                                        Master Card
                                      </label>
                                    </div>
                                  </div>
                                  <div className="radio-container">
                                    <div className="d-flex">
                                      <input
                                        type="radio"
                                        name="AmericanExpress"
                                        value="American Express"
                                        id="AmericanExpress"
                                        checked={
                                          paymentOptionSelected ===
                                          "American Express"
                                        }
                                        onChange={changePaymentOptionSeleted}
                                      />
                                      <label
                                        className="ms-1"
                                        htmlFor="American Express"
                                      >
                                        American Express
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                {/* card form */}
                                <form className="d-flex justify-content-start gap-3">
                                  <div className="form-group">
                                    <label for="card-number">
                                      {paymentOptionSelected} Number
                                    </label>
                                    <input
                                      type="number"
                                      className="form-control"
                                      id="card-number"
                                      placeholder="Enter Card Number"
                                      onChange={(e) =>
                                        setDigitalPaymentNumber(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label for="card-expire-date">
                                      Expiry Date
                                    </label>
                                    <LocalizationProvider
                                      dateAdapter={AdapterDayjs}
                                    >
                                      <DatePicker
                                        label={" "}
                                        format={dateFormat}
                                        onChange={(v) => setExpireDate(v?.$d)}
                                        slotProps={{
                                          textField: { size: "small" },
                                        }}
                                      />
                                    </LocalizationProvider>

                                    {/* <input
                                      type='date'
                                      className='form-control'
                                      id='card-expire-date'
                                      onChange={(e) => setExpireDate(e.target.value)}
                                    /> */}
                                  </div>
                                </form>
                              </>
                            )}
                          </div>

                          {/* digital payment */}
                          <div className="payment-container mb-1 pt-1 rounded">
                            <div className="d-flex justify-content-between align-items-center  mb-1">
                              <div className="d-flex">
                                <input
                                  type="radio"
                                  name="digital-payment"
                                  value="digital-payment"
                                  id="digital-payment"
                                  checked={
                                    selectedPayment === "digital-payment"
                                  }
                                  onChange={changeSelectedHandler}
                                />
                                <label
                                  className="ms-2"
                                  htmlFor="digital-payment"
                                >
                                  Digital Payment
                                </label>
                              </div>
                              <div className="d-flex justify-content-end align-items-center gap-1">
                                <img
                                  className="payment-icon"
                                  src={digitalPayment}
                                  alt="digital-payment"
                                />
                              </div>
                            </div>
                            {/* payment option */}
                            {selectedPayment === "digital-payment" && (
                              <>
                                <div className="p-2 d-flex gap-2">
                                  <div className="radio-container">
                                    <div className="d-flex">
                                      <input
                                        type="radio"
                                        name="method"
                                        value="Rocket"
                                        id="Rocket"
                                        checked={
                                          paymentOptionSelected === "Rocket"
                                        }
                                        onChange={changePaymentOptionSeleted}
                                      />
                                      <label className="ms-1" htmlFor="Rocket">
                                        Rocket
                                      </label>
                                    </div>
                                  </div>
                                  {/* master card */}
                                  <div className="radio-container">
                                    <div className="d-flex">
                                      <input
                                        type="radio"
                                        name="method"
                                        value="Nagad"
                                        id="Nagad"
                                        checked={
                                          paymentOptionSelected === "Nagad"
                                        }
                                        onChange={changePaymentOptionSeleted}
                                      />
                                      <label className="ms-1" htmlFor="Nagad">
                                        Nagad
                                      </label>
                                    </div>
                                  </div>
                                  <div className="radio-container">
                                    <div className="d-flex">
                                      <input
                                        type="radio"
                                        name="method "
                                        value="BKash"
                                        id="BKash"
                                        checked={
                                          paymentOptionSelected === "BKash"
                                        }
                                        onChange={changePaymentOptionSeleted}
                                      />
                                      <label className="ms-1" htmlFor="BKash">
                                        BKash
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                {/*  form */}
                                <form className="d-flex justify-content-start gap-3">
                                  <div className="form-group">
                                    <label for="card-number">
                                      {paymentOptionSelected} {""}
                                    </label>
                                    <input
                                      type="number"
                                      className="form-control"
                                      id="card-number"
                                      placeholder="Payment Number"
                                      onChange={(e) =>
                                        setDigitalPaymentNumber(e.target.value)
                                      }
                                    />
                                  </div>
                                </form>
                              </>
                            )}
                          </div>
                          {/* e-wallet*/}
                          <div className="payment-container rounded mb-1">
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="d-flex">
                                <input
                                  disabled
                                  type="radio"
                                  name="e-wallet"
                                  value="e-wallet"
                                  checked={selectedPayment === "e-wallet"}
                                  id="e-wallet"
                                  onChange={(e) => {
                                    changeSelectedHandler(e);
                                    // setpaymentAmount(balance);
                                  }}
                                />
                                <label className="ms-2" htmlFor="e-wallet">
                                  e-Wallet
                                </label>
                              </div>
                              <div className="d-flex justify-content-end align-items-center p-2 gap-1">
                                {/* <img
                              className="payment-icon"
                              src={eWallet}
                              alt="e-wallet"
                            /> */}
                                <p>Available Balance: </p>
                                <p
                                  style={{ color: "green", fontWeight: "bold" }}
                                >
                                  {currencyFormat(balance)}
                                </p>
                              </div>
                            </div>

                            {selectedPayment === "e-wallet" && (
                              <>
                                <div className="row mb-1 align-items-center">
                                  <div className="col-sm-7">
                                    <input
                                      type="number"
                                      onChange={(e) => {
                                        setrequestAmount(e.target.value);
                                      }}
                                      onKeyDown={(event) => {
                                        if (event.key === "Enter") {
                                          send_request();
                                        }
                                      }}
                                      className="form-control"
                                      placeholder="Requested Amount"
                                      disabled={
                                        otpIterfaceShowing ? true : false
                                      }
                                    />
                                  </div>

                                  <div className="col-sm-5">
                                    {requestLoading ? (
                                      <button className="vaital-setup-btn">
                                        Sending....
                                      </button>
                                    ) : (
                                      <button
                                        className="vaital-setup-btn"
                                        onClick={send_request}
                                      >
                                        Send Request
                                      </button>
                                    )}
                                  </div>
                                </div>
                                {otpIterfaceShowing && (
                                  <div className="row mb-1 align-items-center">
                                    <div className="col-sm-4">
                                      <input
                                        disabled={isOtpVerifyed}
                                        type="number"
                                        className="form-control"
                                        placeholder="OTP"
                                        onChange={(e) => {
                                          setotpInput(e.target.value);
                                        }}
                                        onKeyDown={(event) => {
                                          if (event.key === "Enter") {
                                            check_otp();
                                          }
                                        }}
                                      />
                                    </div>
                                    <div className="col-sm-6 d-flex gap-1">
                                      {otpLoading ? (
                                        <button
                                          style={{ background: "blue" }}
                                          className="vaital-setup-btn"
                                        >
                                          checking...
                                        </button>
                                      ) : isOtpVerifyed ? (
                                        <FaCheckCircle />
                                      ) : (
                                        <button
                                          style={{ background: "blue" }}
                                          className="vaital-setup-btn"
                                          onClick={check_otp}
                                        >
                                          Verify otp
                                        </button>
                                      )}

                                      {isOtpVerifyed && (
                                        <button
                                          className="vaital-setup-btn"
                                          onClick={paymentRequest}
                                        >
                                          Pay now
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                )}
                              </>
                            )}
                          </div>

                          {/* e-payment*/}
                          {/* <div className='d-flex justify-content-between align-items-center payment-container rounded mb-1'>
                            <div className='d-flex'>
                              <input
                                type='radio'
                                name='e-payment'
                                value='e-payment'
                                id='e-payment'
                                disabled
                              // onChange={changeHandler}
                              />
                              <label className='ms-2' htmlFor='e-payment'>
                                e-Pay.
                              </label>
                            </div>
                            <div className='d-flex justify-content-end align-items-center gap-1'>
                              <img className='payment-icon' src={ePayment} alt='e-payment' />
                            </div>
                          </div> */}
                        </div>
                      </Col>

                      {/* balance calculation && time && date */}
                    </Row>
                  </div>
                ) : (
                  <div
                    style={{ color: "red", fontSize: "20px", marginTop: "5%" }}
                    align="center"
                  >
                    <p>
                      This apartment is not eligible for billing. Please
                      complete the billing process first.
                    </p>
                  </div>
                )}
                <div className="payment_slip" ref={componentPaymetSlipRef}>
                  <PaymentSlip
                    ownerDetails={ownerDetails}
                    singlePaymentHitory={singlePaymentHitory}
                    amount={amount}
                    invoice={invoice}
                  />
                </div>
              </div>
            ) : (
              <div
                className="col-10"
                style={{ color: "red", fontSize: "20px" }}
                align="center"
              >
                <p>Please select the the appartment first</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
}
