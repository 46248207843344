import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { FcPrint } from "react-icons/fc";
import { useReactToPrint } from "react-to-print";
import Setting from "../../Setting/Setting";

import logo from "./../../../front_assets/Logo_Image/logo.jpg";

export default function DepositSlip({ ownerDetails, depostiHistory }) {
  const { currencyFormat } = Setting;

  return (
    <>
      <div className="product_modal">
        <div className="page-content">
          <div className="history-body">
            <div className="d-flex justify-content-between align-items-center mb-1">
              <h4 className="text-uppercase">Deposit Receipt</h4>
              <h5 className="text-uppercase">Original</h5>
              <img src={logo} width="50" height="50" alt="logo" />
            </div>
            <div className="owner_info mb-2">
              <div className="row">
                <div className="col-6">
                  <div className="row">
                    <div className="col-3">Name </div>
                    <div className="col-9">: {ownerDetails?.name}</div>
                  </div>
                  <div className="row">
                    <div className="col-3">Mobile </div>
                    <div className="col-9">: {ownerDetails?.mobile_1}</div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="row">
                    <div className="col-3">email</div>
                    <div className="col-9">: {ownerDetails?.email}</div>
                  </div>
                  <div className="row">
                    <div className="col-3">Address</div>
                    <div className="col-9">
                      :{ownerDetails?.address_line_1}{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex mb-2">
              <div className="col-3 me-1 owner_info">
                <p>Total Deposit Amount :</p>
                <p>{currencyFormat(ownerDetails?.e_wallet?.balance)}</p>
              </div>
            </div>

            <div className="unit-manager-table invoice-item-table mb-3">
              <table className="">
                <tr>
                  <td>MRN</td>
                  <td>Payment Date</td>
                  <td>time</td>

                  <td>Payment Method</td>
                  <td>Amount</td>
                </tr>
                <tr>
                  <td>{depostiHistory?.trun_id}</td>
                  <td>
                    {moment(depostiHistory?.created_at).format("DD/MM/YYYY")}
                  </td>
                  <td>{moment(depostiHistory?.created_at).format("h:mm a")}</td>
                  <td>{depostiHistory?.payment_type}</td>

                  <td align="right">{depostiHistory?.amount}</td>
                </tr>
                <tr>
                  <td colSpan={4}>
                    <b>Total</b>
                  </td>
                  <td align="right">
                    <b>{currencyFormat(depostiHistory?.amount)}</b>
                  </td>
                </tr>
              </table>
              <p className="mt-1">
                Total amount of recived :
                {currencyFormat(depostiHistory?.amount)}
              </p>
            </div>
            <div className="d-flex justify-content-end mt-4">
              <p style={{ borderTop: "1px dashed grey", paddingTop: "10px" }}>
                Authorized Signatures
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="product_modal">
        <div className="page-content">
          <div className="history-body">
            <div className="d-flex justify-content-between align-items-center mb-1">
              <h4 className="text-uppercase">Deposit Receipt</h4>
              <h5 className="text-uppercase">Customer Copy</h5>
              <img src={logo} width="50" height="50" alt="logo" />
            </div>
            <div className="owner_info mb-2">
              <div className="row">
                <div className="col-6">
                  <div className="row">
                    <div className="col-3">Name </div>
                    <div className="col-9">: {ownerDetails?.name}</div>
                  </div>
                  <div className="row">
                    <div className="col-3">Mobile </div>
                    <div className="col-9">: {ownerDetails?.mobile_1}</div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="row">
                    <div className="col-3">email</div>
                    <div className="col-9">: {ownerDetails?.email}</div>
                  </div>
                  <div className="row">
                    <div className="col-3">Address</div>
                    <div className="col-9">
                      :{ownerDetails?.address_line_1}{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex mb-2">
              <div className="col-3 me-1 owner_info">
                <p>Total Deposit Amount :</p>
                <p>{currencyFormat(ownerDetails?.e_wallet?.balance)}</p>
              </div>
            </div>

            <div className="unit-manager-table invoice-item-table mb-3">
              <table className="">
                <tr>
                  <td>MRN</td>
                  <td>Payment Date</td>
                  <td>time</td>

                  <td>Payment Method</td>
                  <td>Amount</td>
                </tr>
                <tr>
                  <td>{depostiHistory?.trun_id}</td>
                  <td>
                    {moment(depostiHistory?.created_at).format("DD/MM/YYYY")}
                  </td>
                  <td>{moment(depostiHistory?.created_at).format("h:mm a")}</td>
                  <td>{depostiHistory?.payment_type}</td>

                  <td align="right">{depostiHistory?.amount}</td>
                </tr>
                <tr>
                  <td colSpan={4}>
                    <b>Total</b>
                  </td>
                  <td align="right">
                    <b>{currencyFormat(depostiHistory?.amount)}</b>
                  </td>
                </tr>
              </table>
              <p className="mt-1">
                Total amount of recived :
                {currencyFormat(depostiHistory?.amount)}
              </p>
            </div>
            <div className="d-flex justify-content-end mt-4">
              <p style={{ borderTop: "1px dashed grey", paddingTop: "10px" }}>
                Authorized Signatures
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
