
import axios from "axios";

export default axios.create({
    baseURL: `${process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_MODE_URL : process.env.REACT_APP_PRO_MODE_URL}`,
    headers: {
        "Content-type": "application/json",
        "Authorization": localStorage.getItem("user") !== null ? `Bearer ${JSON.parse(localStorage.getItem("user"))?.access_token}` : `Bearer ${JSON.parse(sessionStorage.getItem("user"))?.access_token}`
    }
});

