import React, { useState } from "react";
import AuthUser from "./AuthUser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "../front_assets/Logo_Image/Logo.png";
import http from "../http";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";

export default function Login() {
  const { setUser } = AuthUser();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isRemember, setIsRemember] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const submitForm = (e) => {
    e.preventDefault();
    setIsLoading(true);
    http
      .post("login-with-saas", { email: email, password: password })
      .then((res) => {
        console.log(res.data.status);

        if (res.data.status === 422) {
          setIsLoading(false);
          Swal.fire({
            icon: "error",
            title: "Your subscription has expired",
            text: "It seems your subscription has expired. Please renew now to continue accessing our services.",
            showConfirmButton: true,
            confirmButtonText: "Renew Now",
            confirmButtonColor: "#035E7B",
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.href = "https://saas.apartpro.xyz/";
            }
          });

          return;
        }

        if (res.data.status === 200) {
          setUser(res.data, isRemember);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("Invalid Phone or Password", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  return (
    <div className="container">
      <div className="row justify-content-center mt-5">
        <div className="col-md-4">
          <div className="card">
            <div className="card-body">
              <ToastContainer />
              <div className="text-center mb-4">
                <img
                  src={logo}
                  alt="Logo"
                  style={{ width: "100px" }}
                  className="mb-2"
                />
                <h4 className="card-title mb-1">Welcome</h4>
                <p className="card-text">
                  Please sign in to your account and start the adventure.
                </p>
              </div>
              <form onSubmit={submitForm}>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    Phone number
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="01X-XXXXXX-XX"
                    autoFocus
                  />
                </div>
                <div className="mb-3">
                  <div className="d-flex justify-content-between">
                    <label htmlFor="password" className="form-label">
                      Password
                    </label>
                    <Link to="/forgot-password" className="small">
                      Forgot Password?
                    </Link>
                  </div>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="············"
                  />
                </div>
                <div className="mb-3 form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="remember-me"
                    checked={isRemember}
                    onChange={(e) => setIsRemember(e.target.checked)}
                  />
                  <label className="form-check-label" htmlFor="remember-me">
                    Remember Me
                  </label>
                </div>
                <button
                  type="submit"
                  className="btn btn-primary w-100"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <div
                      className="spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  ) : (
                    "Sign In"
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
