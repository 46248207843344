
import MaterialTable from 'material-table'
import React, { Fragment } from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import Modal from 'react-modal';
import Swal from 'sweetalert2';
import http from '../../../http';
import moment from 'moment'
import allCustomStyles from '../../../CSS/ReactModalStyle';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default function Area() {

    const { addreessModule } = allCustomStyles;
    const [updateButton, setUpdateButton] = useState(false);

    const addData = () => {
        setIsOpen(true);
    }
    const deleteRowData = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        })
            .then((result) => {
                if (result.isConfirmed) {
                    http.delete(`area/${id}`)
                        .then((res) => {


                            if (res?.data?.status == 500) {
                                Swal.fire({
                                    position: 'top-center',
                                    icon: 'error',
                                    title: 'Error !',
                                    text: res?.data?.message,
                                    showConfirmButton: false,
                                    timer: 1500
                                })
                            } else {
                                setUpdate(!update);
                                setIsOpen(false);
                                Swal.fire({
                                    position: 'top-center',
                                    icon: 'success',
                                    title: 'Success !',
                                    text: 'Data Deleted Successfully',
                                    showConfirmButton: false,
                                    timer: 1500
                                })
                            }
                        })
                }
            })
    }
    const editData = (id) => {
        setIsOpen(true)
        http.get(`area/${id}/edit`)
            .then((res) => {
                setInputData({
                    inputData,
                    id: res?.data?.data?.id,
                    division_name: res?.data?.data?.division?.name,
                    division_id: res?.data?.data?.division_id,
                    district_id: res?.data?.data?.district_id,
                    district_name: res?.data?.data?.district?.name,
                    area: res?.data?.data?.area,
                })
                setIsOpen(true);
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const columns = [
        {
            title: "SL",
            field: "",
            render: (row) => <div>{row?.tableData?.id + 1}</div>,
            width: "20 !important",
            cellStyle: {
                textAlign: "center",
            },
        },
        {
            title: "Division",
            render: (row) => <div>{row?.division?.name}</div>,
            field: `division_id`,
            cellStyle: {
                textAlign: "center",
            },
        },
        {
            title: "District",
            render: (row) => <div>{row?.district?.name}</div>,
            field: `district_id`,
            cellStyle: {
                textAlign: "center",
            },
        },

        {
            title: "Area",
            field: `area`,
            cellStyle: {
                textAlign: "center",
            },
        },
        {
            title: "Action",
            field: "patient",
            render: (row) => <div>{
                row?.deletable == 1 ? (
                    <></>
                ) : (
                    <>
                        <button
                            onClick={() => editData(row?.id)}
                            className="btn btn-sm action-btn"
                        >
                            <i className="far fa-edit"></i>
                        </button>

                        <button
                            onClick={() => deleteRowData(row?.id)
                            }
                            className="btn btn-sm action-btn"
                        >
                            <i className="far fa-trash"></i>
                        </button >
                    </>
                )
            }</div >,
            cellStyle: {
                textAlign: "center",
            },
        },
    ];

    const [data, setData] = useState([]);
    const [update, setUpdate] = useState(false);
    const [spinner, setSpinner] = useState(false);
    const [district, setDistrict] = useState([]);
    const [divisions, setDivisions] = useState([]);
    useEffect(() => {
        const controller = new AbortController();
        setSpinner(true);
        http
            .get(`area`)
            .then((res) => {
                console.log(res, 'res')
                setData(res?.data?.data);
                setSpinner(false);
            })
            .catch((err) => {
                setSpinner(false);
                console.log(err);
            });
        http
            .get(`get-division-and-district-data`)
            .then((res) => {
                setDivisions(res?.data?.data)
                // setData(res?.data?.data);
                setSpinner(false);
            })
            .catch((err) => {
                setSpinner(false);
                console.log(err);
            });
        return () => {
            controller.abort();
        };
    }, [update]);
    // add modal 
    console.log(divisions, 'setDivisions')
    const [modalIsOpen, setIsOpen] = useState(false);
    const [inputData, setInputData] = useState({ id: "", division_id: "", district_id: "", area: "", district_name: "", division_name: '' })
    const handleChange = (e) => {
        setInputData({ ...inputData, [e.target.name]: e.target.value });
    }
    const [isLoading, setisLoading] = useState(false)

    const submitData = (e) => {
        e.preventDefault()
        setisLoading(true)
        if (inputData.id) {
            http.put(`area/${inputData?.id}`, inputData)
                .then((res) => {
                    setUpdate(!update);
                    setIsOpen(false);
                    setInputData({ division_id: "", district_id: "", area: "" });
                    Swal.fire({
                        position: 'top-center',
                        icon: 'success',
                        title: 'Success !',
                        text: 'Data Updated Successfully',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    setisLoading(false)
                })
                .catch((err) => {
                    console.log(err)
                    Swal.fire({
                        position: 'top-center',
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    setisLoading(false)
                })
        } else {
            http.post('area', inputData)
                .then((res) => {
                    setUpdate(!update);
                    setIsOpen(false);
                    setInputData({ division_id: "", district_id: "", area: "" });
                    Swal.fire({
                        position: 'top-center',
                        icon: 'success',
                        title: 'Success !',
                        text: 'Data Added Successfully',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    setisLoading(false)
                })
                .catch((err) => {
                    console.log(err)
                    Swal.fire({
                        position: 'top-center',
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    setisLoading(false)
                })
        }

    }
    const closeModal = () => {
        setIsOpen(false);
        setUpdateButton(false)
        setInputData({ id: '', division_id: "", district_id: "", area: "" });
    }
    const handleDivisionChange = (e, value) => {
        setInputData({ ...inputData, ['division_id']: value?.id });
        setDistrict(value?.district)
    }
    console.log(data, 'data')
    return (
        <div className='page-content adjustment-type-table'>
            <div className="custom-card p-2 d-flex justify-content-between mb-2 align-items-center">
                <h6>Area </h6>
                <div>
                    <button style={{ marginTop: "1px" }} onClick={addData} className='btn btn-sm btn-primary float-end'>Add</button>
                </div>
            </div>

            <MaterialTable
                columns={columns}
                data={data}
                isLoading={spinner}
                options={{
                    search: true,
                    showTitle: false,
                    searchFieldAlignment: "left",
                    pageSize: 10,
                    emptyRowsWhenPaging: false,
                    pageSizeOptions: [5, 10, 20, 50, 100],
                }}
            />

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={addreessModule}
                contentLabel="Example Modal"
            >
                <div className="product_modal">
                    <div className="page-content">
                        <div className=" patients-head ">
                            <h5 className="fw-normal custom_py-3 px-2  text-start mb-2 card-name">  {updateButton === true ? 'Edit' : "Add"}
                                <span style={{ cursor: "pointer", fontSize: "16px" }} onClick={closeModal} className='float-end'><i className="fal fa-times"></i></span>
                            </h5>
                        </div>

                        <div className=" p-3">
                            <form onSubmit={submitData}>

                                <div className="row mb-2">
                                    <div className="col-3">
                                        <label> Division <span className="text-danger">*</span></label>
                                    </div>
                                    <div className="col-9">
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            options={divisions}
                                            getOptionLabel={(option) => option?.name}
                                            sx={{ width: '100%' }}
                                            size='small'
                                            onChange={(event, value) => {
                                                handleDivisionChange(event, value)
                                            }}
                                            isOptionEqualToValue={(option, value) => option?.name === value?.name}
                                            renderInput={(params) => <TextField {...params} label={inputData?.division_name} required={inputData?.division_id?.length === 0} />}
                                        />

                                    </div>
                                </div>

                                <div className="row mb-2">
                                    <div className="col-3">
                                        <label> District <span className="text-danger">*</span></label>
                                    </div>
                                    <div className="col-9">
                                        <Autocomplete
                                            required
                                            disablePortal
                                            id="combo-box-demo"
                                            options={district}
                                            getOptionLabel={(option) => option?.name}
                                            sx={{ width: '100%' }}
                                            size='small'
                                            onChange={(event, value) => {
                                                setInputData({ ...inputData, district_id: value?.id })
                                            }}
                                            isOptionEqualToValue={(option, value) => option?.name === value?.name}
                                            renderInput={(params) => <TextField {...params} label={inputData?.district_name} required={inputData?.district_id?.length === 0} />}
                                        />
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-3">
                                        <label htmlFor="type" className="mb-1">
                                            Area
                                        </label>
                                    </div>
                                    <div class="col-9">
                                        <input onChange={handleChange} value={inputData?.area} name="area" type="text" className="form-control form-control-sm my-2" required placeholder="Title" />
                                    </div>
                                </div>

                                {
                                    isLoading ?
                                        <button className="btn mt-2 btn-sm btn-info float-end text-uppercase" type="button"> saving ...</button>
                                        :
                                        updateButton === true ? (
                                            <button className="btn mt-2 btn-sm btn-success float-end text-uppercase" type="submit"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-save mb-1"><path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path><polyline points="17 21 17 13 7 13 7 21"></polyline><polyline points="7 3 7 8 15 8"></polyline></svg> Update</button>
                                        ) : (
                                            <button className="btn mt-2 btn-sm btn-primary float-end text-uppercase" type="submit"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-save mb-1"><path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path><polyline points="17 21 17 13 7 13 7 21"></polyline><polyline points="7 3 7 8 15 8"></polyline></svg> Save</button>
                                        )}
                            </form>

                        </div>

                    </div>
                </div>
            </Modal>
        </div>
    )
}
