// Modal.js

import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import Select from 'react-select'
import http from '../../../http';
import { MdDeleteOutline } from "react-icons/md";
import Swal from 'sweetalert2';
import allCustomStyles from '../../../CSS/ReactModalStyle';
import { CircularProgress, LinearProgress } from '@mui/material';


Modal.setAppElement('#root');

const RentMap = ({ isOpen, closeModalBillMap }) => {
    const { BillingMapModal, colourStyles } = allCustomStyles;
    const [setupData, setsetupData] = useState([])
    const [isLoading, setisLoading] = useState(false)

    useEffect(() => {
        let sub = true;
        setisLoading(true)
        if (sub) {
            http.get(`rent-setup`)
                .then((res) => {
                    setsetupData(res.data.data)
                    setisLoading(false)
                }).catch(err => {
                    console.log("err", err)
                    setisLoading(false)

                })
        }


        return () => {
            sub = false
        }
    }, [])




    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={closeModalBillMap}
            contentLabel="Example Modal"
            style={BillingMapModal}
        >
            <span
                className="float-end"
                style={{ fontSize: "15px", cursor: "pointer" }}
                onClick={closeModalBillMap}
            >
                <i className="fal fa-times"></i>
            </span>
            <h6 style={{ fontSize: "14px" }}>Appartment Rent Map</h6>
            <hr />

            <div className="unit-manager-table invoice-item-table mb-3">
                <span className='mb-1' style={{ border: "1px solid #fff", padding: "3px", padding: '4px 8px', border: "1px solid", borderRadius: "3px", display: "inline-block" }}>Select Rent </span>
                <table className=''>
                    <tr>
                        <td>Type</td>
                        <td>Price</td>
                        <td>From Data</td>
                        <td>To Data</td>
                        <td>Act</td>
                    </tr>
                    {
                        isLoading ? <tr>
                            <td colSpan={5}>
                                <CircularProgress style={{ width: "30px", height: "30px" }} />
                            </td>
                        </tr> :

                            setupData?.length >
                                0 ?
                                setupData?.map((v, i) => {
                                    return <tr key={i}>
                                        <td>{v.type}</td>
                                        <td>{v.price}</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>

                                    </tr>
                                }) :
                                <tr>
                                    <td style={{ color: "red" }} colSpan={5}>Records are not available</td>

                                </tr>
                    }




                </table>
            </div>



        </Modal>
    );
};

export default RentMap;
