// CustomStyles.js

const serviceModal = {
    content: {
      top: '45%',
      left: '60%',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      maxHeight: '100%',
      width: '400px',
      height: '265px',
      padding: '10px',
    },
};

const SetupModal = {
  content: {
    top: '45%',
    left: '60%',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: '100%',
    width: '400px',
    height: '245px',
    padding: '10px',
  },
};

const HelpLine = {
  content: {
    top: '45%',
    left: '60%',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: '100%',
    width: '30%',
    height: '350px',
    padding: '10px',
  },
};

const BillingMapModal = {
  content: {
    top: '50%',
    left: '21%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    width: "60%",
    height: "400px",
    background: "white",
    padding: "15px",
    marginLeft: "38%",
  },
};
const BillingManagementModal = {
  content: {
    top: '333px',
    left: '21%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    width: "81%",
    height: "522px",
    background: "white",
    padding: "10px 15px",
    marginLeft: "38%",
  },
};
// const BillingManagementModal = {
//   content: {
//     top: '325px',
//     left: '21%',
//     right: 'auto',
//     bottom: 'auto',
//     transform: 'translate(-50%, -50%)',
//     width: "80%",
//     height: "521px",
//     background: "white",
//     padding: "15px",
//     marginLeft: "38%",
//   },
// };
const InvoiceMapModal = {
  content: {
    top: '60%',
    left: '60%',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: '90%',
    width: '70%',
    height: 'auto',
    padding: '10px',
    paddingBottom: '60px'
  },
};
const BillingSmsModal = {
  content: {
    top: '50%',
    left: '60%',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: '90%',
    width: '50%',
    height: 'auto',
    padding: '10px',
    paddingBottom: '60px'
  },
}
const colourStyles={
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
          ...styles,
          backgroundColor: isFocused ? "#999999" : null,
          color: "#333333",
      };
  },
  menu: (provided) => ({
    ...provided,
    zIndex: 9999,
    overflowX: "hidden",
    borderRadius: 5,
  }),
  menuPortal: (base) => ({
    ...base,
    borderRadius: 5,
    zIndex: 9999,
  }),

  // menuList: (provided) => ({
  //   ...provided,
  //   backgroundColor: "white",
  //   margin: "0px",
  //   padding: "0px",
  //   overflowX: "hidden",
  //   overflowY: "auto",
  //   maxHeight: 200 + "px",
  //   "&::-webkit-scrollbar": {
  //     width: "7px",
  //   },
  //   "&::-webkit-scrollbar-thumb": {
  //     backgroundColor: "#cccccc",
  //     borderRadius: "6px",
  //   },
  //   "&::-webkit-scrollbar-track": {
  //     backgroundColor: "#fcfcfc",
  //   },
  //   scrollbarWidth: "thin",
  //   scrollbarColor: "#cccccc #fcfcfc",
  // }),
};
const addreessModule={
  content: {
    top: '45%',
    left: '60%',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: '100%',
    width: '30%',
    height: '300px',
    padding: '10px',
  },
};

const policeStationsModule={
  content: {
    top: '45%',
    left: '60%',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: '100%',
    width: '30%',
    height: '340px',
    padding: '10px',
  },
};
const historyModal={
  content: {
    top: '45%',
    left: '50%',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: '100%',
    width: '50%',
    height: '420px',
    padding: '10px',
  },
};
const allCustomStyles = {
  SetupModal,
  BillingSmsModal,
  BillingMapModal,
  InvoiceMapModal,
  BillingManagementModal,
  colourStyles,
  addreessModule,
  historyModal,
  HelpLine,
  policeStationsModule,
  serviceModal
};



export default allCustomStyles;
  