import React, { useEffect, useRef, useState } from "react";
import http from "../../../http";
import moment from "moment";
import Setting from "../../Setting/Setting";
import { useReactToPrint } from "react-to-print";
import DatePicker from "react-datepicker";
import Select from "react-select";
import allCustomStyles from "../../../CSS/ReactModalStyle";
import "../Billing/Billing.css";
import { FiRefreshCcw } from "react-icons/fi";
import { FiPrinter } from "react-icons/fi";
import { CircularProgress } from "@mui/material";
import PrintStatmentHeader from "../../Componets/PrintStatmentHeader";
import dayjs from "dayjs";

export default function BalanceStatment() {
  const { currencyFormat, dateFormat } = Setting;
  const { colourStyles } = allCustomStyles;

  const [walletData, setwalletData] = useState();
  const [walletDataMaster, setwalletDataMaster] = useState();
  const [startDate, setStartDate] = useState(dayjs().subtract(1, "month")?.$d);
  const [endDate, setEndDate] = useState(dayjs().add(2, "month")?.$d);
  const [isloading, setisloading] = useState(false);

  const [slectedoption, setslectedoption] = useState([])
  useEffect(() => {
    let sub = false;
    if (!sub) {
      const value = {
        startDate: startDate,
        endDate: endDate,
      };
      dataGetDateWise(value);

    }
    return () => {
      sub = true;
    };
  }, []);

  const totalDepostit = walletData?.map(item => {
    return (
      item?.history.reduce(
        (acc, detail) => {
          if (detail?.deposit_or_withdraw === "deposit") {
            return acc + Number(detail?.amount);
          }
          return acc;
        },
        0
      )
    );
  }).reduce((acc, det) => acc + Number(det), 0);

  const totalWithdrow = walletData?.map(item => {
    return (
      item?.history.reduce(
        (acc, detail) => {
          if (detail?.deposit_or_withdraw !== "deposit") {
            return acc + Number(detail?.amount);
          }
          return acc;
        },
        0
      )
    );
  }).reduce((acc, det) => acc + Number(det), 0);



  const cutotal = walletData?.reduce(
    (acc, detail) => acc + Number(detail?.balance),
    0
  );


  const statement = useRef();
  const billingSM = useReactToPrint({
    content: () => statement.current,

  });

  const refresh = () => {
    const value = {
      startDate: dayjs().subtract(1, "month")?.$d,
      endDate: dayjs().add(2, "month")?.$d,
    };
    dataGetDateWise(value);
    setStartDate(value?.startDate);
    setEndDate(value?.endDate);
    setslectedoption([])
  };


  const dataGetDateWise = (value) => {
    setisloading(true);
    http
      .post("e-wallet-details", value)
      .then((res) => {
        console.log("data", res.data)
        setwalletData(res.data.data);
        setwalletDataMaster(res.data.data);
        setisloading(false);
      })
      .catch((err) => setisloading(false));
  };

  return (
    <div className="page-content adjustment-type-table">
      <div className="custom-card p-2 d-flex justify-content-between mb-2 align-items-center">
        <h6>Balance Statment</h6>
        <div>
          <button
            style={{ marginTop: "1px" }}
            onClick={billingSM}
            className="btn btn-sm btn-primary float-end ms-1"
          >
            <FiPrinter /> Print
          </button>
          <button
            style={{ marginTop: "1px" }}
            onClick={refresh}
            className="btn btn-sm btn-primary float-end ms-1"
          >
            <FiRefreshCcw /> Refresh
          </button>
        </div>
      </div>
      <div className="custom-card p-2 d-flex justify-content-between mb-2 align-items-center">
        <div className="d-flex gap-2">
          <DatePicker
            selected={startDate}
            placeholderText="Start Date"
            onChange={(date) => {
              setStartDate(date);
              const value = {
                startDate: date,
                endDate: endDate,
              };
              dataGetDateWise(value);
            }}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            dateFormat="dd/MM/yyyy"
            className="custom_date_picker_styles"
          />
          <p className="mt-2"> to </p>
          <DatePicker
            selected={endDate}
            placeholderText="End Date"
            onChange={(date) => {
              setEndDate(date);

              const value = {
                startDate: startDate,
                endDate: date,
              };
              dataGetDateWise(value);
            }}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            dateFormat="dd/MM/yyyy"
            className="custom_date_picker_styles"
          />
          <div className="col-lg-8">
            <Select
              isMulti
              value={slectedoption}
              styles={colourStyles}
              options={walletDataMaster?.map(item => ({ value: item, label: item?.owner?.name }))}
              onChange={(v) => {
                setslectedoption(v)
                setwalletData(v.map(d => d.value));
              }}
              isClearable={true}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>

        </div>
      </div>


      <div ref={statement} className="card">
        <div className="card-body bill-statment">
          {/* <StatmentHeading name={"Billing Statement"} /> */}
          <PrintStatmentHeader name={"Balance Statement"} start={startDate} end={endDate} />
          <div className="unit-manager-table invoice-item-table">
            <table>
              <tr style={{ background: "grey", color: "white" }}>
                <td style={{ borderRight: "none !important" }}>
                  Date
                </td>

                <td>Tran Id</td>
                <td>Name</td>
                <td>Deposit</td>
                <td>Withdrow</td>
                <td>Current Balanace</td>
              </tr>
              {isloading ? (
                <tr>
                  <td colSpan={12}>

                    <CircularProgress />
                  </td>
                </tr>
              ) : walletData?.length > 0 ? (
                walletData?.map((item, i) => {
                  return (
                    <>
                      <tr key={i}>
                        <td>
                          {/* {moment(item?.created_at).format(dateFormat)} */}
                        </td>

                        <td></td>
                        <td>{item?.owner?.name}</td>
                        <td></td>
                        <td></td>
                        <td></td>

                      </tr>
                      {item?.history.map((v, j) => {

                        const subT = item?.history
                          ?.slice(0, j + 1)
                          .reduce(
                            (acc, detail) => {
                              if (detail?.deposit_or_withdraw === "deposit") {
                                return acc + Number(detail?.amount);
                              } else {
                                return acc - Number(detail?.amount);
                              }
                            },
                            0
                          );

                        return (
                          <tr key={j}>
                            <td> {moment(v?.created_at).format(dateFormat)}</td>
                            <td> {v?.trun_id}</td>
                            <td> </td>
                            <td align="right"> {v?.deposit_or_withdraw === 'deposit' ? Number(v?.amount).toFixed(2) : ''}</td>
                            <td align="right"> {v?.deposit_or_withdraw !== 'deposit' ? Number(v?.amount).toFixed(2) : ''}</td>
                            <td align="right">{Number(subT).toFixed(2)}</td>
                          </tr>
                        );
                      })}

                    </>
                  );
                })
              ) : (
                <tr>
                  <td style={{ color: "red" }} colSpan={12}>

                    Records are not available
                  </td>
                </tr>
              )}
              <tr>
                <td colSpan="3">
                  <b>Total</b>
                </td>
                <td align="right">
                  <b>{currencyFormat(totalDepostit)}</b>
                </td>
                <td align="right">
                  <b>{currencyFormat(totalWithdrow)}</b>
                </td>
                <td align="right">
                  <b>{currencyFormat(cutotal)}</b>
                </td>
              </tr>
            </table>

            <div
              className="d-flex justify-content-between mt-1"
              style={{ fontWeight: "700", fontSize: "12px" }}
            >
              <div className="d-flex gap-3">
                <p> Total Deposit Amount : {currencyFormat(totalDepostit)}</p>
                <p style={{ color: "green" }}>

                  Total Withdroe Amount : {currencyFormat(totalWithdrow)}
                </p>
                <p style={{ color: "red" }}>

                  Total Balance : {currencyFormat(cutotal)}
                </p>
              </div>

              <p
                onClick={billingSM}
                className="print_icon"
                style={{ color: "#2b6afd", cursor: "pointer" }}
              >

                <FiPrinter /> Print
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
