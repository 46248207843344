import React from 'react'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useState, useEffect, useRef } from 'react';
import '../../../CSS/Invoice.css'
import http from '../../../http';
import Swal from 'sweetalert2';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from 'react-router-dom';
import Select from 'react-select'
import allCustomStyles from "../../../CSS/ReactModalStyle";
import Setting from "../../Setting/Setting";
import { LinearProgress } from '@mui/material';
export default function AddOwners() {
    const { colourStyles } = allCustomStyles;
    const [member, setMember] = useState();
    const [gender, setGender] = useState();
    const [spinner, setSpinner] = useState();
    const [random, setRandom] = useState();
    const navigate = useNavigate();
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [secondsRemaining, setSecondsRemaining] = useState(30);
    const [verfiyNum, setVerfiyNum] = useState([]);
    const [invalidMobileNum, setInvalidMobileNum] = useState(false);
    const [invalidMobileNumMess, setInvalidMobileNumMess] = useState();
    const [imagePreview, setImagePreview] = useState(null);
    const [picturePreview, setPicturePreview] = useState(null);
    const [activationMessage, setActivationMessage] = useState();
    const [ownership, setOwnership] = useState([]);
    const inputRef = useRef(null);
    const { dateFormat } = Setting;
    const [familyMember, setFamilyMember] = useState([
        {
            id: '',
            owner_id: '',
            u_id: '1',
            full_name: '',
            member_type: '',
            nationlity: '',
            address: '',
            email: '',
            emergency: '',
            blood_group: '',
            image: '',
            contact_no: '',
            gender_type: '',
        },
    ])

    const [property, setproperty] = useState([])
    const [propertyvalue, setpropertyvalue] = useState([])

    useEffect(() => {
        setSpinner(true)
        http.get(`owner-setup-data`)
            .then((res) => {
                setproperty(res.data.data.pD)
                setNationality(res.data.data.nA)
                setProfession(res.data.data.dG)
                setbloodGroup(res.data.data.bG)
                setOwnership(res?.data?.data.oS);
                setMember(res.data.data.mT);
                setGender(res.data.data.g);

                setSpinner(false);


            })




        // http.get(`nationality`)
        //     .then((res) => {

        //         if (res.status === 200) {
        //             setNationality(res.data.data)
        //             setSpinner(false);
        //         }

        //     })
        // http.get(`designations`)
        //     .then((res) => {

        //         if (res.status === 200) {
        //             setProfession(res.data.data)
        //             setSpinner(false);
        //         }

        //     })
        // http.get(`blood-group`)
        //     .then((res) => {

        //         if (res.status === 200) {
        //             setbloodGroup(res.data.data)
        //             setSpinner(false);
        //         }

        //     })
        // http
        //     .get(`ownerships-type`)
        //     .then((res) => {
        //         setOwnership(res?.data?.data);
        //         setSpinner(false);
        //     })
        //     .catch((err) => {
        //         console.log(err);
        //         setSpinner(false);
        //     });
        // http
        //     .get(`member-type`)
        //     .then((res) => {
        //         setMember(res.data);
        //         setSpinner(false);
        //     })
        //     .catch((err) => {
        //         console.log(err);
        //         setSpinner(false);
        //     });

        // http
        //     .get(`gender-type`)
        //     .then((res) => {
        //         setGender(res.data);
        //         setSpinner(false);
        //     })
        //     .catch((err) => {
        //         console.log(err);
        //         setSpinner(false);
        //     });

        // http
        //     .get(`get-otp-verification-number`)
        //     .then((res) => {
        //         setVerfiyNum(res.data.data);
        //         setSpinner(false);
        //     })
        //     .catch((err) => {
        //         console.log(err);
        //         setSpinner(false);
        //     });

        let countdown;

        if (isButtonDisabled) {
            countdown = setInterval(() => {
                setSecondsRemaining((prevSeconds) => {
                    if (prevSeconds === 0) {
                        clearInterval(countdown);
                        setIsButtonDisabled(false);
                        return 30;
                    } else {
                        return prevSeconds - 1;
                    }
                });
            }, 1000);
        }

        return () => {
            clearInterval(countdown);
        };
    }, [random, isButtonDisabled]);



    const [profession, setProfession] = useState([]);
    // project info 
    const [personalInfo, setPersonaInfo] = useState({
        name: '',
        email: '',
        phone_1: '',
        phone_2: '',
        mobile_1: '',
        mobile_2: '',
        profession_id: '',
        profession_name: '',
        ownership_type_id: '',
        ownership_type_name: '',
        father_name: '',
        mother_name: '',
        date_of_birth: new Date(),
        address_line_1: '',
        address_line_2: '',
        picture: ''
    })

    const [myWallet, setMyWallet] = useState({
        initial_deposit: '',
        deposit_by: '',
        activaiton_date: '',
        contuct_number: '',
        wallet_no: '',
        otp_num: ''
    })

    const handlePersonalInfoChange = (event) => {
        const { name, value } = event.target;
        setPersonaInfo({ ...personalInfo, [name]: value });
    }

    const handleNextPage = (id) => {
        document.getElementById(id).click();
    }
    const [errorMessage, setErrorMessage] = useState({
        name: '',
        address_line_1: '',
        profession_id: '',
        date_of_birth: '',
        father_name: '',
        mobile_1: '',
        ownership_type_id: '',
        email: ''
    });
    const [errorMessageFamily, setErrorMessageFamily] = useState({
        member_type: '',
        gender_type: '',
        contact_no: '',
        blood_group: '',
        full_name: '',
        nationlity: ''
    });

    const handle_Images_File = (event, index) => {
        if (event.target.files[0].size < 1000048) {
            const { files } = event.target;
            const existing = [...familyMember];
            existing[index]["image"] = files[0];
            setFamilyMember(existing);
        } else {
            event.target.value = '';
            alert('File size exceeds the limit (1MB)');
        }
    };
    const [isLoading, setisLoading] = useState(false)

    const handleSubmit = (e) => {
        e.preventDefault();
        setisLoading(true)
        if (!personalInfo?.name) {
            setErrorMessage({ name: 'Name field is required' })
            setisLoading(false)
            return false;
        } else if (!personalInfo?.ownership_type_id) {
            setErrorMessage({ ownership_type_id: 'Ownership name field is required' })
            setisLoading(false)
            return false;
        } else if (!personalInfo?.father_name) {
            setErrorMessage({ father_name: 'father name field is required' })
            setisLoading(false)
            return false;
        }
        else if (!personalInfo?.profession_id) {
            setErrorMessage({ profession_id: 'Profession field is required' })
            setisLoading(false)
            return false;
        }
        else if (!personalInfo?.mobile_1) {
            setErrorMessage({ mobile_1: 'Mobile 1 field is required' })
            setisLoading(false)
            return false;
        }
        else if (!personalInfo?.address_line_1) {
            setErrorMessage({ address_line_1: 'Address line 1 field is required' })
            setisLoading(false)
            return false;
        }
        const formData = new FormData();
        formData.append('name', personalInfo?.name)
        formData.append('email', personalInfo?.email)
        formData.append('phone_1', personalInfo?.phone_1)
        formData.append('phone_2', personalInfo?.phone_2)
        formData.append('mobile_1', personalInfo?.mobile_1)
        formData.append('mobile_2', personalInfo?.mobile_2)
        formData.append('profession_id', personalInfo?.profession_id)
        formData.append('ownership_type_id', personalInfo?.ownership_type_id)
        formData.append('ownership_type', personalInfo?.ownership_type)
        formData.append('father_name', personalInfo?.father_name)
        formData.append('mother_name', personalInfo?.mother_name)
        formData.append('date_of_birth', personalInfo?.date_of_birth)
        formData.append('address_line_1', personalInfo?.address_line_1)
        formData.append('address_line_2', personalInfo?.address_line_2)
        formData.append('picture', personalInfo?.picture)

        formData.append("property", JSON.stringify(propertyvalue));

        http.post(`owners`, formData)
            .then((res) => {
                console.log(res, 'res')
                setisLoading(false)
                if (res.status === 200) {
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Owner Added Successfully',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    document.getElementById('picture').value = ''
                    setPersonaInfo({
                        name: '',
                        email: '',
                        phone_1: '',
                        phone_2: '',
                        mobile_1: '',
                        mobile_2: '',
                        profession_id: '',
                        ownership_type_id: '',
                        father_name: '',
                        mother_name: '',
                        date_of_birth: new Date(),
                        address_line_1: '',
                        address_line_2: '',
                    })
                    familyMember.map((item, i) => {
                        const formDataFamily = new FormData();
                        formDataFamily.append("owner_id", res.data.owner_id.id);
                        formDataFamily.append("u_id", item.u_id);
                        formDataFamily.append("full_name", item.full_name);
                        formDataFamily.append("member_type", item.member_type);
                        formDataFamily.append("nationlity", item.nationlity);
                        formDataFamily.append("address", item.address);
                        formDataFamily.append("email", item.email);
                        formDataFamily.append("emergency", item.emergency);
                        formDataFamily.append("blood_group", item.blood_group);
                        formDataFamily.append("full_name", item.full_name);
                        formDataFamily.append("contact_no", item.contact_no);
                        formDataFamily.append("gender_type", item.gender_type);
                        formDataFamily.append("image", item.image);

                        http.post("owners-family-members-data-store", formDataFamily).then((res) => {
                        });
                    });
                    navigate(`${`/edit-owners/${res.data.owner_id.id}`}`)
                }

            })
            .catch((err) => {
                setisLoading(false)
                setErrorMessage({
                    ...errorMessage,
                    mobile_1: err?.response?.data?.errors?.mobile_1,
                    name: err.response.data.errors.name,
                    address_line_1: err?.response?.data?.errors?.address_line_1,
                    profession_id: err?.response?.data?.errors?.profession_id,
                    date_of_birth: err?.response?.data?.errors?.date_of_birth,
                    father_name: err?.response?.data?.errors?.father_name,
                    ownership_type_id: err?.response?.data?.errors?.ownership_type_id,
                    email: err?.response?.data?.errors?.email
                })

            })
    }

    const [nationlity, setNationality] = useState([])
    const [bloodGroup, setbloodGroup] = useState([]);

    const handleFamilyDetailsChange = (event, index) => {
        const { name, value } = event.target;
        const existing = [...familyMember];
        existing[index][name] = value;
        setFamilyMember(existing);
    }
    const handleMemberTypeChange = (event, index) => {
        const { id } = event;
        const existing = [...familyMember];
        existing[index]['member_type'] = id;
        setFamilyMember(existing);
    }

    const handleNationlityChange = (event, index) => {
        const { id } = event;
        const existing = [...familyMember];
        existing[index]['nationlity'] = id;
        setFamilyMember(existing);
    }
    const handleGenderChange = (event, index) => {
        const { id } = event;
        const existing = [...familyMember];
        existing[index]['gender_type'] = id;
        setFamilyMember(existing);
    }
    const handleBloodGroupChange = (event, index) => {
        const { id } = event;
        const existing = [...familyMember];
        existing[index]['blood_group'] = id;
        setFamilyMember(existing);
    }

    const handleAddFamilyDetails = () => {
        setFamilyMember([
            ...familyMember,
            {
                id: '',
                u_id: `${familyMember.length + 1}-${new Date().getTime()}`,
                full_name: '',
                member_type: '',
                nationlity: '',
                address: '',
                email: '',
                emergency: '',
                blood_group: '',
                image: '',
                contact_no: '',
                gender_type: '',
            },
        ]);
    }
    const deleteFamily = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                http.delete(`/delete-contact/${id}`).then(res => {
                    if (res.data.status === 200) {
                        setFamilyMember(familyMember.filter((item) => item.id !== id));
                    }
                });
                Swal.fire(
                    {
                        position: 'top-center',
                        icon: 'success',
                        title: 'Deleted!',
                        text: 'Your data has been deleted.',
                        timer: 2500
                    }

                )
            }
        })
    }
    const handleRemoveFamilyDetails = (id) => {
        setFamilyMember(familyMember.filter((item) => item.u_id !== id));
    }

    const handle_IPersonalmages_File = (event) => {
        const file = event.target.files[0];

        if (file.size < 1000048) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPicturePreview(reader.result);
            };
            reader.readAsDataURL(file);
            const existing = { ...personalInfo };
            existing["picture"] = file;
            setPersonaInfo(existing);
        } else {
            inputRef.current.value = '';
            Swal.fire(
                {
                    position: 'top-center',
                    icon: 'error',
                    title: 'Deleted!',
                    text: 'File size exceeds the limit (1MB).',
                    showConfirmButton: true,
                }

            )
            // alert('File size exceeds the limit (1MB)');
        }
    };

    const handleMyWalletChange = (event) => {

        const { name, value } = event.target;
        if (name === 'wallet_no') {
            const InvalidVerifyNumber = verfiyNum.find((item) => item.mobile_num == value);
            const invalidNumber = InvalidVerifyNumber ? true : false;
            setInvalidMobileNum(invalidNumber)
            if (invalidNumber === true) {
                setInvalidMobileNumMess('Already Registered')
            } else {
                setInvalidMobileNumMess('')
            }

        }

        setMyWallet(prevState => ({
            ...prevState,
            [name]: value,
        }));
    }


    const [otp, setOtp] = useState(0);
    const [resend, setResend] = useState(0);
    const [verified, setVerified] = useState(false);
    const [verifiedNumber, setVerifiedNumber] = useState();
    const [time, setTime] = useState(30);
    const [currentTime, setCurrentTime] = useState();


    useEffect(() => {
        const intervalId = setInterval(() => {
            if (time > 0) {
                setTime((previous) => previous - 1);
            }
        }, 1000);
        if (time === 0) {
            clearInterval(intervalId);
        }
        return () => clearInterval(intervalId);
    }, [time])

    const [walletErrorMessage, setWalletErrorMessage] = useState();
    const [nameErrorMessage, setNameErrorMessage] = useState();

    const handlePaymentVerification = () => {
        if (!myWallet?.wallet_no) {
            setWalletErrorMessage('Wallet no field is required')
            return false;
        }
        if (!personalInfo?.name) {
            setNameErrorMessage('Name field is required')
            return false;
        }
        setTime(30)
        setOtp(1);
        setResend(1);
        setIsButtonDisabled(true);
        setTimeout(() => {
            const paymentVerify = {
                walletNum: myWallet?.wallet_no,
                ownername: personalInfo?.name,
            }
            if (paymentVerify) {
                http.post('payment-verification', paymentVerify).then((res) => {
                    if (res?.data?.status === 200) {
                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: res?.data?.message,
                            showConfirmButton: false,
                            timer: 1500
                        })
                    } else if (res?.data?.status === 500) {
                        Swal.fire({
                            position: 'center',
                            icon: 'error',
                            title: res?.data?.message,
                            showConfirmButton: false,
                            timer: 1500
                        })
                        setOtp(0);
                    }
                })
                    .catch((err) => {

                    })
            }
        }, 2000);
    }
    const handleOtpVerification = (e) => {
        e.preventDefault()
        const otpVerify = {
            walletNum: myWallet?.wallet_no,
            ownername: personalInfo?.name,
            otp_num: myWallet?.otp_num
        }
        if (otpVerify) {
            http.post('otp-verification', otpVerify).then((res) => {
                if (res?.data?.status === 200) {
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: res?.data?.message,
                        showConfirmButton: false,
                        timer: 1500
                    })
                    setVerified(true)
                    setMyWallet({ wallet_no: res?.data?.data })
                    setOtp(0)
                } else if (res?.data?.status === 500) {
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: res?.data?.message,
                        showConfirmButton: false,
                        timer: 1500
                    })
                    setVerified(false)
                    setOtp(1)
                }
            })
                .catch((err) => {

                })
        }
    }

    console.log(personalInfo, 'profession')
    console.log(familyMember, 'familyMember')
    return (
        <div className="page-content bg-white">
            {spinner && <LinearProgress />}
            <div className="property-register ms-1">
                <div className="row">
                    <h3 className="text-center my-3">Owner/Tenant Information</h3>
                    <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Personal Info</button>
                        </li>

                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Family Member</button>
                        </li>
                        {/* <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">My Wallet</button>
                        </li> */}
                    </ul>
                    <div className="tab-content" id="pills-tabContent">
                        <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                            <form onSubmit={handleSubmit} >
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="row mb-2">
                                            <div className="col-3">
                                                <label>Full Name <span className="text-danger">*</span></label>
                                            </div>
                                            <div className="col-9">
                                                <input required type="text" onChange={handlePersonalInfoChange} value={personalInfo?.name} name='name' className="form-control form-control-sm" />
                                                {errorMessage?.name && <div className="text-danger"> {errorMessage?.name} </div>}
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-3">
                                                <label> Ownership Type <span className="text-danger">*</span></label>
                                            </div>
                                            <div className="col-9">
                                                <Autocomplete
                                                    required
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    options={ownership}
                                                    getOptionLabel={(option) => option?.name}
                                                    sx={{ width: '100%' }}
                                                    size='small'
                                                    onChange={(event, value) => {
                                                        // setPersonaInfo({ ...personalInfo, ownership_type_id: value?.id })

                                                        setPersonaInfo({ ...personalInfo, ownership_type_id: value?.id, ownership_type: value?.name })
                                                    }}
                                                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                    renderInput={(params) => <TextField {...params} label={personalInfo?.ownership_type_name} />}
                                                />
                                                {errorMessage?.ownership_type_id && <div className="text-danger"> {errorMessage?.ownership_type_id} </div>}
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-3">
                                                <label> Select Propertry
                                                    {/* <span className="text-danger">*</span> */}
                                                </label>
                                            </div>
                                            <div className="col-9">
                                                <Autocomplete
                                                    size="small"
                                                    multiple
                                                    id="combo-box-demo"
                                                    options={property}
                                                    getOptionLabel={(option) => option?.property_name}
                                                    sx={{ width: '100%' }}
                                                    onChange={(event, value) => {
                                                        setpropertyvalue(value)

                                                    }}
                                                    renderInput={(params) => <TextField {...params} label={personalInfo?.ownership_type_name} />}
                                                />



                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-3">
                                                <label> Father Name <span className="text-danger">*</span></label>
                                            </div>
                                            <div className="col-9">
                                                <input required type="text" onChange={handlePersonalInfoChange} value={personalInfo?.father_name} name='father_name' className="form-control form-control-sm" />
                                                {errorMessage?.father_name && <div className="text-danger"> {errorMessage?.father_name} </div>}
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-3">
                                                <label> Phone 1</label>
                                            </div>
                                            <div className="col-9">
                                                <input type="number" onChange={handlePersonalInfoChange} value={personalInfo?.phone_1} name='phone_1' className="form-control form-control-sm" />
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-3">
                                                <label> Mobile 1 <span className="text-danger">*</span></label>
                                            </div>
                                            <div className="col-9">
                                                <input required type="number" onChange={handlePersonalInfoChange} value={personalInfo?.mobile_1} name='mobile_1' className="form-control form-control-sm" />
                                                {errorMessage?.mobile_1 && <div className="text-danger"> {errorMessage?.mobile_1} </div>}
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-3">
                                                <label> Email <span className="text-danger">*</span></label>
                                            </div>
                                            <div className="col-9">
                                                <input required type="email" onChange={handlePersonalInfoChange} value={personalInfo?.email} name='email' className="form-control form-control-sm" />
                                                {errorMessage?.email && <div className="text-danger"> {errorMessage?.email} </div>}
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-3">
                                                <label htmlFor="address1">Address 1 <span className="text-danger">*</span></label>
                                            </div>
                                            <div className="col-9">
                                                <textarea required name="address_line_1" onChange={handlePersonalInfoChange} value={personalInfo?.address_line_1} className="form-control form-control-sm" id="" cols="30" rows="3"></textarea>
                                                {errorMessage?.address_line_1 && <div className="text-danger"> {errorMessage?.address_line_1} </div>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row mb-2">
                                            <div className="col-3">
                                                <label className=''>Date of Birth <span className="text-danger">*</span></label>
                                            </div>
                                            <div className="col-9">
                                                <DatePicker
                                                    required
                                                    // format={dateFormat}
                                                    selected={personalInfo?.date_of_birth ? new Date(personalInfo?.date_of_birth) : new Date()}
                                                    dateFormat="dd/MM/yyyy"
                                                    className="form-control form-control-sm"
                                                    onChange={(date) => setPersonaInfo({ ...personalInfo, date_of_birth: date })} />
                                                {errorMessage?.date_of_birth && <div className="text-danger"> {errorMessage?.date_of_birth} </div>}

                                            </div>
                                        </div>

                                        <div className="row mb-2">
                                            <div className="col-3">
                                                <label> Profession <span className="text-danger">*</span></label>
                                            </div>
                                            <div className="col-9">
                                                <Autocomplete
                                                    required
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    options={profession}
                                                    getOptionLabel={(option) => option.name}
                                                    sx={{ width: '100%' }}
                                                    size='small'
                                                    onChange={(event, value) => {
                                                        setPersonaInfo({ ...personalInfo, profession_id: value.id })
                                                    }}
                                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                                    renderInput={(params) => <TextField {...params} label={personalInfo?.profession_name} />}
                                                />
                                                {errorMessage?.profession_id && <div className="text-danger"> {errorMessage?.profession_id} </div>}
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-3">
                                                <label> Mother's Name <span className="text-danger">*</span></label>
                                            </div>
                                            <div className="col-9">
                                                <input required type="text" onChange={handlePersonalInfoChange} value={personalInfo?.mother_name} name='mother_name' className="form-control form-control-sm" />
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-3">
                                                <label> Phone 2</label>
                                            </div>
                                            <div className="col-9">
                                                <input type="tel" onChange={handlePersonalInfoChange} value={personalInfo?.phone_2} name='phone_2' className="form-control form-control-sm" />
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-3">
                                                <label> Mobile 2</label>
                                            </div>
                                            <div className="col-9">
                                                <input type="tel" onChange={handlePersonalInfoChange} value={personalInfo?.mobile_2} name='mobile_2' className="form-control form-control-sm" />
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-3">
                                                <label htmlFor="address1">Address 2</label>
                                            </div>
                                            <div className="col-9">
                                                <textarea name="address_line_2" onChange={handlePersonalInfoChange} value={personalInfo?.address_line_2} className="form-control form-control-sm" id="" cols="30" rows="3"></textarea>
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-3">
                                                <label> Picture</label>
                                            </div>
                                            <div className="col-9">
                                                <input type="file" id='picture' ref={inputRef} onChange={(e) => handle_IPersonalmages_File(e)} accept='image/*' name='picture' className="form-control form-control-sm" />

                                            </div>
                                        </div>
                                        <div className='row mb-2'>
                                            <div className="col-3">

                                            </div>
                                            <div className="col-9">
                                                {picturePreview === null ?
                                                    <></> : <img src={picturePreview} alt="Live Preview" style={{ maxWidth: '100%', height: 'auto' }} width="120" />
                                                }
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-12">
                                        <div className="d-flex justify-content-end">
                                            <button type='button' onClick={() => navigate('/owners')} className="btn btn-sm btn-primary me-1 mt-2">Back</button>
                                            <button type='button' onClick={() => handleNextPage('pills-contact-tab')} className="btn btn-sm btn-primary me-1 mt-2">Next</button>
                                            {
                                                isLoading ?
                                                    <button className="btn mt-2 btn-sm btn-info float-end text-uppercase" type="button"> saving ...</button>
                                                    :
                                                    <button type='submit' className="btn btn-sm btn-primary mt-2">Save</button>

                                            }
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                            <form onSubmit={handleSubmit}>
                                {
                                    familyMember.map((item, i) => {
                                        return <div key={item.u_id} className="row mb-2">
                                            <div className="col-md-6">
                                                <div className="row mb-2">
                                                    <div className="col-3">
                                                        <label>Member type<span className="text-danger">*</span></label>
                                                    </div>
                                                    <div className="col-9">
                                                        <Select required className="basic-single" styles={colourStyles}
                                                            onChange={(e) => handleMemberTypeChange(e, i)}
                                                            options={member} value={member?.filter((e) => e.id == item.member_type)} getOptionLabel={(option) => option.member_type} />
                                                        {errorMessageFamily?.member_type && <div className="text-danger"> {errorMessageFamily?.member_type} </div>}
                                                    </div>
                                                </div>

                                                <div className="row mb-2">
                                                    <div className="col-3">
                                                        <label>Gender<span className="text-danger">*</span></label>
                                                    </div>
                                                    <div className="col-9">
                                                        <Select required className="basic-single" styles={colourStyles}
                                                            onChange={(e) => handleGenderChange(e, i)}
                                                            options={gender} value={gender?.filter((e) => e.id == item?.gender_type)} getOptionLabel={(option) => option?.gender_type} />
                                                        {errorMessageFamily?.gender_type && <div className="text-danger"> {errorMessageFamily?.gender_type} </div>}
                                                    </div>
                                                </div>

                                                <div className="row mb-2">
                                                    <div className="col-3">
                                                        <label> Contact No<span className="text-danger">*</span></label>
                                                    </div>
                                                    <div className="col-9">
                                                        <input required type="number" onChange={(e) => handleFamilyDetailsChange(e, i)} value={item?.contact_no} name='contact_no' className="form-control form-control-sm" />
                                                    </div>
                                                </div>

                                                <div className="row mb-2">
                                                    <div className="col-3">
                                                        <label> Email</label>
                                                    </div>
                                                    <div className="col-9">
                                                        <input type="text" value={item?.email == "null" ? '' : item?.email} onChange={(e) => handleFamilyDetailsChange(e, i)} name='email' className="form-control form-control-sm" />
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <div className="col-3">
                                                        <label>Blood Group<span className="text-danger">*</span></label>
                                                    </div>
                                                    <div className="col-9">
                                                        <Select
                                                            required
                                                            className="basic-single"
                                                            styles={colourStyles}
                                                            onChange={(e) => handleBloodGroupChange(e, i)}
                                                            options={bloodGroup}
                                                            value={bloodGroup?.find((e) => e.name == item?.blood_group)}
                                                            getOptionLabel={(option) => option?.name}
                                                        />
                                                        {errorMessageFamily?.blood_group && <div className="text-danger"> {errorMessageFamily?.blood_group} </div>}
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <div className="col-3 mb-1">
                                                        <label htmlFor='image'> Profile Image </label>
                                                    </div>
                                                    <div className="col-9">
                                                        <input type="file" onChange={(e) => handle_Images_File(e, i)} id='image' name='image' className="form-control form-control-sm mb-2" />
                                                    </div>

                                                </div>
                                                <div className="row mb-2">
                                                    <div className="col-3 mb-1">

                                                    </div>
                                                    <div className="col-9">
                                                        {item?.image ?
                                                            <> <img src={URL.createObjectURL(item.image)} alt="Live Preview" style={{ maxWidth: '100%', height: 'auto' }} width="120" /></> : <></>
                                                        }

                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="row mb-2">
                                                    <div className="col-3">
                                                        <label> Name<span className="text-danger">*</span></label>
                                                    </div>
                                                    <div className="col-9">
                                                        <input required type="text" onChange={(e) => handleFamilyDetailsChange(e, i)} value={item?.full_name} name='full_name' className="form-control form-control-sm" />
                                                        {errorMessageFamily?.full_name && <div className="text-danger"> {errorMessageFamily?.full_name} </div>}
                                                    </div>
                                                </div>

                                                <div className="row mb-2">
                                                    <div className="col-3">
                                                        <label>Nationality<span className="text-danger">*</span></label>
                                                    </div>
                                                    <div className="col-9">
                                                        <Select required className="basic-single" name='nationlity' styles={colourStyles}
                                                            onChange={(e) => handleNationlityChange(e, i)}
                                                            options={nationlity} value={nationlity?.find((e) => e.id === item?.nationlity)} getOptionLabel={(option) => option.name} />
                                                        {errorMessageFamily?.nationlity && <div className="text-danger"> {errorMessageFamily?.nationlity} </div>}
                                                    </div>
                                                </div>

                                                <div className="row mb-2">
                                                    <div className="col-3">
                                                        <label> Emergency</label>
                                                    </div>
                                                    <div className="col-9">
                                                        <input type="tel" value={item?.emergency} onChange={(e) => handleFamilyDetailsChange(e, i)} name='emergency' className="form-control form-control-sm" />
                                                    </div>
                                                </div>

                                                <div className="row mb-2">
                                                    <div className="col-3">
                                                        <label> Address</label>
                                                    </div>
                                                    <div className="col-9">
                                                        <textarea type="text" value={item?.address} onChange={(e) => handleFamilyDetailsChange(e, i)} name='address' className="form-control form-control-sm" ></textarea>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="col-12">
                                                <div className="d-flex justify-content-end">

                                                    {
                                                        familyMember?.length - 1 === i && (
                                                            <button onClick={handleAddFamilyDetails} className="btn btn-sm btn-primary me-3 mt-3">Add</button>
                                                        )}
                                                    {
                                                        familyMember?.length !== 1 && (
                                                            item.id ?
                                                                <button onClick={() => deleteFamily(item.id)} className="btn btn-sm btn-primary me-3 mt-3">Remove</button>
                                                                :
                                                                <button onClick={() => handleRemoveFamilyDetails(item.u_id, i)} className="btn btn-sm btn-primary me-3 mt-3">Remove</button>
                                                        )}
                                                </div>
                                            </div>

                                        </div>
                                    })
                                }
                                <div className="col-12">
                                    <div className="d-flex justify-content-end">
                                        <button type='button' onClick={() => navigate('/owners')} className="btn btn-sm btn-primary me-1 mt-2">Back</button>
                                        <button type="button" onClick={() => handleNextPage('pills-home-tab')} className="btn btn-sm btn-primary me-1 mt-2">Previous</button>
                                        <button type="button" onClick={() => handleNextPage('pills-profile-tab')} className="btn btn-sm btn-primary me-1 mt-2">Next</button>
                                        {
                                            isLoading ?
                                                <button className="btn mt-2 btn-sm btn-info float-end text-uppercase mt-2" type="button"> saving ...</button>
                                                :
                                                <button type='submit' className="btn btn-sm btn-primary  mt-2">Save</button>

                                        }
                                    </div>

                                </div>
                            </form>
                        </div>
                        {/* <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                            <div className="row mb-2">
                                <div className="col-md-12">
                                    <div className="row mb-2 text-align-left">
                                        <div className='col-md-6'>
                                            <div className='row'>
                                                <div className="col-3">
                                                    <label> Owner Name</label>
                                                </div>
                                                <div className="col-9">
                                                    {personalInfo?.name}
                                                    {nameErrorMessage && <div className="text-danger"> {nameErrorMessage} </div>}
                                                </div>
                                            </div>
                                        </div>
                                    

                                    </div>

                                </div>
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="row mb-2">
                                                <div className="col-3">
                                                    <label> Wallet No</label>
                                                </div>
                                                <div className="col-6">
                                                    {verified == true ? (
                                                        <input type="number" onChange={(e) => handleMyWalletChange(e)} value={myWallet?.wallet_no} name='wallet_no' className="form-control form-control-sm" required disabled />
                                                    ) : (
                                                        <>
                                                            {resend === 0 ? (
                                                                <>
                                                                    <input type="number" onChange={(e) => handleMyWalletChange(e)} value={myWallet?.wallet_no} name='wallet_no' className={`form-control form-control-sm ` + `${invalidMobileNum === true ? 'is-invalid' : ''}`} required />
                                                                    {invalidMobileNumMess && <div className="text-danger d-inline-block"> {invalidMobileNumMess} </div>}
                                                                    <p>Verify your wallet number  <span class="badge text-bg-secondary bg-success cursor-pointer" type="submit" onClick={handlePaymentVerification}> Send OTP </span></p>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {time == 0 ? (
                                                                        <>
                                                                            <input type="number" onChange={(e) => handleMyWalletChange(e)} value={myWallet?.wallet_no} name='wallet_no' className={`form-control form-control-sm ` + `${invalidMobileNum === true ? 'is-invalid' : ''}`} required />
                                                                            {invalidMobileNumMess && <div className="text-danger d-inline-block"> {invalidMobileNumMess} </div>}
                                                                            <p>Verify your wallet number  <span class="badge text-bg-secondary bg-success cursor-pointer" type="submit" onClick={handlePaymentVerification}> Resend OTP</span></p>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <input type="number" onChange={(e) => handleMyWalletChange(e)} value={myWallet?.wallet_no} name='wallet_no' className={`form-control form-control-sm ` + `${invalidMobileNum === true ? 'is-invalid' : ''}`} required />
                                                                            {invalidMobileNumMess && <div className="text-danger d-inline-block"> {invalidMobileNumMess} </div>}
                                                                            <p>Verify your wallet number  <span class="badge text-bg-secondary bg-success cursor-pointer" > Resend OTP {time}</span></p>
                                                                        </>
                                                                    )}

                                                                </>
                                                            )}
                                                        </>
                                                    )}

                                                </div>
                                                <div className="col-md-3">
                                                    {verified === true ? (
                                                        <button disabled className="btn btn-sm btn-success me-3 px-3" style={{ margin: "0" }} >  {verified === true ? <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><path fill="#ffffff" d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" /></svg> : <i class="fa-solid fa-check d-none"></i>} verified</button>
                                                    ) : (
                                                        <button disabled className="btn btn-sm btn-primary me-3 px-3" style={{ margin: "0" }} >  {verified === true ? <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><path fill="#ffffff" d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" /></svg> : <i class="fa-solid fa-check d-none"></i>} Unverified</button>
                                                    )}




                                                </div>
                                            </div>
                                            {otp === 1 && (
                                                <div className="row mb-2">
                                                    <div className="col-3">
                                                    </div>
                                                    <div className="col-6">
                                                        <input type="number" onChange={(e) => handleMyWalletChange(e)} value={myWallet?.otp_num} name='otp_num' className="form-control form-control-sm" required placeholder='Verfiy your OTP' />
                                                        <span className='text-success'>Verification has been sent to your registered mobile</span>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <button type="button" onClick={handleOtpVerification} className="btn btn-sm btn-primary me-3" style={{ margin: "0" }}>Submit</button>
                                                    </div>
                                                </div>
                                            )}

                                            <div className="row mb-2">
                                                <div className="col-3">
                                                    <label> Initial Deposit</label>
                                                </div>
                                                <div className="col-9">
                                                    <input type="number" disabled onChange={(e) => handleMyWalletChange(e)} value={myWallet?.initial_deposit} name='initial_deposit' className="form-control form-control-sm" />
                                                </div>
                                            </div>

                                            <div className="row mb-2">
                                                <div className="col-3">
                                                    <label>Deposit by</label>
                                                </div>
                                                <div className="col-9">
                                                    <input type="text" disabled onChange={(e) => handleMyWalletChange(e)} name='deposit_by' className="form-control form-control-sm" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="row mb-2">
                                                <div className="col-3">
                                                    <label>Activaiton Date</label>
                                                </div>
                                                <div className="col-9">
                                                    <DatePicker
                                                        selected={myWallet?.activaiton_date ? new Date(myWallet?.activaiton_date) : new Date()}
                                                        dateFormat="dd/MM/yyyy"
                                                        disabled
                                                        className="form-control form-control-sm"
                                                        onChange={(date) => setMyWallet({ ...myWallet, activaiton_date: date })} />
                                                    {activationMessage && <div className="text-danger"> {activationMessage} </div>}
                                                </div>
                                            </div>

                                            <div className="row mb-2">
                                                <div className="col-3">
                                                    <label>Contact Number</label>
                                                </div>
                                                <div className="col-9">
                                                    <input type="tel" onChange={(e) => handleMyWalletChange(e)} name='contuct_number' className="form-control form-control-sm" disabled />
                                                </div>
                                            </div>

                                            <div className="col-12">
                                                <div className="d-flex justify-content-end">
                                                    <button type='button' onClick={() => navigate('/owners')} className="btn btn-sm btn-primary me-1 mt-2">Back</button>

                                                    <button onClick={() => handleNextPage('pills-contact-tab')} className="btn btn-sm btn-primary me-1 mt-2">Previous</button>
                                                    {
                                                        isLoading ?
                                                            <button className="btn mt-2 btn-sm btn-info float-end text-uppercase" type="button"> saving ...</button>
                                                            :
                                                            <button type='submit' className="btn btn-sm btn-primary mt-2">Save</button>

                                                    }
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </form>


                                <div className="col-md-12">
                                    <div className='mb-4'>
                                        <div className="row mt-3">
                                            <h6 className='mb-3 text-center'>History / ledger</h6>
                                            <div className="col-4">
                                                <div className="row mb-2">
                                                    <div className="col-4">
                                                        <label> Total Unit / Floor</label>
                                                    </div>
                                                    <div className="col-8">

                                                    </div>
                                                </div>

                                            </div>
                                            <div className="col-4">

                                            </div>
                                            <div className="col-1">

                                            </div>
                                        </div>

                                        <div className="unit-manager-table invoice-item-table mb-3">
                                            <table className=''>
                                                <tr>
                                                    <td>Date</td>
                                                    <td>Deposit Ammount</td>
                                                    <td>Total Paid</td>
                                                    <td>Available Ammount</td>
                                                    <td>Remarks</td>
                                                    <td>Details</td>
                                                    <td>Jan</td>
                                                    <td>Year</td>
                                                </tr>
                                                <tr>
                                                    <td>01/01/2023</td>
                                                    <td>5000</td>
                                                    <td width='10%'>
                                                        3000
                                                    </td>
                                                    <td width='10%'>
                                                        2000
                                                    </td>

                                                    <td className='' width='15%'>

                                                    </td>
                                                    <td className='' width='15%'>

                                                    </td>
                                                    <td className='' width='15%'>
                                                        Jan
                                                    </td>
                                                    <td width='10%'>
                                                        2024
                                                    </td>

                                                </tr>

                                            </table>
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div> */}
                    </div>

                </div>
            </div>
        </div>
    )
}
