import MaterialTable from "material-table";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Modal from "react-modal";
import Swal from "sweetalert2";
import http from "../../../http";
import { Link } from "react-router-dom";
import avatar from "../../../front_assets/images/avatar.png";
export default function Owners() {
  const deleteRowData = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result?.isConfirmed) {
        http
          .delete(`owners/${id}`)
          .then((res) => {
            setUpdate(!update);
            Swal.fire({
              position: "top-center",
              icon: "success",
              title: "Success !",
              text: "Owner Deleted Successfully",
              showConfirmButton: false,
              timer: 1500,
            });
          })
          .catch((error) => {
            // Handle error response
            console.error("Error:", error.response.data.error);
            Swal.fire({
              position: "top-center",
              icon: "error",
              title: "Error!",
              text: error.response.data.error,
              showConfirmButton: false,
              timer: 1500,
            });
          });
      }
    });
  };

  const columns = [
    {
      title: "SL",
      field: "",
      render: (row) => <div>{row.tableData.id + 1}</div>,
      width: "20 !important",
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Image",
      field: "content",
      render: (row) => (
        <div>
          {row.picture != "null" &&
            row.picture != "" &&
            row.picture !== null ? (
            <img
              src={`${global.img_Url}/images/${row?.picture}`}
              width="50"
              height="50"
              alt="Profile"
            />
          ) : (
            <img src={avatar} width="50" height="50" alt="Profile" />
          )}
        </div>
      ),
      cellStyle: {
        textAlign: "center",
      },
    },

    {
      title: "Name",
      field: `name`,
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Mobile",
      field: `mobile_1`,
      cellStyle: {
        textAlign: "center",
      },
    },

    {
      title: "Email",
      field: `email`,
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Ownership Type",
      field: `owner_ships_type.name`,
      // render: (row) => <div>{row?.owner_ships_type?.name}</div>,
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Wallet Number",
      field: `wallet_number`,
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      title: "E Wallet Balance",
      field: `e_wallet.balance`,
      // render: (row) => <div>{row?.owner_ships_type?.name}</div>,
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Projects",
      field: ``,
      render: (row) => <div>{row?.property?.map(v => v?.project?.property_name).join()}</div>,
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Action",
      field: "patient",
      render: (row) => (
        <div>
          <Link to={`/edit-owners/${row.id}`}>
            <button className="btn btn-sm action-btn">
              <i className="far fa-edit"></i>
            </button>
          </Link>
          <button
            onClick={() => deleteRowData(row.id)}
            className="btn btn-sm action-btn"
          >
            <i className="far fa-trash"></i>
          </button>
        </div>
      ),
      cellStyle: {
        textAlign: "center",
      },
    },
  ];
  const [data, setData] = useState([]);
  const [update, setUpdate] = useState(false);
  const [spinner, setSpinner] = useState(false);
  useEffect(() => {
    const controller = new AbortController();
    setSpinner(true);
    http
      .get(`owners`)
      .then((res) => {
        console.log("data", res.data)
        setData(res.data);
        setSpinner(false);
      })
      .catch((err) => {
        console.log(err);
        setSpinner(false);
      });

    return () => {
      controller.abort();
    };
  }, [update]);

  return (
    <div className="page-content adjustment-type-table">
      <div className="custom-card p-2 d-flex justify-content-between mb-2 align-items-center">
        <h6>Owners/Tenants</h6>
        <div>
          {/* <Link to="/add-owners">
            <button
              style={{ marginTop: "1px" }}
              className="btn btn-sm btn-primary float-end"
            >
              Add
            </button>
          </Link> */}
        </div>
      </div>

      <MaterialTable
        columns={columns}
        data={data}
        isLoading={spinner}
        options={{
          search: true,
          showTitle: false,
          searchFieldAlignment: "left",
          pageSize: 10,
          emptyRowsWhenPaging: false,
          pageSizeOptions: [5, 10, 20, 50, 100],
        }}
      />
    </div>
  );
}
