import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MaterialTable from "material-table";
import Modal from "react-modal";
import "../../../CSS/MemberSetup.css";
import http from '../../../http';
import Swal from 'sweetalert2';
import allCustomStyles from '../../../CSS/ReactModalStyle';
const RentSetup = () => {
    const [random, setRandom] = useState();
    const [spinner, setSpinner] = useState(false);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [type, setType] = useState();
    const [price, setprice] = useState();
    const [updateButton, setUpdateButton] = useState(false);
    const [id, setId] = useState();
    const [error, setError] = useState();
    const { serviceModal } = allCustomStyles;
    function openModal() {
        setType()
        setIsOpen(true);
    }
    function closeModal() {
        clearState()
        setIsOpen(false);
        setUpdateButton(false)
        setRandom(Math.random())
    }
    const [data, setData] = useState([]);

    useEffect(() => {
        setSpinner(true);
        http
            .get(`rent-setup`)
            .then((res) => {
                setData(res.data.data);
                setSpinner(false);
            })
            .catch((err) => {
                console.log(err);
                setSpinner(false);
            });
    }, [random]);

    const [isLoading, setisLoading] = useState(false)


    const clearState = () => {
        setType()
        setprice()
        setError()
        setSpinner()
        setId()
        setUpdateButton(false)
    }


    const handleSubmit = (event) => {
        event.preventDefault();
        setisLoading(true)

        if (id) {
            http
                .put(`/rent-setup/${id}`, {
                    type, price
                }).then((res) => {
                    setisLoading(false)
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Data Updated Successfully',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    closeModal()
                }).catch((err) => {
                    console.log(err);
                    setSpinner(false);
                    setisLoading(false)
                    errorSet(err)
                });
        } else {
            http
                .post("/rent-setup", {
                    type, price
                }).then((res) => {
                    setisLoading(false)
                    console.log('res', res)
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: res?.data.message,
                        showConfirmButton: false,
                        timer: 1500
                    })
                    closeModal()
                }).catch((err) => {
                    setisLoading(false)
                    setSpinner(false);
                    errorSet(err)

                });
        }
    }

    const errorSet = (err) => {
        if (err.response.data.errors.type != undefined) {
            setError({
                type: err.response.data.errors.type[0]
            })

        }
        if (err.response.data.errors.price != undefined) {
            setError({
                price: err.response.data.errors.price[0]
            })

        }
    }
    const updateMemberType = (id) => {

        const record = data.find((item) => item.id == id)

        setId(record.id)
        setType(record.type)
        setprice(record.price)

        setUpdateButton(true)
        setIsOpen(true);

    }
    const deleteMemberType = (id) => {

        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                http
                    .delete(`/rent-setup/${id}`).then((res) => {
                        Swal.fire({
                            title: "Deleted!",
                            text: "Your file has been deleted.",
                            icon: "success"
                        });
                        setRandom(Math.random())
                        setIsOpen(false);
                    }).catch((err) => {
                        console.log("err sadf", err.response);
                        setSpinner(false);
                        setError(err.response.data.message)
                    });

            }
        });

    }

    const columns = [
        {
            title: "SL",
            field: "",
            render: (row) => <div>{row.tableData.id + 1}</div>,
            width: "20 !important",
            cellStyle: {
                textAlign: "center",
            },
        },

        {
            title: "Type",
            field: `type`,
            cellStyle: {
                textAlign: "center",
            },
        },
        {
            title: "Price",
            field: `price`,
            cellStyle: {
                textAlign: "center",
            },
        },

        {
            title: "Action",
            field: "patient",
            render: (row) => (
                <div>
                    <button className="btn btn-sm action-btn" onClick={() => updateMemberType(row?.id)}>
                        <i className="far fa-edit"></i>
                    </button>
                    <button className="btn btn-sm action-btn" onClick={() => deleteMemberType(row?.id)}>
                        <i className="far fa-trash"></i>
                    </button>
                </div>
            ),
            cellStyle: {
                textAlign: "center",
            },
        },
    ];
    //  

    return (
        <div className="page-content adjustment-type-table">
            <div className="custom-card p-2 d-flex justify-content-between mb-2 align-items-center">
                <h6>Rent Setup</h6>
                <div>
                    <button
                        style={{ marginTop: "1px" }}
                        className="btn btn-sm btn-primary float-end"
                        onClick={openModal}
                    >
                        Add
                    </button>
                </div>
            </div>
            <MaterialTable
                columns={columns}
                data={data}
                isLoading={spinner}
                options={{
                    search: true,
                    showTitle: false,
                    searchFieldAlignment: "left",
                    pageSize: 10,
                    emptyRowsWhenPaging: false,
                    pageSizeOptions: [5, 10, 20, 50, 100],
                }}
            />
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={serviceModal}
                contentLabel="Example Modal"
            >
                <div className="product_modal">
                    <div className="page-content">
                        <div className=" patients-head ">
                            <h5 className="fw-normal custom_py-3 px-2  text-start mb-2 card-name">
                                {updateButton ? "Edit" : "Add"}
                                <span style={{ cursor: "pointer", fontSize: "16px" }} onClick={closeModal} className='float-end'><i className="fal fa-times"></i></span>
                            </h5>
                        </div>
                        <div className=" p-3">
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label htmlFor="type" className="mb-1">
                                        Type
                                    </label>
                                    <input
                                        required
                                        className="form-control"
                                        id="type"
                                        placeholder="Hourly, Daily, Weekly, Monthly etc"
                                        name="type"
                                        value={type}
                                        onChange={(event) => setType(event.target.value)}
                                    />
                                    {error?.type && <div className="text-danger">{error?.type}</div>}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="type" className="mb-1">
                                        Price
                                    </label>
                                    <input
                                        type="number"
                                        required
                                        className="form-control"
                                        id="type"
                                        name="price"
                                        placeholder="1000.00"
                                        value={price}
                                        onChange={(event) => setprice(event.target.value)}
                                    />
                                    {error?.price && <div className="text-danger">{error?.price}</div>}
                                </div>
                                {isLoading ?
                                    <button className="btn mt-2 btn-sm btn-info float-end text-uppercase" type="button"> saving ...</button>
                                    :
                                    updateButton ? (
                                        <button className="btn mt-2 btn-sm btn-success float-end text-uppercase" type="submit"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-save mb-1"><path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path><polyline points="17 21 17 13 7 13 7 21"></polyline><polyline points="7 3 7 8 15 8"></polyline></svg> Update</button>
                                    ) : (
                                        <button className="btn mt-2 btn-sm btn-primary float-end text-uppercase" type="submit"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-save mb-1"><path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path><polyline points="17 21 17 13 7 13 7 21"></polyline><polyline points="7 3 7 8 15 8"></polyline></svg> Save</button>
                                    )}
                            </form>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default RentSetup;
