import React from 'react'
// import logo from './../../../front_assets/Logo_Image/logo.jpg'
import logo from '../../front_assets/Logo_Image/logo.jpg'

export default function StatmentHeading({ name = "Heading" }) {
    return (
        <div className="statment-heading mb-1">
            <h4 className="text-uppercase">
                {name}
            </h4>

            <img src={logo} width="50" height="50" alt="logo" />
        </div>
    )
}
