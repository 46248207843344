
import MaterialTable from 'material-table'
import React, { Fragment } from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import Modal from 'react-modal';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Swal from 'sweetalert2';
import http from '../../../http';
import moment from 'moment';
import allCustomStyles from '../../../CSS/ReactModalStyle';

export default function District() {
    const [updateButton, setUpdateButton] = useState(false);
    const { SetupModal } = allCustomStyles;
    const [error, setError] = useState({
        divisions: ''
    });
    const addData = () => {
        if (error?.divisions) {
            setError({ divisions: '' })
        }
        setIsOpen(true);
    }
    const [modalIsOpen, setIsOpen] = useState(false);
    const [inputData, setInputData] = useState({
        id: '', name: "", division_id: '', division_name: ''
    })
    const deleteRowData = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        })
            .then((result) => {
                if (result.isConfirmed) {
                    http.delete(`district/${id}`)
                        .then((res) => {


                            if (res?.data?.status == 500) {
                                Swal.fire({
                                    position: 'top-center',
                                    icon: 'error',
                                    title: 'Error !',
                                    text: res?.data?.message,
                                    showConfirmButton: false,
                                    timer: 1500
                                })
                            } else {
                                setUpdate(!update);
                                setIsOpen(false);
                                Swal.fire({
                                    position: 'top-center',
                                    icon: 'success',
                                    title: 'Success !',
                                    text: 'Data Deleted Successfully',
                                    showConfirmButton: false,
                                    timer: 1500
                                })
                            }


                        })


                }
            })
    }
    const editData = (id) => {
        setIsOpen(true)
        setUpdateButton(true)
        if (error?.divisions) {
            setError({ divisions: '' })
        }
        http.get(`district/${id}/edit`)
            .then((res) => {
                console.log(res, 'Edit')
                setInputData({
                    ...inputData,
                    id: res?.data?.data?.id,
                    name: res?.data?.data?.name,
                    division_name: res?.data?.data?.division?.name,
                    division_id: res?.data?.data?.division_id,
                })
                setIsOpen(true);
            })
            .catch((err) => {
                console.log(err)
            })
    }
    const columns = [
        {
            title: "SL",
            field: "",
            render: (row) => <div>{row?.tableData?.id + 1}</div>,
            width: "20 !important",
            cellStyle: {
                textAlign: "center",
            },
        },

        {
            title: "Division",
            render: (row) => <div>{row?.division?.name}</div>,
            field: `division`,
            cellStyle: {
                textAlign: "center",
            },
        },

        {
            title: "Name",
            field: `name`,
            cellStyle: {
                textAlign: "center",
            },
        },

        {
            title: "Action",
            field: "patient",
            render: (row) => <div>{
                row?.deletable == 1 ? (
                    <></>
                ) : (
                    <>
                        <button
                            onClick={() => editData(row?.id)}
                            className="btn btn-sm action-btn"
                        >
                            <i className="far fa-edit"></i>
                        </button>

                        <button
                            onClick={() => deleteRowData(row?.id)
                            }
                            className="btn btn-sm action-btn"
                        >
                            <i className="far fa-trash"></i>
                        </button >
                    </>
                )
            }</div >,
            cellStyle: {
                textAlign: "center",
            },
        },
    ];

    const [data, setData] = useState([]);
    const [update, setUpdate] = useState(false);
    const [spinner, setSpinner] = useState(false);
    const [divisions, setDivision] = useState([]);
    useEffect(() => {
        const controller = new AbortController();
        setSpinner(true);
        http
            .get(`district`)
            .then((res) => {
                console.log(res, 'res')
                setData(res.data.data);
                setSpinner(false);
            })
            .catch((err) => {
                setSpinner(false);
                console.log(err);
            });

        http
            .get(`division`)
            .then((res) => {
                setDivision(res?.data?.data)
                setSpinner(false);
            })
            .catch((err) => {
                setSpinner(false);
                console.log(err);
            });


        return () => {
            controller.abort();
        };
    }, [update]);
    // add modal 


    const handleChange = (e) => {
        setInputData({ ...inputData, [e.target.name]: e.target.value });
    }
    const [isLoading, setisLoading] = useState(false)

    const submitData = (e) => {
        e.preventDefault()
        setisLoading(true)
        if (!inputData?.division_id) {
            setError({ divisions: 'Divisions field is required' })
            return false;
            setisLoading(false)
        }
        if (inputData.id) {
            http.put(`district/${inputData.id}`, inputData)
                .then((res) => {
                    setisLoading(false)
                    setUpdate(!update);
                    setIsOpen(false);
                    setInputData({ id: '', name: "", division_id: "", division_name: '' });
                    Swal.fire({
                        position: 'top-center',
                        icon: 'success',
                        title: 'Success !',
                        text: 'Data Updated Successfully',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    setUpdateButton(false)
                })
                .catch((err) => {
                    setisLoading(false)
                    console.log(err)
                    Swal.fire({
                        position: 'top-center',
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!',
                        showConfirmButton: false,
                        timer: 1500
                    })
                })
        } else {
            http.post('district', inputData)
                .then((res) => {
                    setisLoading(false)
                    setUpdate(!update);
                    setIsOpen(false);
                    setInputData({ id: '', name: "", division_id: "", division_name: '' });
                    Swal.fire({
                        position: 'top-center',
                        icon: 'success',
                        title: 'Success !',
                        text: 'Data Added Successfully',
                        showConfirmButton: false,
                        timer: 1500
                    })
                })
                .catch((err) => {
                    setisLoading(false)
                    console.log(err)
                    Swal.fire({
                        position: 'top-center',
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!',
                        showConfirmButton: false,
                        timer: 1500
                    })
                })
        }

    }
    const closeModal = () => {
        if (error?.divisions) {
            setError({ divisions: '' })
        }
        setUpdateButton(false)
        setIsOpen(false);
        setInputData({ id: '', name: "", division_id: "", division_name: '' });
    }
    return (
        <div className='page-content adjustment-type-table'>
            <div className="custom-card p-2 d-flex justify-content-between mb-2 align-items-center">
                <h6>District</h6>
                <div>
                    <button style={{ marginTop: "1px" }} onClick={addData} className='btn btn-sm btn-primary float-end'>Add</button>
                </div>
            </div>

            <MaterialTable
                columns={columns}
                data={data}
                isLoading={spinner}
                options={{
                    search: true,
                    showTitle: false,
                    searchFieldAlignment: "left",
                    pageSize: 10,
                    emptyRowsWhenPaging: false,
                    pageSizeOptions: [5, 10, 20, 50, 100],
                }}
            />

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={SetupModal}
                contentLabel="Example Modal"
            >
                <div className="product_modal">
                    <div className="page-content">
                        <div className=" patients-head ">
                            <h5 className="fw-normal custom_py-3 px-2  text-start mb-2 card-name">  {updateButton === true ? 'Edit' : "Add"}
                                <span style={{ cursor: "pointer", fontSize: "16px" }} onClick={closeModal} className='float-end'><i className="fal fa-times"></i></span>
                            </h5>
                        </div>

                        <div className=" p-3">
                            <form onSubmit={submitData}>
                                <div className="row mb-2">
                                    <div className="col-3">
                                        <label> Division <span className="text-danger">*</span></label>
                                    </div>
                                    <div className="col-9">
                                        <Autocomplete
                                            required
                                            disablePortal
                                            id="combo-box-demo"
                                            options={divisions}
                                            getOptionLabel={(option) => option?.name}
                                            sx={{ width: '100%' }}
                                            size='small'
                                            onChange={(event, value) => {
                                                setInputData({ ...inputData, division_id: value?.id })
                                            }}
                                            isOptionEqualToValue={(option, value) => option?.name === value?.name}
                                            renderInput={(params) => <TextField {...params} label={inputData?.division_name} required={inputData?.division_id?.length === 0} />}
                                        />
                                        {error.divisions && <div className='text-danger'>{error.divisions}</div>}
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-3">
                                        <label htmlFor="type" className="mb-1">
                                            Name
                                        </label>
                                    </div>
                                    <div className="col-9">
                                        <div>
                                            <input onChange={handleChange} value={inputData?.name} name="name" type="text" className="form-control form-control-sm my-2" required placeholder="Title" />
                                        </div>
                                    </div>
                                </div>
                                {
                                    isLoading ?
                                        <button className="btn mt-2 btn-sm btn-info float-end text-uppercase" type="button"> saving ...</button>
                                        :
                                        updateButton === true ? (
                                            <button className="btn mt-2 btn-sm btn-success float-end text-uppercase" type="submit"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-save mb-1"><path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path><polyline points="17 21 17 13 7 13 7 21"></polyline><polyline points="7 3 7 8 15 8"></polyline></svg> Update</button>
                                        ) : (
                                            <button className="btn mt-2 btn-sm btn-primary float-end text-uppercase" type="submit"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-save mb-1"><path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path><polyline points="17 21 17 13 7 13 7 21"></polyline><polyline points="7 3 7 8 15 8"></polyline></svg> Save</button>
                                        )}
                            </form>

                        </div>

                    </div>
                </div>
            </Modal>
        </div>
    )
}
