import MaterialTable from "material-table";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Modal from "react-modal";
import Swal from "sweetalert2";
import http from "../../../http";
import moment from "moment";
import { Link } from "react-router-dom";
import avatar from "../../../front_assets/images/avatar.png";

export default function Properties() {
  const columns = [
    {
      title: "SL",
      field: "",
      render: (row) => <div>{row.tableData.id + 1}</div>,
      width: "20 !important",
      cellStyle: {
        textAlign: "center",
      },
    },

    {
      title: "Logo",
      field: ``,
      render: (row) => (
        <div>
          {row.logo ? (
            <img
              src={`${global.img_Url}/images/${row?.logo}`}
              width="50"
              height="50"
              alt="Profile"
            />
          ) : (
            <img src={avatar} width="50" height="50" alt="Profile" />
          )}
        </div>
      ),
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Company",
      field: `company_name`,
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Property",
      field: `property_name`,
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Address",
      field: `address_line_1`,
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Building",
      field: ``,
      render: (v) => <>{v.building.length}</>,
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Floor",
      field: ``,
      render: (v) => (
        <>
          {v.building
            .map((obj) => obj.units.length)
            .reduce((acc, val) => acc + val, 0)}
        </>
      ),
      cellStyle: {
        textAlign: "center",
      },
    },

    {
      title: "Action",
      field: "patient",
      render: (v) => (
        <div>
          <Link
            to={`/add-properties/${v.id}`}
            className="btn btn-sm action-btn"
          >
            <i className="far fa-edit"></i>
          </Link>
          {/* <button
            onClick={() => console.log("test")}
            className="btn btn-sm action-btn"
          >
            <i className="far fa-trash"></i>
          </button> */}
        </div>
      ),
      cellStyle: {
        textAlign: "center",
      },
    },
  ];
  const [data, setData] = useState([]);
  const [update, setUpdate] = useState(false);
  const [spinner, setSpinner] = useState(false);
  useEffect(() => {
    const controller = new AbortController();
    setSpinner(true);
    http
      .get(`properties`)
      .then((res) => {
        setData(res.data.data);
        setSpinner(false);
      })
      .catch((err) => {
        console.log(err);
        setSpinner(false);
      });

    return () => {
      controller.abort();
    };
  }, [update]);

  return (
    <div className="page-content adjustment-type-table">
      <div className="custom-card p-2 d-flex justify-content-between mb-2 align-items-center">
        <h6>Properties</h6>
        <Link to="/add-properties/null">
          <button
            style={{ marginTop: "1px" }}
            className="btn btn-sm btn-primary float-end"
          >
            Add
          </button>
        </Link>
      </div>

      <MaterialTable
        columns={columns}
        data={data}
        isLoading={spinner}
        options={{
          search: true,
          showTitle: false,
          searchFieldAlignment: "left",
          pageSize: 10,
          emptyRowsWhenPaging: false,
          pageSizeOptions: [5, 10, 20, 50, 100],
        }}
      />
    </div>
  );
}
