import './App.css';
import { useState, useEffect } from 'react';
import AuthUser from './components/AuthUser';
import Auth from "./navbar/Auth";
import Guest from './navbar/guest';

function App() {
  const {getToken} = AuthUser();
  const [storageData, setstorageData] = useState(false);
  useEffect(() => {
        var value = JSON.parse(sessionStorage.getItem("user"));

    if (!value) {
        var value = JSON.parse(localStorage.getItem("user"));    
    }
    setstorageData(value);
  }, []);
      if (!getToken()){
          return <Guest/>
      }
    return (
      <Auth perStorageData={storageData}/>
    );
}

export default App;