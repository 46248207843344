// Modal.js

import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import Select from 'react-select'
import http from '../../../http';
import { MdDeleteOutline } from "react-icons/md";
import Swal from 'sweetalert2';
import allCustomStyles from '../../../CSS/ReactModalStyle';


Modal.setAppElement('#root');

const AppartmentBillingMapModal = ({ isOpen, closeModalAppBilling, floorInfoProps, setfloorInfoProps, buildingInfo, setbuildingInfo, settableInfoUpdate }) => {
    const { BillingMapModal, colourStyles } = allCustomStyles;

    const [serviceType, setserviceType] = useState([])
    const [feeSetup, setfeeSetup] = useState([])
    useEffect(() => {

        http.get(`service-type`)
            .then((res) => {
                setserviceType(res.data)
            }).catch(err => {
                console.log("err", err)

            })

        http.get(`bill-map-data/${floorInfoProps?.id}`)
            .then((res) => {
                console.log("res app", res.data)

                setfeeSetup(res.data.data)
            }).catch(err => {
                console.log("err", err)

            })

        return () => {

        }
    }, [floorInfoProps?.id])

    const closeModal = () => {
        setfeeSetup([])
        closeModalAppBilling()
        setfloorInfoProps()
        setbuildingInfo()
        setisLoading(false)
    }

    console.log("buildingInfo", buildingInfo)
    console.log("floorInfoProps", floorInfoProps)
    const [isLoading, setisLoading] = useState(false)

    const hadleSaveData = () => {
        setisLoading(true)

        if (feeSetup.length === 0) {
            return Swal.fire({
                position: 'top-center',
                icon: 'warning',
                title: 'Please select service type and check the reocrd are avalable !',
                // text: 'Please select the manager field',
                showConfirmButton: false,
                timer: 1500
            })
        }
        if (buildingInfo?.id !== undefined) {
            const data = {
                buildingInfo,
                feeSetup
            }
            http.post('appoitment-building-wise-bill-map', data).then(res => {
                Swal.fire({
                    position: 'top-center',
                    icon: 'success',
                    title: 'success!',
                    text: res.data.message,
                    timer: 2500
                })
                settableInfoUpdate(Math.random())
                closeModal()
            }).catch(err => {
                console.log("err", err)
                setisLoading(false)
            })
        } else {
            const data = {
                building_id: floorInfoProps?.building_id,
                floor_details_id: floorInfoProps?.id,
                feeSetup,
            }
            http.post('appoitment-floor-wise-bill-map', data).then(res => {

                Swal.fire({
                    position: 'top-center',
                    icon: 'success',
                    title: 'success!',
                    text: res.data.message,
                    timer: 2500
                })
                closeModal()
                settableInfoUpdate(Math.random())
            }).catch(err => {
                console.log("err", err)
                setisLoading(false)
            })
        }

    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={closeModalAppBilling}
            contentLabel="Example Modal"
            style={BillingMapModal}
        >
            <span
                className="float-end"
                style={{ fontSize: "15px", cursor: "pointer" }}
                onClick={closeModal}
            >
                <i className="fal fa-times"></i>
            </span>
            <h6 style={{ fontSize: "14px" }}>Apartment Billing Map ({floorInfoProps ? floorInfoProps?.unit_name : "All Unit"})</h6>
            <hr />

            <div className='row'>
                <div className="col-3"><Select options={serviceType} styles={colourStyles} onChange={(v) => {

                    http.get(`fee-setup-detatil/${v.id}`)
                        .then((res) => {
                            console.log("res da", res.data.data)
                            // setserviceTypeId(res.data.data.service_type_id)
                            // setserviceGroupId(res.data.data.service_group_id)
                            setfeeSetup(res.data.data)
                        }).catch(err => {
                            console.log("err", err)
                            setfeeSetup([])
                        })

                }} getOptionLabel={(option) => option.service_type} className="basic-single mb-2" /></div>

            </div>
            {
                feeSetup?.length > 0 ?
                    feeSetup?.map((val, i) => {
                        return <div key={i} className="unit-manager-table invoice-item-table mb-3">
                            <span className='mb-1' style={{ border: "1px solid #fff", padding: "3px", backgroundColor: "#035E7B", color: 'white', padding: '4px 8px', borderRadius: "3px", display: "inline-block" }}>{val?.service?.service_group}</span>
                            <table className=''>
                                <tr>
                                    <td>SL</td>
                                    <td>Name</td>
                                    <td>Per Unit</td>
                                    <td>Unit Meaurment</td>
                                    <td>Action</td>
                                </tr>

                                {val?.fee_setup_deatils?.length > 0 ?
                                    val?.fee_setup_deatils?.map((item, index) =>
                                        <tr key={index}>
                                            {/* <td>{index + 1000}</td> */}
                                            <td>{item?.code}</td>
                                            <td>{item.feesid?.fee_name}</td>
                                            <td>{item?.unit}</td>
                                            <td>{item.unitsid?.unit_type}</td>
                                            <td><MdDeleteOutline onClick={() => {
                                                Swal.fire({
                                                    title: 'Are you sure?',
                                                    text: 'You won\'t be able to revert this!',
                                                    icon: 'warning',
                                                    showCancelButton: true,
                                                    confirmButtonColor: '#d33', // Set confirm button color to red
                                                    cancelButtonColor: '#3085d6', // Set cancel button color to blue
                                                    confirmButtonText: 'Yes, delete it!'
                                                }).then((result) => {
                                                    if (result.isConfirmed) {
                                                        const data = feeSetup.map(outer => {
                                                            if (outer.id === val.id) {
                                                                outer.fee_setup_deatils = outer.fee_setup_deatils?.filter(data => data.id !== item.id)
                                                            }
                                                            return outer;
                                                        })
                                                        setfeeSetup(data)
                                                        Swal.fire(
                                                            'Deleted!',
                                                            'Your data has been deleted.',
                                                            'success'
                                                        );
                                                    }
                                                });


                                            }} style={{ color: "red", fontSize: "17px", cursor: "pointer" }} /></td>
                                        </tr>
                                    ) : <tr style={{ marginLeft: "50%" }} className='text-center'>

                                        <td style={{ color: "red" }} colSpan={6}>Records are not available</td>
                                    </tr>
                                }



                            </table>
                        </div>
                    }) :
                    <p style={{ color: "red", textAlign: "center", marginTop: "20px" }} >Records are not available</p>

            }

            {
                feeSetup?.length > 0 &&
                <div className="d-flex justify-content-end">
                    <button onClick={closeModal} className="btn btn-sm btn-primary me-1">Cancel</button>
                    {
                        isLoading ?
                            <button className="btn btn-sm btn-primary">loading...</button>
                            :
                            <button onClick={hadleSaveData} className="btn btn-sm btn-primary">{buildingInfo?.id !== undefined ? "Save For All Unit" : "Save For Single Unit"}</button>
                    }
                </div>
            }



        </Modal>
    );
};

export default AppartmentBillingMapModal;
