import React, { Fragment, useEffect, useState } from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import http from '../../../http';
import Swal from 'sweetalert2';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Link } from 'react-router-dom';
const ViewServiceFeeSetup = () => {
    const [utlitiyState, setUtlitiyState] = useState(true)
    const [serviceState, setServiceState] = useState([])
    const [masterData, setMasterData] = useState()
    const [units, setUnits] = useState([])
    const [spinner, setSpinner] = useState(false);
    const [serviceType, setServiceType] = useState([]);
    const [unitType, setUnitType] = useState([]);
    const [allData, setAllData] = useState({});
    const [activeData, setActiveData] = useState([]);
    const [random, setRandom] = useState();
    const navigate = useNavigate()
    const id = useParams()


    const [data, setData] = useState({
        service_group_id: '',
        service_type_id: '',
        effactive_date: '',
        end_date: '',
        isActive: '',
        id: '',
    });

    const handleChangeUint = (event) => {
        const { name, value } = event.target;
        setData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleFeesChange = (event) => {
        event.preventDefault()
        const { name, value } = event.target;

        setData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    }




    const handleChangeUpdateInputUintMesureMind = (event, i) => {
        event.preventDefault();
        const { name, value } = event.target;
        setServiceState((prevData) => {
            const updatedFees = prevData.map((fee, index) =>
                index === i ? { ...fee, unit_id: value } : fee
            );
            return updatedFees;
        });
    }

    const handleChangeUpdateInputUint = (event, i) => {
        event.preventDefault();
        const { name, value } = event.target;
        setServiceState((prevData) => {
            const updatedFees = prevData.map((fee, index) =>
                index === i ? { ...fee, unit: value } : fee
            );
            return updatedFees;
        });
    }
    const handleChangeUpdateRemark = (event, i) => {
        event.preventDefault();
        const { name, value } = event.target;
        setServiceState((prevData) => {
            const updatedFees = prevData.map((fee, index) =>
                index === i ? { ...fee, remarks: value } : fee
            );
            return updatedFees;
        });
    }


    console.log(id)
    useEffect(() => {
        http
            .get(`service-group`)
            .then((res) => {
                setUnits(res.data)
                setSpinner(false);
            })
            .catch((err) => {
                setSpinner(false);
                console.log(err);
            });
        http
            .get(`fee-setup`)
            .then((res) => {
                setData({});
                const getData = res?.data?.data;
                const FindFeesData = getData?.find((item) => item.id == id.id)
                setAllData(FindFeesData)
                setServiceState(FindFeesData?.fee_setup_deatils)
                setData({
                    ...data,
                    id: FindFeesData.id,
                    service_group_id: FindFeesData.service_group_id,
                    service_type_id: FindFeesData.service_type_id,
                    effactive_date: FindFeesData.effactive_date,
                    end_date: FindFeesData.end_date,
                    isActive: FindFeesData.isActive,
                });
                setSpinner(false);
            })
            .catch((err) => {
                setSpinner(false);
                console.log(err);
            });
        http
            .get(`service-type`)
            .then((res) => {
                setServiceType(res.data);
                setSpinner(false);
            })
            .catch((err) => {
                console.log(err);
                setSpinner(false);
            });
        http
            .get(`unit-type`)
            .then((res) => {
                setUnitType(res.data);
                setSpinner(false);
            })
            .catch((err) => {
                console.log(err);
                setSpinner(false);
            });
        // active fee service setup 
        http
            .get(`active-fee-setup`)
            .then((res) => {
                setActiveData(res?.data?.data);
                setSpinner(false);
            })
            .catch((err) => {
                console.log(err);
                setSpinner(false);
            });
    }, [random])
    const [errorMessage, setErrorMessage] = useState({
        service_group_id: '',
        effactive_date: '',
        service_type_id: '',
        unit_measurement: '',
        end_date: ''
    })

    const handleUpdateSubmit = (event) => {
        event.preventDefault()
        const endDate = data?.end_date;
        const endDateFromat = moment(endDate).format();
        const effDate = data?.effactive_date;
        const effDateFromat = moment(effDate).format();
        if (!data.service_group_id) {
            setErrorMessage({ service_group_id: 'Service group is required' })
            return false;
        }
        else if (!data.service_type_id) {
            setErrorMessage({ service_type_id: 'Service type field is required' })
            return false;
        }
        else if (!data.effactive_date) {
            setErrorMessage({ effactive_date: 'Effactive date field is required' })
            return false;
        }
        else if (!serviceState[0].unit_id) {
            setErrorMessage({ unit_measurement: 'Unit measurement field is required' })
            return false;
        }
        else if (!data.end_date) {
            setErrorMessage({ end_date: 'End date field is required' })
            return false;
        }

        else if (effDateFromat > endDateFromat) {
            setErrorMessage({ end_date: 'End date must be grather then effactive date' })
            return false;
        }
        console.log('endDate', endDateFromat);
        const formdata = JSON.stringify({ data })
        const feesData = JSON.stringify({ serviceState })
        const findActiveServiceGroup = activeData?.find((item) => item?.service_group_id == data?.service_group_id && item?.service_type_id == data?.service_type_id)
        if (findActiveServiceGroup) {
            Swal.fire({
                title: 'Are you sure?',
                text: "You have already activate this service!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        http.post('fee-setup-update', { formdata, feesData })
                            .then((res) => {
                                console.log(res, 'res')
                                Swal.fire({
                                    position: 'top-center',
                                    icon: 'success',
                                    title: 'Success !',
                                    text: 'Data Added Successfully',
                                    showConfirmButton: false,
                                    timer: 1500
                                })
                                setRandom(Math.random())
                                navigate('/service-fee-setup')
                            })
                            .catch((err) => {
                                console.log(err)
                                Swal.fire({
                                    position: 'top-center',
                                    icon: 'error',
                                    title: 'Oops...',
                                    text: 'Something went wrong!',
                                    showConfirmButton: false,
                                    timer: 1500
                                })
                            })
                    }
                })
        } else {
            http.post('fee-setup-update', { formdata, feesData })
                .then((res) => {
                    console.log(res, 'res')
                    Swal.fire({
                        position: 'top-center',
                        icon: 'success',
                        title: 'Success !',
                        text: 'Data Added Successfully',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    setRandom(Math.random())
                    navigate('/service-fee-setup')
                })
                .catch((err) => {
                    console.log(err)
                    Swal.fire({
                        position: 'top-center',
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!',
                        showConfirmButton: false,
                        timer: 1500
                    })
                })
        }
    }


    console.log('errorMessage', errorMessage)
    console.log('data', data)
    return (
        <>
            <div className="page-content bg-white">
                <h3 className="text-center my-3 mb-4">Fee Setup</h3>
                <div className='row'>
                    <div className='col-12'>
                        <div className='mb-4'>

                            <div className="row mt-3 justify-content-between">

                                <div className="col-2">
                                    <div className="row mb-2">
                                        <div className="col-2">
                                            <label> Group</label>
                                        </div>
                                        <div className="col-10">
                                            <select name='service_group_id' className="form-select form-select-sm" onChange={(event) => handleFeesChange(event)} required>
                                                <option selected disabled>{allData?.service?.service_group}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-3">
                                    <div className="row mb-2">
                                        <div className="col-4">
                                            <label> Service Type</label>
                                        </div>
                                        <div className="col-8">
                                            <select name='service_type_id' className="form-select form-select-sm" onChange={(event) => handleChangeUint(event)} required value={data?.service_type_id ? data?.service_type_id : ''}>
                                                <option selected disabled value="" >Select service type</option>
                                                {serviceType.map((item, i) =>
                                                    <option value={item?.id} key={i}>{item?.service_type}</option>
                                                )}
                                            </select>
                                            {errorMessage?.service_type_id && <div className="text-danger"> {errorMessage?.service_type_id} </div>}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-3">
                                    <div className="row mb-2">
                                        <div className="col-3">
                                            <label> Effactive Date</label>
                                        </div>
                                        <div className="col-9">
                                            <DatePicker
                                                required
                                                selected={data?.effactive_date ? new Date(data?.effactive_date) : new Date()}
                                                dateFormat="dd/MM/yyyy"
                                                className="form-control form-control-sm"
                                                onChange={(event) => setData({ ...data, effactive_date: event })}
                                            />
                                            {errorMessage?.effactive_date && <div className="text-danger"> {errorMessage?.effactive_date} </div>}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="row mb-2">
                                        <div className="col-3">
                                            <label> End Date</label>
                                        </div>
                                        <div className="col-9">
                                            <DatePicker
                                                required
                                                selected={data?.end_date ? new Date(data?.end_date) : new Date()}
                                                dateFormat="dd/MM/yyyy"
                                                className="form-control form-control-sm"
                                                onChange={(event) => setData({ ...data, end_date: event })}
                                            />
                                            {errorMessage?.end_date && <div className="text-danger"> {errorMessage?.end_date} </div>}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-1 mt-2">
                                    <div className="row">
                                        <Link to="/service-fee-setup" className='btn btn-primary btn-sm '>Back</Link>
                                    </div>
                                </div>
                            </div>

                            <div className="unit-manager-table invoice-item-table mb-3">
                                <form onSubmit={handleUpdateSubmit}>
                                    <table className=''>
                                        <tr>
                                            <td>SL</td>
                                            <td>Code</td>
                                            <td>Name</td>
                                            <td>Per Unit</td>
                                            <td>Unit Meaurment</td>
                                            <td>Remarks</td>
                                        </tr>
                                        {serviceState.map((fee, i) => {
                                            return (
                                                <tr>
                                                    <td>{i + 1}</td>
                                                    <td>{fee?.code}</td>
                                                    <td width="30%"> {fee?.feesid?.fee_name}</td>
                                                    <td>
                                                        <input type="text" name='unit' className="form-control form-control-sm border-none" onChange={(event) => handleChangeUpdateInputUint(event, i)} required value={fee?.unit ? fee?.unit : ''} />
                                                    </td>
                                                    <td >
                                                        <div className="col-8">
                                                            <select required name='unit_measurement' className="form-select form-select-sm" onChange={(event) => handleChangeUpdateInputUintMesureMind(event, i)} value={fee?.unit_id ? fee?.unit_id : ''}>
                                                                <option disabled value="">Select uint Type</option>
                                                                {unitType.map((item) => <option value={item?.id}>{item?.unit_type}</option>
                                                                )}
                                                            </select>
                                                            {errorMessage?.unit_measurement && <div className="text-danger"> {errorMessage?.unit_measurement} </div>}
                                                        </div>
                                                    </td>
                                                    <td width='10%'>
                                                        <input name='remarks' className="form-control form-control-sm" onChange={(event) => handleChangeUpdateRemark(event, i)} value={fee?.remarks} />
                                                    </td>
                                                </tr>
                                            )
                                        })
                                        }
                                    </table>

                                </form>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </>
    )
}

export default ViewServiceFeeSetup

