import React, { useEffect, useRef, useState } from "react";
import "../../../CSS/BuillingSetup.css";
import { FaBuilding, FaCheck, FaRegBuilding } from "react-icons/fa";
import Select from "react-select";
import { FaBars, FaBuildingColumns } from "react-icons/fa6";
import http from "../../../http";
import avatar from "../../../front_assets/images/avatar.png";
import Setting from "../../Setting/Setting";
import { MdMapsHomeWork } from "react-icons/md";
import StatmentHeading from "../../Componets/StatmentHeading";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import { FiPrinter } from "react-icons/fi";
import { CircularProgress } from "@mui/material";
import allCustomStyles from "../../../CSS/ReactModalStyle";

const Billing = () => {
  const { colourStyles } = allCustomStyles;

  const { currencyFormat, dateFormat } = Setting;
  const [showClass, setShowClass] = useState("hide");
  const [ownerShipType, setOwnerShipType] = useState([]);
  const [isloading, setisloading] = useState(false);

  const options = [
    { value: "Dhaka", label: "Dhaka" },
    { value: "Chattogram", label: "Chattogram" },
    { value: "Cumilla", label: "Cumilla" },
  ];
  const owners = [
    { value: "Echo", label: "Echo" },
    { value: "Piko", label: "Piko" },
    { value: "kaku", label: "kaku" },
  ];
  const showFullWidth = () => {
    if (showClass === "hide") {
      setShowClass("show");
    } else {
      setShowClass("hide");
    }
  };

  const [billingStatmentMasterData, setbillingStatmentMasterData] = useState(
    []
  );
  const [billingStatmentData, setbillingStatmentData] = useState([]);
  const [mobileNumber, setmobileNumber] = useState();
  const [building, setbuilding] = useState();

  const [searchData, setsearchData] = useState({
    owner: [],
    tanent: [],
    phone: [],
  });

  useEffect(() => {
    setisloading(true);
    http.get("ownerships-type-owners").then((res) => {
      setOwnerShipType(res?.data?.data);
    });

    http
      .get("billing-summary-floor-wise")
      .then((res) => {
        console.log("Billing Summary", res?.data?.data);
        setbillingStatmentData(res?.data?.data?.sumuaryData);
        setbillingStatmentMasterData(res?.data?.data?.sumuaryData);
        ledegerStatmentAdd(res?.data?.data?.sumuaryData);
        setmobileNumber(res?.data?.data?.owner);
        setbuilding(res?.data?.data?.building);

        setsearchData({
          ...searchData,
          owner: res?.data?.data?.owner.filter(
            (v) => v?.owner_ships_type.name === "Owner"
          ),
          tanent: res?.data?.data?.owner.filter(
            (v) => v?.owner_ships_type.name === "Tenant"
          ),
          phone: res?.data?.data?.owner,
        });

        setisloading(false);
      })
      .catch((err) => setisloading(false));
  }, []);

  const ledegerStatmentAdd = (data = billingStatmentMasterData) => {
    const v = data?.flatMap((item) => item?.history);
    setledgerStatment(v);
  };

  const getOrdinal = (number) => {
    const suffixes = ["th", "st", "nd", "rd"];
    const lastDigit = number % 10;
    const suffix =
      lastDigit <= 3 && number !== 11 && number !== 12 && number !== 13
        ? suffixes[lastDigit]
        : suffixes[0];
    return number + suffix;
  };
  const [ledgerStatment, setledgerStatment] = useState([]);

  const [buttonActive, setbuttonActive] = useState();
  const [buttonActiveDue, setbuttonActiveDue] = useState();
  const [buttonActivePaid, setbuttonActivePaid] = useState();

  const navTabActive = (id = "home-tab") => {
    document.getElementById(id).click();
  };
  const statement = useRef();
  const billingSM = useReactToPrint({
    content: () => statement.current,
  });
  const reciveTotal = ledgerStatment?.reduce(
    (acc, detail) => acc + Number(detail?.received_amount),
    0
  );
  const grandTotal = ledgerStatment?.reduce(
    (acc, detail) => acc + Number(detail?.grand_total),
    0
  );
  const Due = ledgerStatment?.reduce(
    (acc, detail) => acc + Number(detail?.due),
    0
  );

  const clearData = () => {
    setbuttonActive();
    setbuttonActiveDue();
    setbuttonActivePaid();
  };

  const [searchFillterDisplay, setsearchFillterDisplay] = useState(true)

  return (
    <div id="builling_setup">
      <div className="page-content bg-white">
        <div className="row">
          <div
            className={`${showClass === "show" ? "col-lg-12 show" : "col-lg-9 hide"
              }`}
          >
            <div className="property-register ms-1">
              {/* <form action=""> */}
              <div className="row">
                <h3 className="text-center my-3">Billing Summary</h3>
                <ul
                  className="nav bulling_tab  mb-3"
                  id="pills-tab"
                  role="tablist"
                >
                  <li
                    className="nav-item billing_list d-flex"
                    role="presentation"
                  >
                    <button
                      onClick={() => {
                        setsearchFillterDisplay(true)
                        ledegerStatmentAdd()
                      }}
                      class="nav-link builling_link active"
                      id="home-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#home"
                      type="button"
                      role="tab"
                      aria-controls="home"
                      aria-selected="true"
                    >
                      Personal Statement{" "}
                    </button>
                    <span className="builling_item"></span>
                    <span className="builling_item_one"></span>
                  </li>
                  <li className="nav-item billing_list" role="presentation">
                    <span className="builling_item"></span>
                    <button
                      class={`nav-link builling_link`}
                      id="profile-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#profile"
                      type="button"
                      role="tab"
                      aria-controls="profile"
                      aria-selected="false"
                      onClick={() => {
                        setsearchFillterDisplay(false)
                      }}
                    >
                      Ledger Satement
                    </button>
                    <span className="builling_item"></span>
                    <span className="builling_item_one"></span>
                  </li>
                  <li className="nav-item billing_list" role="presentation">
                    <button
                      class="nav-link builling_link"
                      id="contact-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#contact"
                      type="button"
                      role="tab"
                      aria-controls="contact"
                      aria-selected="false"
                      onClick={() => {
                        setsearchFillterDisplay(false)
                      }}
                    >
                      Blance report
                    </button>
                    <span className="builling_item"></span>
                    <span className="builling_item_one"></span>
                  </li>
                  <li className="nav-item billing_list" role="presentation">
                    <button
                      class="nav-link builling_link"
                      id="profit-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#profit"
                      type="button"
                      role="tab"
                      aria-controls="profit"
                      aria-selected="false"
                      onClick={() => {
                        setsearchFillterDisplay(false)
                      }}
                    >
                      Profit and loss
                    </button>
                    <span className="builling_item"></span>
                    <span className="builling_item_one"></span>
                  </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                  <div
                    class="tab-pane fade show active"
                    id="home"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >
                    <ul className="nav mb-3" id="pills-tab" role="tablist">
                      <li className="nav-item d-flex" role="presentation">
                        <button
                          onClick={() => {
                            setbillingStatmentData(billingStatmentMasterData);
                          }}
                          className={`nav-link builling-header-item active`}
                          id={"pills-tab-8"}
                          data-bs-toggle="pill"
                          data-bs-target={"#tab-8"}
                          type="button"
                          role="tab"
                          aria-controls={`${"#tab-8"}`}
                          aria-selected="true"
                        >
                          All
                        </button>
                      </li>

                      {ownerShipType &&
                        ownerShipType.map((item, i) => {
                          return (
                            <>
                              <li
                                key={i}
                                className="nav-item d-flex"
                                role="presentation"
                              >
                                <button
                                  onClick={() => {
                                    if (item?.name === "Owner") {
                                      var oD =
                                        billingStatmentMasterData?.filter(
                                          (v, i) => v?.floor?.tenant === null
                                        );
                                    } else {
                                      var oD =
                                        billingStatmentMasterData?.filter(
                                          (v, i) => v?.floor?.tenant !== null
                                        );
                                    }
                                    setbillingStatmentData(oD);
                                  }}
                                  className={`nav-link builling-header-item`}
                                  id={`${"pills-tab-" + item?.id}`}
                                  data-bs-toggle="pill"
                                  data-bs-target={`${"#tab-" + item?.id}`}
                                  type="button"
                                  role="tab"
                                  aria-controls={`${"#tab-" + item?.id}`}
                                  aria-selected="true"
                                >
                                  {item?.name}
                                </button>
                              </li>
                            </>
                          );
                        })}
                    </ul>

                    <div className="tab-content" id="pills-tabContent">
                      {isloading ? (
                        <tr>
                          <CircularProgress />
                        </tr>
                      ) : billingStatmentData?.length > 0 ? (
                        billingStatmentData?.map((v, i) => {
                          return (
                            <div
                              key={i}
                              className="tab-pane active"
                              id="tab2"
                              role="tabpanel"
                              aria-labelledby="pills-home-tab"
                            >
                              <div className="builling-card-container">
                                <div className="builling-card-header-container">
                                  <div className="d-flex justify-content-between">
                                    <div className="col-lg-4">
                                      {/* <img src={`${global.img_Url}/images/${item?.picture}`} alt="" width="100" style={{ borderRadius: '6px', }} className='my-3' /> */}

                                      {v?.floor?.tenant === null ? (
                                        v?.floor?.owner?.picture &&
                                          v?.floor?.owner?.picture != "null" ? (
                                          <img
                                            src={`${global.img_Url}/images/${v?.floor?.owner?.picture}`}
                                            width="50"
                                            height="50"
                                            alt="Profile"
                                          />
                                        ) : (
                                          <img
                                            src={avatar}
                                            width="50"
                                            height="50"
                                            alt="Profile"
                                          />
                                        )
                                      ) : v?.floor?.tenant?.picture &&
                                        v?.floor?.tenant?.picture != "null" ? (
                                        <img
                                          src={`${global.img_Url}/images/${v?.floor?.tenant?.picture}`}
                                          width="50"
                                          height="50"
                                          alt="Profile"
                                        />
                                      ) : (
                                        <img
                                          src={avatar}
                                          width="50"
                                          height="50"
                                          alt="Profile"
                                        />
                                      )}
                                    </div>
                                    <div className="col-lg-3">
                                      <div className="builling-card-list">
                                        <ul className="pt-2 ps-2">
                                          <li>
                                            {" "}
                                            <span className="pe-1">
                                              {" "}
                                              <FaBuildingColumns />{" "}
                                            </span>

                                            {v?.floor?.project?.property_name}
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                    {/* <div className="col-lg-5 d-flex justify-content-between align-items-top pt-3">
                                                                            <h2 className=''>Delivery Date: <span> 2024/01/01 </span></h2>
                                                                            <p>Transit Days: <span>4 Days</span> </p>
                                                                        </div> */}
                                  </div>
                                </div>
                                <div className="builling-card-content-container d-flex">
                                  <div className="col-lg-8 mt-1">
                                    <div className="d-flex flex-wrap">
                                      <div className="col-lg-6">
                                        <div className="builling-card-content-top-left-image">
                                          <h1
                                            style={{
                                              fontSize: "20px",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            {v?.floor?.tenant === null
                                              ? v?.floor?.owner?.name
                                              : v?.floor?.tenant?.name}
                                          </h1>
                                          <p className="pt-1">
                                            {v?.floor?.tenant === null
                                              ? v?.floor?.owner?.mobile_1
                                              : v?.floor?.tenant?.mobile_1}
                                          </p>
                                          <p className="pt-1">
                                            {v?.floor?.tenant === null
                                              ? v?.floor?.owner?.address_line_1
                                              : v?.floor?.tenant
                                                ?.address_line_1}
                                          </p>
                                          <p className="pt-1">
                                            {" "}
                                            <MdMapsHomeWork />{" "}
                                            {v?.floor?.building?.title}
                                          </p>
                                          <p>
                                            <FaCheck />{" "}
                                            {getOrdinal(v?.floor?.floor)} Floor
                                            ({v?.floor?.unit_name})
                                          </p>
                                        </div>
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="col-lg-12"></div>
                                        <div className="col-lg-12">
                                          <div className="builling-card-list">
                                            <ul className="p-0">
                                              <div className="builling-center-content">
                                                <h2 className="heading">
                                                  Current Due
                                                </h2>
                                                <h3 className="heading_one">
                                                  {currencyFormat(v?.due_sum)}
                                                </h3>
                                                <h4 className="heading mb-2">
                                                  Cost of transportation is
                                                  insurable
                                                </h4>
                                                <div className="d-flex">
                                                  <button className="builling-header-item">
                                                    Sms
                                                  </button>
                                                  <button className="builling-header-item">
                                                    Email
                                                  </button>
                                                </div>
                                              </div>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-lg-4">
                                    <div className="billing-setup-right-content-container">
                                      <ul>
                                        <li>Total Bill</li>
                                        <li>
                                          {currencyFormat(v?.grand_total_sum)}
                                        </li>
                                      </ul>
                                      <ul>
                                        <li>Total Paid</li>
                                        <li>
                                          {currencyFormat(
                                            v?.received_amount_sum
                                          )}
                                        </li>
                                      </ul>
                                      <button
                                        onClick={() => {
                                          // const data = v?.history?.flatMap((item) => item?.payment_history)
                                          setledgerStatment(v?.history);

                                          navTabActive("profile-tab");
                                        }}
                                        className="billing-setup-rign-button"
                                      >
                                        Statement
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <p style={{ color: "red", marginTop: "10px" }}>
                          Records are not available
                        </p>
                      )}
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="profile"
                    role="tabpanel"
                    aria-labelledby="profile-tab"
                  >
                    <div ref={statement} className="bill-sum-print">
                      <div >
                        <StatmentHeading name={"Billing Statement"} />
                        <div className="unit-manager-table invoice-item-table">
                          <table>
                            <tr style={{ background: "grey", color: "white" }}>
                              <td style={{ borderRight: "none !important" }}>
                                Billing Month
                              </td>

                              <td>Invoice</td>
                              <td>Date</td>
                              <td>Property</td>
                              <td>Building</td>
                              <td>Floor</td>
                              <td>Flat</td>
                              <td>Owner</td>
                              <td>Tenant</td>

                              <td>MRN/Slip</td>

                              <td>Recived</td>
                              <td>Bill Amount</td>
                              <td>Due Balance</td>
                            </tr>
                            {ledgerStatment?.length > 0 ? (
                              ledgerStatment?.map((item, i) => {
                                return (
                                  <>
                                    <tr key={i}>
                                      <td>
                                        {moment(item?.billing_month).format(
                                          "MMMM, YYYY"
                                        )}
                                      </td>

                                      <td>{item?.invoice}</td>
                                      <td>
                                        {moment(item?.billing_date).format(
                                          dateFormat
                                        )}
                                      </td>
                                      <td>{item?.floor?.project?.property_name}</td>
                                      <td>{item?.building?.title}</td>
                                      <td>{getOrdinal(item?.floor?.floor)}</td>
                                      <td>{item?.floor?.unit_name}</td>
                                      <td>{item?.floor?.owner?.name}</td>
                                      <td>{item?.floor?.tenant?.name}</td>
                                      <td></td>
                                      <td></td>
                                      <td align="right">
                                        {Number(item?.grand_total).toFixed(2)}
                                      </td>
                                      <td align="right">
                                        {Number(item?.grand_total).toFixed(2)}
                                      </td>
                                    </tr>
                                    {item?.payment_history.map((v, i) => {
                                      const subT = item?.payment_history
                                        ?.slice(0, i + 1)
                                        .reduce(
                                          (acc, detail) =>
                                            acc + Number(detail?.amount),
                                          0
                                        );

                                      return (
                                        <tr>
                                          <td></td>

                                          <td></td>
                                          <td>
                                            {moment(v?.created_at).format(
                                              dateFormat
                                            )}
                                          </td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td>{v?.slip_number}</td>
                                          <td align="right">
                                            {Number(v?.amount).toFixed(2)}
                                          </td>
                                          <td></td>

                                          <td align="right">
                                            {(
                                              Number(item?.grand_total) -
                                              Number(subT)
                                            ).toFixed(2)}
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </>
                                );
                              })
                            ) : (
                              <tr>
                                <td style={{ color: "red" }} colSpan={12}>
                                  {" "}
                                  Records are not available
                                </td>
                              </tr>
                            )}
                            <tr>
                              <td colSpan="10">
                                <b>Total</b>
                              </td>
                              <td align="right">
                                <>{currencyFormat(reciveTotal)}</>
                              </td>
                              <td align="right">
                                <>{currencyFormat(grandTotal)}</>
                              </td>
                              <td align="right">
                                <>{currencyFormat(Due)}</>
                              </td>
                            </tr>
                          </table>

                          <div
                            className="d-flex justify-content-between mt-1"
                            style={{ fontWeight: "700", fontSize: "12px" }}
                          >
                            <div className="d-flex gap-3">
                              <p>
                                {" "}
                                Total Bill Amount : {currencyFormat(grandTotal)}
                              </p>
                              <p style={{ color: "green" }}>
                                {" "}
                                Total Recived Amount :{" "}
                                {currencyFormat(reciveTotal)}
                              </p>
                              <p style={{ color: "red" }}>
                                {" "}
                                Total Due Amount : {currencyFormat(Due)}
                              </p>
                            </div>

                            <p
                              onClick={billingSM}
                              className="print_icon"
                              style={{ color: "#2b6afd", cursor: "pointer" }}
                            >
                              {" "}
                              <FiPrinter /> Print{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="tab-pane fade"
                    id="contact"
                    role="tabpanel"
                    aria-labelledby="contact-tab"
                  >
                    <h2>Under construction</h2>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="profit"
                    role="tabpanel"
                    aria-labelledby="profit-tab"
                  >
                    <h2>Under construction</h2>
                  </div>
                </div>
              </div>
              {/* </form> */}
            </div>
          </div>
          <div className="col-lg-3">
            <div className="billing-card-filter">

              <div style={{ display: !searchFillterDisplay ? "none" : "inline" }}>
                <div className="search-by-origin mt-3">
                  <label htmlFor="" style={{ fontWeight: "600" }}>
                    Owner
                  </label>
                  <Select
                    styles={colourStyles}
                    options={searchData?.owner}
                    getOptionLabel={(op) => op?.name}
                    onChange={(v) => {
                      const data = billingStatmentMasterData?.filter(
                        (a) => a?.floor?.owner.id === v.id
                      );
                      setbillingStatmentData(data);
                    }}
                    className="mt-2"
                  />
                </div>
                <div className="search-by-origin mt-5">
                  <label htmlFor="" style={{ fontWeight: "600" }}>
                    Phone
                  </label>
                  <Select
                    styles={colourStyles}
                    options={searchData?.phone}
                    getOptionLabel={(op) => op?.mobile_1}
                    onChange={(d) => {
                      var data = billingStatmentMasterData?.filter(
                        (v) => v?.floor?.owner?.mobile_1 === d?.mobile_1
                      );
                      if (data?.length === 0) {
                        var dataT = billingStatmentMasterData?.filter(
                          (v, i) => v?.floor?.tenant?.mobile_1 === v?.mobile_1
                        );
                        setbillingStatmentData(dataT);
                      } else {
                        setbillingStatmentData(data);
                      }
                    }}
                    className="mt-2"
                  />
                </div>
                <div className="search-by-origin mt-3">
                  <label htmlFor="" style={{ fontWeight: "600" }}>
                    Tanent
                  </label>
                  <Select
                    styles={colourStyles}
                    options={searchData?.tanent}
                    getOptionLabel={(op) => op?.name}
                    onChange={(v) => {
                      console.log("v", v);
                      const data = billingStatmentMasterData?.filter(
                        (a) => a?.floor?.tenant?.id === v.id
                      );

                      setbillingStatmentData(data);
                    }}
                    className="mt-2"
                  />
                </div>
                <div className="search-by-origin mt-3 d-flex  flex-wrap">
                  <label
                    htmlFor=""
                    className="w-100 mb-1"
                    style={{ fontWeight: "600" }}
                  >
                    Appartment
                  </label>
                  {building?.map((item, i) => {
                    return (
                      <button
                        onClick={() => {
                          setbuttonActive(i);
                          const data = billingStatmentMasterData?.filter(
                            (v, i) =>
                              Number(v?.floor?.building_id) === Number(item?.id)
                          );
                          setbillingStatmentData(data);
                        }}
                        key={i}
                        className={`builling-filter-button-item  ${Number(i) === Number(buttonActive)
                          ? "button-active"
                          : ""
                          }`}
                      >
                        {item?.title}
                      </button>
                    );
                  })}
                </div>
                <div className="d-flex flex-row">
                  <div className="search-by-origin mt-3 d-flex flex-wrap">
                    <label
                      htmlFor=""
                      className="w-100 mb-1"
                      style={{ fontWeight: "600" }}
                    >
                      Due
                    </label>
                    <button
                      onClick={() => {
                        clearData();
                        setbuttonActiveDue("max");
                        const ex = [...billingStatmentMasterData];
                        const max = ex?.sort((a, b) => b?.due_sum - a?.due_sum);
                        setbillingStatmentData(max);
                      }}
                      className={`builling-filter-button-item ${buttonActiveDue === "max" ? "button-active" : ""
                        }`}
                    >
                      Max
                    </button>
                    <button
                      onClick={() => {
                        clearData();
                        setbuttonActiveDue("low");
                        const ex = [...billingStatmentMasterData];
                        const low = ex?.sort((a, b) => a?.due_sum - b?.due_sum);
                        setbillingStatmentData(low);
                      }}
                      className={`builling-filter-button-item ${buttonActiveDue === "low" ? "button-active" : ""
                        }`}
                    >
                      Low
                    </button>
                  </div>
                  <div className="search-by-origin mt-3 d-flex flex-wrap">
                    <label
                      htmlFor=""
                      className="w-100 mb-1"
                      style={{ fontWeight: "600" }}
                    >
                      Total Paid
                    </label>
                    <button
                      onClick={() => {
                        clearData();
                        setbuttonActivePaid("max");
                        const ex = [...billingStatmentMasterData];
                        const max = ex?.sort(
                          (a, b) =>
                            b?.received_amount_sum - a?.received_amount_sum
                        );
                        setbillingStatmentData(max);
                      }}
                      className={`builling-filter-button-item ${buttonActivePaid === "max" ? "button-active" : ""
                        }`}
                    >
                      Max
                    </button>
                    <button
                      onClick={() => {
                        clearData();
                        setbuttonActivePaid("low");
                        const ex = [...billingStatmentMasterData];
                        const low = ex?.sort(
                          (a, b) =>
                            a?.received_amount_sum - b?.received_amount_sum
                        );
                        setbillingStatmentData(low);
                      }}
                      className={`builling-filter-button-item ${buttonActivePaid === "low" ? "button-active" : ""
                        }`}
                    >
                      Low
                    </button>
                  </div>
                </div>
                <div className="search-by-origin mt-3 text-end">
                  <button
                    onClick={() => {
                      clearData();
                      setbillingStatmentData(billingStatmentMasterData);
                      navTabActive();
                    }}
                    className="btn btn-primary "
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Billing;
