// CustomStyles.js

const currencyFormat = (value, cur = "bdt") => {
    return Intl.NumberFormat("en-BD", {
      style: "currency",
      currency: cur,
    }).format(value);
  };

const dateFormat = "DD/MM/YYYY";

const Setting = {
    currencyFormat,
    dateFormat
};



export default Setting;
  