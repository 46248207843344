import React from 'react'
import Setting from '../../Setting/Setting';
import moment from 'moment';
import PrintStatmentHeader from '../../Componets/PrintStatmentHeader';
import dayjs from 'dayjs';

export default function BillingStatement({ data, grandTotal, totalRecive, totalDue, monthAndYear }) {
    const { currencyFormat, dateFormat } = Setting;

    const getOrdinal = (number) => {
        const suffixes = ["th", "st", "nd", "rd"];
        const lastDigit = number % 10;
        const suffix = lastDigit <= 3 && number !== 11 && number !== 12 && number !== 13 ? suffixes[lastDigit] : suffixes[0];
        return number + suffix;
    }

    const end = dayjs(dayjs().format(`${monthAndYear?.year}-${monthAndYear?.month}-01`)).add(1, "month")
        .subtract(1, "day")
    console.log("data", end)
    return (
        <div >
            {/* <div className="text-center mb-3">
                
                <h5>Billing Statment</h5>
                <h6>{priviousMonth}</h6>
            </div> */}
            <PrintStatmentHeader name={"Billing Statment"} start={dayjs().format(`${monthAndYear?.year}-${monthAndYear?.month}-01`)}
                end={end?.$d}
            />


            <div className="unit-manager-table invoice-item-table mb-3">
                <table className=''>
                    <tr>
                        <td>Date</td>
                        <td>Invoice</td>
                        <td>Property</td>
                        <td>Building</td>
                        <td>Floor</td>
                        <td>Flat</td>
                        <td>Owner</td>
                        <td>Tenant</td>
                        <td>Total</td>
                        <td>Recived</td>
                        <td>Due</td>
                    </tr>

                    {
                        data?.length > 0 ? <>
                            {
                                data?.map((item, i) => (
                                    <tr key={i}>
                                        <td>{item?.created_at &&
                                            moment(item?.created_at).format(dateFormat)}</td>
                                        <td>{item?.invoice}</td>
                                        <td>{item?.project.property_name}</td>
                                        <td>{item?.building?.title}</td>
                                        <td>{getOrdinal(item?.floor)}</td>

                                        <td>{item?.unit_name}</td>
                                        <td>{item?.owner?.name}</td>
                                        <td>{item?.tenant?.name}</td>
                                        <td>{item?.grand_total}</td>
                                        <td>{item?.received_amount}</td>
                                        <td>{item?.due}</td>
                                    </tr>

                                ))


                            }
                            <tr>
                                <td colSpan={8}>Total</td>
                                <td > {currencyFormat(grandTotal)}</td>
                                <td > {currencyFormat(totalRecive)}</td>
                                <td > {currencyFormat(totalDue)}</td>
                            </tr>

                        </>
                            :
                            <tr><td style={{ color: "red" }} colSpan={8}>Records are not availabel</td></tr>
                    }



                </table>
            </div>
        </div>
    )
}
