import React, { useState } from "react";
import Modal from "react-modal";
import allCustomStyles from '../../../CSS/ReactModalStyle';
import MaterialTable from "material-table";
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import http from "../../../http";
import Swal from "sweetalert2";

function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}
export default function BillProcessModal({
    isbillProcessModalOpen,
    setisbillProcessModalOpen,
    priviousMonth,
    data,
    spinner,
    setupdateBillEntryData
}) {

    const { BillingManagementModal } = allCustomStyles;
    const [progress, setProgress] = React.useState(10);


    // React.useEffect(() => {
    //     const timer = setInterval(() => {
    //         setProgress((prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 10));
    //     }, 800);
    //     return () => {
    //         clearInterval(timer);
    //     };
    // }, []);

    const [dataSubmitDone, setdataSubmitDone] = useState(false)

    const submitData = () => {
        setdataSubmitDone(false)
        // const timer = setInterval(() => {
        //     setProgress((prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 10));
        // }, 800);

        // setTimeout(() => {
        //     http.post('acknowledge-bill', data?.filter(v => v.grand_total !== null)).then(res => {
        //         console.log("res", res.data)
        //         clearInterval(timer);
        //         setProgress(100)
        //         setupdateBillEntryData(Math.random())
        //     }).catch(err => {
        //         clearInterval(timer);
        //         setProgress(100)
        //     })
        //     setackCheck(false)
        //     setdataSubmitDone(true)
        // }, 3000);

        setProgress(30)

        http.post('acknowledge-bill', data?.filter(v => v.grand_total !== null)).then(res => {
            setProgress(100)
            setupdateBillEntryData(Math.random())
            setackCheck(false)
            setdataSubmitDone(true)
        }).catch(err => {

            setProgress(100)
        })



    }

    const columns = [
        {
            title: "SL",
            field: "sl",
            render: (row) => <>{row.tableData.id + 101}</>,
            cellStyle: {
                textAlign: "center",
            },
        },
        {
            title: "Building",
            field: `building`,
            render: (row) => <>{row?.building?.title}</>,
            cellStyle: {
                textAlign: "center",
            },
        },
        {
            title: "Flat",
            field: `flat`,
            render: (row) => <>{row?.unit_name}</>,
            cellStyle: {
                textAlign: "center",
            },
        },
        {
            title: "Owner",
            field: `owner`,
            render: (row) => <>{row?.owner?.name}</>,
            cellStyle: {
                textAlign: "center",
            },
        },
        {
            title: "Tenant",
            field: `tenant`,
            render: (row) => <>{row?.tenant?.name}</>,
            cellStyle: {
                textAlign: "center",
            },
        },

        {
            title: "Total",
            field: "total",
            render: (row) => <>{row?.grand_total === null ? '0.00' : row?.grand_total}</>,
            cellStyle: {
                textAlign: "center",
            },
        },
        {
            title: "Status",
            field: "status",
            render: (row) => <>{
                row?.grand_total === null ?
                    <span className="badge" style={{ background: "#cb0000" }}>Incomplete</span> :
                    <span className="badge bg-success">{row?.status}</span>
            }</>,
            cellStyle: {
                textAlign: "center",
            },
        },


    ];
    const closeModal = () => {
        setisbillProcessModalOpen(false);
    };
    const handleNextPage = (id) => {
        document.getElementById(id).click();
    }

    const AkData = [
        { id: 1, name: "Verify the acknowledge of the invoice details", cheked: true },
        { id: 2, name: "Did you confirm the utility and managed service on the invoice", cheked: true },
        { id: 3, name: "Did you confirm the  Billing Month , Billing date and Due Date service on the invoice", cheked: true },
        { id: 4, name: "Did you confirm the User information on the invoice", cheked: true },
    ]
    const [ackCheck, setackCheck] = useState(false)

    return (
        <Modal
            isOpen={isbillProcessModalOpen}
            onRequestClose={isbillProcessModalOpen}
            style={BillingManagementModal}
            contentLabel="Bill process modal"
        >
            <div className="product_modal" style={{ fontFamily: "Patua One" }}>
                <div className="page-content">
                    <div className=" patients-head ">
                        <h5 className="fw-normal  text-start card-name">
                            Bill Process
                            <span
                                style={{ cursor: "pointer", fontSize: "16px" }}
                                onClick={closeModal}
                                className="float-end"
                            >
                                <i className="fal fa-times"></i>
                            </span>
                            <hr></hr>
                        </h5>
                    </div>
                    <div className="row">
                        <h5 className="text-center" style={{ fontWeight: "700", fontSize: "20px" }}>Billing Cycle</h5>
                        <h5 className="text-center">{priviousMonth}</h5>
                        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Preview</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Acknowledgement</button>
                            </li>

                        </ul>
                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                <div className="billing_table">
                                    <h5 className="mb-2">Number of billing records available</h5>
                                    <MaterialTable
                                        columns={columns}
                                        data={data}
                                        isLoading={spinner}
                                        options={{
                                            search: false,
                                            selection: false,
                                            showTitle: false,
                                            searchFieldAlignment: "left",
                                            pageSize: 7,
                                            emptyRowsWhenPaging: false,
                                            pageSizeOptions: [5, 10, 20, 50, 100],
                                            rowStyle: {
                                                fontSize: 14,
                                                fontWeight: 400,
                                            },
                                        }}

                                    />
                                    <button
                                        style={{ marginTop: "5px" }}
                                        onClick={() => {
                                            handleNextPage('pills-profile-tab')
                                        }}
                                        className="btn btn-sm btn-primary float-end"
                                    >
                                        Process
                                    </button>
                                </div>


                            </div>
                            <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">

                                <div className="row">
                                    <div className="col-8">
                                        <h5 className="mb-2">Please acknowledge this information</h5>
                                        {
                                            AkData?.map((item, i) => {
                                                return <div key={i} className="form-check" style={{ fontSize: "16px" }}>
                                                    <input className="form-check-input" type="checkbox" id="flexCheckDefault" />
                                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                                        {item?.name}
                                                    </label>
                                                </div>
                                            })
                                        }
                                    </div>
                                    <div className="col-4" style={{ fontSize: "15px" }}>
                                        <h5 className="mb-2">Review the information</h5>
                                        <div className="row" style={{ color: "green" }}>
                                            <div className="col-8">Total no of building :</div>
                                            <div className="col-4">{data?.length}</div>
                                        </div>
                                        <div className="row" style={{ color: "green" }}>
                                            <div className="col-8">Total no of Bill Created :</div>
                                            <div className="col-4">{data?.filter(v => v.grand_total !== null).length}</div>
                                        </div>
                                        <div className="row" style={{ color: "red" }}>
                                            <div className="col-8">Total no of Incomplete Bill : </div>
                                            <div className="col-4">{data?.filter(v => v.grand_total === null).length}</div>
                                        </div>

                                    </div>
                                </div>




                                <div className="form-check mt-4" style={{ fontSize: "17px" }}>
                                    <input onChange={() => setackCheck(!ackCheck)} className="form-check-input" type="checkbox" id="flexCheckDefault" />
                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                        I acknowledge,this Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam tenetur dolorem iusto reprehenderit reiciendis vero, nemo, minima ab exercitationem aliquid praesentium, cupiditate sint dolore blanditiis odit consequatur maxime dolores ad.
                                    </label>
                                </div>

                                {(ackCheck && !dataSubmitDone) && <div className="d-flex justify-content-center mt-3">
                                    <button
                                        style={{ marginTop: "5px" }}
                                        onClick={() => submitData()}
                                        className="btn btn-sm btn-primary"
                                    >
                                        Lets Go
                                    </button>
                                </div>}



                                {
                                    progress > 10 && <LinearProgressWithLabel value={progress} />
                                }

                                {dataSubmitDone && <div className="d-flex justify-content-center mt-3">
                                    <div className="text-center">
                                        <h5 className="fw-normal  text-start card-name mb-2" style={{ color: "green" }}>
                                            Bill process Complete
                                        </h5>
                                        <button
                                            onClick={closeModal}
                                            className="btn btn-outline-danger"
                                        >
                                            Close Window
                                        </button>
                                    </div>
                                </div>}


                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </Modal>
    )
}
